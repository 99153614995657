const vueAppEl = document.querySelector('#vueApp')
var dataStatic
var dataPath

if (typeof vueAppEl != 'undefined' && vueAppEl != null) {
    dataPath = vueAppEl.dataset.path != 'null' ? vueAppEl.dataset.path : ''
    dataStatic = vueAppEl.dataset.staticpath ? vueAppEl.dataset.staticpath : ''
}

export default {
    //URL Utils
    urlStatic: dataStatic,
    urlSite: dataPath,

    //Home
    home: {
        show: `${dataPath}Home-Show`,
    },

    //Trip
    ticket: {
        availability: `${dataPath}Ticket-AvailableTripDays`,
    },

    //Account
    account: {
        resources: `${dataPath}Account-Resources`,
        logincheckout: `${dataPath}Account-Login?checkout=true`,
        loginmodal: `${dataPath}Account-Login`,
        submitRegistration: `${dataPath}Account-SubmitRegistration`,
        identifyCustomer: `${dataPath}Account-IdentifyCustomer`,
        identifyCustomerAndCreate: `${dataPath}Account-IdentifyAndCreateCustomer`,
        passwordReset: `${dataPath}Account-PasswordResetDialogForm`,
        removeCard: `${dataPath}PaymentInstruments-DeletePayment`,
    },

    //Cart
    cart: {
        addCoupon: `${dataPath}Cart-AddCouponLineItem`,
        removeCoupon: `${dataPath}Cart-RemoveCouponLineItem`,
        insurance: `${dataPath}Cart-Insurance`,
    },

    //Order
    order: {
        cancelOrder: `${dataPath}Order-CancelOrder`,
        downloadDistribusion: `${dataPath}Order-DownloadPDFDistribusion`,
        consult: `${dataPath}Order-TicketConsult`,
        history: `${dataPath}Order-History`,
    },

    //Checkout
    checkout: {
        begin: `${dataPath}Checkout-Begin`,
        data: `${dataPath}Checkout-Data`,
        validateUser: `${dataPath}Checkout-ValidateUser`,
        confirmShipping: `${dataPath}Checkout-ConfirmShipping`,
        createCustomer: `${dataPath}Checkout-CreateCustomer`,
        verifyPassengers: `${dataPath}Checkout-VerifyPassenger`,
        saveAbandonedCart: `${dataPath}Checkout-SaveAbandonedCart`,
    },

    //Checkout Services
    checkoutServices: {
        submitPayment: `${dataPath}CheckoutServices-SubmitPayment`,
        placeOrder: `${dataPath}CheckoutServices-PlaceOrder`,
    },
    pix: {
        submitPayment: `${dataPath}Pix-SubmitPayment`,
    },
    pooling: {
        distribusion: `${dataPath}Pooling-Pooling`,

    },

    login: {
        logout: `${dataPath}Login-Logout`,
        logoutStay: `${dataPath}Login-Logout?stay=true`,
    },
    pages: {
        termosUso: `${dataPath}Page-Show?cid=politica-de-privacidade`,
        politicaPagamento: `${dataPath}Page-Show?cid=politica-de-compra`,
        politicaCookies: `${dataPath}#`,
        crc: 'https://centralderelacionamentocomocliente.mobifacil.com.br/hc/pt-br',
    }
}
