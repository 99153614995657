<template>
    <div class="d-flex">
        <input
            type="radio"
            :id="id"
            :name="name"
            :checked="checked"
            @change="$emit('update:modelValue', value)"
            :disabled="disabled"
            :class="className"
            v-bind="extraAttributes"
        />
        <label :for="id">
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'RadioboxVue',
    props: {
        id: {
            type: String,
            required: true,
        },
        className: {
            type: String,
        },
        label: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        attributes: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number],
            required: true,
        },
    },
    computed: {
        extraAttributes() {
            let extraAttributes = {}

            if (this.attributes) {
                this.attributes.forEach(({ name, value }) => {
                    extraAttributes[name] = value
                })
            }

            return extraAttributes
        },
    },
}
</script>

<style scoped lang="scss">
@import '../../../../scss/core/colors.scss';

label {
    color: $mob-black;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

input[type='radio'] {
    cursor: pointer;
    appearance: none;
    width: 24px;
    height: 24px;
    border: 1px solid $mob-gray-1;
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;

    &:before {
        content: '';
        width: 10px;
        height: 10px;
        background: $mob-primary;
        border: 6px solid $mob-white;
        border-radius: 50%;
        opacity: 0;
        transition: all 600ms ease-in-out;
        position: absolute;
    }

    &:checked {
        opacity: 1;
        border: 8px solid $mob-primary;
    }
    
    &:checked:before {
        opacity: 1;
        border: 1px solid #FFFFFF;
        background: $mob-white;
    }

    &:focus:before {
        box-shadow: 0 0 3px $mob-primary;
    }
}
</style>
