export default {
    downloadDistribusionReq(path, bookingId) {
        const url = new URL(path);
        url.searchParams.append('bookingId', bookingId);
        return {
            url: url.toString(),
            options: {
                method: 'get',
            },
        };
    },
};
