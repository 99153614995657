<template>
    <div class="select-wrapper">
        <label v-if="label" :for="id" :class="{ 'error': errorMessage }">{{ label }}</label>
        <select
            :id="id"
            v-model="selectedValue"
            :class="{ 'error': errorMessage, [className]: className }"
            :disabled="disabled"
            :readonly="readonly"
            v-bind="extraAttributes"
        >
            <option value="" disabled v-if="placeholder">{{ placeholder }}</option>
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
            >
                {{ option.label }}
            </option>
        </select>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
</template>

<script>
export default {
    name: 'SelectVue',
    props: {
        id: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        label: {
            type: String,
        },
        options: {
            type: Array,
            required: true,
            default: () => [],
        },
        className: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        attributes: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
        },
        errorMessage: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            selectedValue: this.value,
        };
    },
    watch: {
        value(newValue) {
            this.selectedValue = newValue;
        },
        selectedValue(newValue) {
            this.$emit('change', newValue);
            this.$emit('input', newValue);
        },
    },
    computed: {
        extraAttributes() {
            let extraAttributes = {};

            if (this.attributes) {
                this.attributes.forEach(({ name, value }) => {
                    extraAttributes[name] = value;
                });
            }

            return extraAttributes;
        },
    },
    created() {
        if (!this.placeholder && this.options.length > 0) {
            this.selectedValue = this.options[0].value;
        }
    }
}
</script>

<style scoped lang="scss">
    @import '../../../../scss/core/colors.scss';

    div {
        margin: 0;
    }

    div label {
        display: block;
    }

    div select {
        width: 100%;
        padding: 0.89rem;
        border: 1px solid $mediumGray;
        border-radius: 4px;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.375rem;
        letter-spacing: -.01em;
        color: $mob-black;
    }

    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url('data:image/svg+xml;utf8,<svg fill="%23${$mob-gray-2}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 0.1rem center;
        height: 56px;
        background-color: white;
    }

    .error-message {
        color: $mob-error;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin-top: 5px;
        text-align: left;
    }

    label.error {
        color: $mob-error;
    }

    .error {
        border-color: $mob-error;
    }

    select:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $mob-white inset !important;
        box-shadow: 0 0 0 30px $mob-white inset !important;
        -webkit-text-fill-color: $mob-black !important;
        background: url('data:image/svg+xml;utf8,<svg fill="%23${$mob-gray-2}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 0.1rem center;
    }

    select[readonly] {
        background-color: $lightGray;
        pointer-events: none;
        touch-action: none;
        border: 1px solid $mob-gray-3;
    }
</style>
