<template>
    <Modal
        modalId="forgetPasswordModal"
        size="md"
        :isStatic="true"
    >
        <template v-slot:title> {{ headingText }} </template>

        <template v-slot:body>
            <p class="mb-3">
                {{ bodyText }}
            </p>
            <InputVue
                v-if="!passwordReset.reset"
                id="checkoutForgetPasswordInput"
                type="email"
                :placeholder="resources.forms.email"
                :attributes="[{ name: 'orange-id', value: 'checkoutForgetPasswordInput' }]"
                v-model="userEmail"
            />
        </template>

        <template v-slot:footer>
            <ButtonVue
                v-if="!passwordReset.reset"
                :label="resources.forms.send"
                className="solid ml-3"
                @click="sendMail"
            />
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import ButtonVue from '../../ui/ButtonVue.vue'
import InputVue from '../../ui/InputVue.vue'
import Modal from '../../ui/Modal.vue'

export default {
    components: {
        InputVue,
        ButtonVue,
        InputVue,
        Modal
    },
    props: {
        email: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            userEmail: this.email || '',
        }
    },
    computed: {
        ...mapState({
            resources: (state) => state.checkout.resources,
            passwordReset: (state) => state.account.passwordReset,
        }),
        bodyText() {
            if (this.passwordReset.reset) {
                return this.passwordReset.resetMsgBody;
            }
            return this.resources.base.forgetPasswordModalBody;
        },
        headingText() {
            if (this.passwordReset.reset) {
                return this.passwordReset.resetMsgHeading;
            }
            return this.resources.base.forgetPasswordModalHeading;
        },

    },
    methods: {
        async sendMail() {
            await this.$store.dispatch('account/resetPassword', {
                loginEmail: this.userEmail,
            })
            setTimeout(() => {
                $(`#forgetPasswordModal`).modal('hide')
            }, 3000)
        },
    },
}
</script>
