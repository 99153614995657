'use strict';
var notifyer = require('../components/notifyer');

function showNotifyer() {
    var url = $('.link-notify').data('link-notifyer');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            data.notificationContent.forEach(notify => {
                if (notify.enableNotification) {
                    var actions = [];

                    if (notify.id == 'orderCreated') {
                        actions.push({
                            action: 'meus-pedidos',
                            title: 'Ver Meus Pedidos',
                            type: 'button',
                            url: notify.notificationLink
                        });
                    } else {
                        actions.push({
                            action: 'go-Home',
                            title: 'Voltar para Home',
                            type: 'button',
                            url: notify.notificationLink
                        });
                    }

                    notifyer.notify({
                        title: notify.notificationTitle,
                        body: notify.notificationBody,
                        data: notify.notificationLink,
                        onclick: notify.notificationLink,
                        actions: actions
                    });
                }
            });
        },
        error: function (error) {
            console.error('🚀 ~ file: homePage.js:28 ~ showNotifyer ~ error', error);
        }
    });
}


module.exports = {
    init: function () {
        $(document).ready(function(){
            window.zE('messenger:on', 'open', function() {
                document.querySelector('.js-gratuidade-close').classList.remove('hide');
            });

            window.zE('messenger:on', 'close', function() {
                document.querySelector('.js-gratuidade-close').classList.add('hide');
            });

        })
    },
    openModalHideButtonPrivally: function () {
        $('.modal-cookies').on('click', function (e) {
            e.preventDefault();
            $('#oPrivallyApp-Modal').show();
            $('#oPrivallyApp-Modal').css({ opacity: 1 });
        });

        var isUserLogged = $('.user-message').length > 0;
        notifyer.init();
        if (isUserLogged) {
            notifyer.initNotifyer();
            setInterval(showNotifyer(), 40000);
        }
    }

};
