'use strict';

const amplitude = require('@amplitude/analytics-browser');
const amplitudeTriggersCheckout = require('./amplitudeTriggersCheckout');
const amplitudeTriggersSearch = require('./amplitudeTriggersSearch');
const amplitudeTriggersAccount = require('./amplitudeTriggersAccount');
const amplitudeHelpers = require('./amplitudeHelpers');

let userId = '';
module.exports = {
    startAmplitude: () => {
        console.info('%c   Amplitude started   ', 'background: #1e61f0; color: #fff');
        let userId = '';

        $(window).on('load', function () {
            const url = $('.ampkey').data('url');
            let amplitudeKey = '';
            let amplitudeOffline = false;
            let amplitudeDebug = '';

            $.ajax({
                url: url,
                type: 'GET',
                success: function (data) {
                    userId = data.userId;
                    amplitudeKey = data.amplitudeKey;
                    amplitudeOffline = data.offline,
                    amplitudeDebug = data.debug
                },
                error: function () {
                    console.error('Error getting userID or amplitudeKey');
                }
            }).then(() => {
                const options = {
                    defaultTracking: {
                        attribution: false,
                        pageViews: false,
                        sessions: false,
                        formInteractions: false,
                        fileDownloads: false,
                    },
                    offline: amplitudeOffline,
                    logLevel: amplitudeDebug
                };
                if (amplitudeDebug == 'none' ) {
                    delete options.logLevel;
                }
                amplitude.setUserId(userId);
                amplitude.init(amplitudeKey, options);
            });
        });
    },
    // Pagina Visualizada
    customPageViewEvent: () => {
        if ($('.ticket__searchInfos').length) {
            $(window).on('load', function () {
                const path = window.location.pathname;
                const loginClass = $('.user-message.user__name').length;
                const checkoutMainClass = $('.checkout-main').length;
                const orderConfirmClass = $('.product-line-item-details.text-center.qrcode__group').length; // Classe que mostra os dados do assento na confirmação do pedido
                const isLoggedIn = (!!loginClass || !!checkoutMainClass || !!orderConfirmClass); // Se houver qualquer uma das classes descritas acima, o usuário está logado
                const isWebView = window.isWebView();
                const isMobile = window.mobileCheck();
                const origin = amplitudeHelpers.getOrigin(isWebView, isMobile);
                const utmData = amplitudeHelpers.getUtmData();
                const event = {
                    event_type: 'Pagina Visualizada',
                    event_properties: {
                        nome_pagina: path,
                        status_login: isLoggedIn,
                        origem: origin,
                        ...utmData
                    }
                };
                amplitude.track(event);
            });
        }
    },
    // Log In Efetuado
    logInEvent: (isLogged) => {
        const isLoggedIn = $('.user-message.user__name').length > 0 || $('.checkout-identification .step-wrapper.checkout-logged-user').length > 0;
        const loginChecked = sessionStorage.getItem('loginChecked') === 'true';
        const utmData = amplitudeHelpers.getUtmData();
        if ((isLoggedIn && !loginChecked) || isLogged) {
            const logInEvent = {
                event_type: 'Log In Efetuado',
                event_properties: {
                    status_login: true,
                    ...utmData
                }
            };
            amplitude.track(logInEvent);
            sessionStorage.setItem('loginChecked', 'true');
        } else if (!isLoggedIn && loginChecked) {
            sessionStorage.removeItem('loginChecked');
        }
    },
    // Busca Solicitada
    searchEvent: () => {
        $('body').on('amplitude:searchEvent', function () {
            amplitudeTriggersSearch.searchEvent();
        });
    },
    // Mapa De Assentos Visualizado
    seatMapViewEvent: function () {
        $('body').on('amplitude:seatMapViewEvent', function () {
            amplitudeTriggersSearch.seatMapViewEvent();
        });
    },
    selectedSeatEvent: () => {
        $('body').on('amplitude:selectedSeatEvent', function (_event, data) {
            const selectedSeatEvent = {
                event_type: 'Assento Escolhido',
                event_properties: data
            };
            amplitude.track(selectedSeatEvent);
        });
    },
    submitedPurchase: () => {
        $('.btn.btn-primary.buy__button.orange.pay-btn, #btnSubmitPayment, .btn-primary.pix-buy-button-modal').on('click', function () {
            amplitudeTriggersCheckout.submitPurchase();
        });
    },
    checkoutIniciado: function () {
        $('body').on('amplitude:checkoutIniciado', function (_event, data) {
            amplitudeTriggersCheckout.checkoutIniciado(data);
        });
    },
    cupomAplicado: function () {
        $('body').on('amplitude:cupomAplicado', function (_event, data) {
            amplitudeTriggersCheckout.cupomAplicado(data);
        });
    },
    seguroViagem: function () {
        $('body').on('amplitude:seguroViagem', function (_event, data) {
            amplitudeTriggersCheckout.seguroViagem(data);
        });
    },
    passengerIdentification: function () {
        $('body').on('amplitude:passengerIdentification', function (_event, data) {
            amplitudeTriggersCheckout.passengerIdentification(data);
        });
    },
    submitPurchase: () => {
        $('body').on('amplitude:submitPurchase', function (_event, data) {
            amplitudeTriggersCheckout.submitPurchase(data);
        });
    },
    tripSelectedEvent: () => {
        $('.ticket__list').on('click', function (_event, customPreventDefault) {
            if (!customPreventDefault) { // Necessário para evitar a execução indevida do código.
                return;
            }
            try {
                const busClass = $('.ticket__card.selected .js-ticket-content').data('classe').toLowerCase();
                const trechos = [];

                $('.busmap_departureDateTime').each(function (index) {
                    const departureDateText = $(this).text().trim().split(' ')[0];
                    const departureDateParts = departureDateText.split('/');
                    const departureFormattedDate = `${departureDateParts[2]}-${departureDateParts[1]}-${departureDateParts[0]}`;

                    const arrivalDateText = $('.busmap_arrivalDateTime').eq(index).text().trim()
                    .split(' ')[0];
                    const arrivalDateParts = arrivalDateText.split('/');
                    const arrivalFormattedDate = `${arrivalDateParts[2]}-${arrivalDateParts[1]}-${arrivalDateParts[0]}`;

                    const originText = $('.busmap_originName').eq(index).text().trim();
                    const originParts = originText.split('-');
                    const destinationText = $('.busmap_destinationName').eq(index).text().trim();
                    const destinationParts = destinationText.split('-');

                    if (originParts.length === 2 && destinationParts.length === 2) {
                        trechos.push({
                            cidade_origem: originParts[0].trim(),
                            uf_origem: originParts[1].trim(),
                            cidade_destino: destinationParts[0].trim(),
                            uf_destino: destinationParts[1].trim(),
                            data_partida: departureFormattedDate,
                            data_chegada: arrivalFormattedDate,
                            assento_classe: busClass
                        });
                    }
                });

                const tripSelected = {
                    event_type: 'Trecho Selecionado',
                    event_properties: {
                        trechos: trechos,
                        viagem_conexão: $('.busmap--connection').length > 0
                    }
                };
                amplitude.track(tripSelected);
            } catch (error) {
                console.error('%cerror', 'color: red', error);
            }
        });
    },
    qrCodeExpirado: function () {
        $('body').on('amplitude:qrCodeExpirado', function () {
            amplitudeTriggersCheckout.qrCodeExpirado();
        });
    },
    minicartView: () => {
        $('body').on('amplitude:minicartView', function () {
            amplitudeTriggersSearch.minicartView();
        });
    },
    userGuest: function () {
        $('body').on('amplitude:userGuest', function (_event, data) {
            amplitudeTriggersAccount.userGuest();
        });
    },
    requestChangePassword: function () {
        $('body').on('amplitude:changePassword', function (_event, data) {
            amplitudeTriggersAccount.changePassword();
        });
    },
    accountCreated: function () {
        $('body').on('amplitude:accountCreated', function (_event, data) {
            const utmData = amplitudeHelpers.getUtmData();
            amplitudeTriggersAccount.accountCreated(data, utmData);
        })},
    plpRedirect: () => {
        $(document).ready(function () {
            if ($('#isFindDaysAhead').length > 0) {
                $('#isFindDaysAhead').on('hide.bs.modal', function (e) {
                    const origin = $('.cities__origin').val().split(' - ')[0];
                    const originUF = $('.cities__origin').val().split(' - ')[1];
                    const destination = $('.cities__destination').val().split(' - ')[0];
                    const destinationUF = $('.cities__destination').val().split(' - ')[1];
                    const departureDate = $('.dates__departure').val();
                    const suggestedDate = new URLSearchParams($('.date-carousel__item.current').attr('href').split('?')[1]).get('date');
                    const utmData = amplitudeHelpers.getUtmData();

                    const dateRedirect = {
                        event_type: 'Redirecionamento de Data',
                        event_properties: {
                            cidade_origem: origin,
                            uf_origem: originUF,
                            cidade_destino: destination,
                            uf_destino: destinationUF,
                            data_partida: departureDate,
                            data_sugerida: suggestedDate,
                            ...utmData
                        }
                    };
                    amplitude.track(dateRedirect);
                  })
            }
        });
    },
    tripNotFoundEvent: () => {
        $('body').on('amplitude:tripNotFoundEvent', function () {
            amplitudeTriggersSearch.tripNotFound();
        });
    },
};
