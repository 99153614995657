<template lang="html">
<div class="card-box-wrapper">
    <p class="mob-subtitle--medium mob-color--secondary-light-1 bd">
            {{ resources.payment.credit.cardData }}
    </p>
    <div class="row">
        <select-cards class="col-md-6"/>
        <SelectVue
            class="form-group col-md-6 pt-3 pt-sm-0"
            id="credit-card-form-installments"
            name="credit-card-form-installments"
            :attributes="[
                { name: 'orange-id', value: 'credit-card-form-installments'},
            ]"
            :options="installmentOptions"
            v-model="form.creditCard.installments"
        />
    </div>

    <ButtonVue
        type="button"
        @click="$root.$refs.CreditPayment.handleCardForm"
        className="ghost h-25"
        :label="resources.payment.credit.buyWithAnotherCard"
        :attributes="[
            { name: 'orange-id', value: 'credit-card-buy-other-card' },
        ]"
    />

    <submitTerms @trigger-submit="submitCardForm" />
</div>
</template>

<script>
    import SelectVue from '../../../ui/SelectVue.vue'
    import ButtonVue from '../../../ui/ButtonVue.vue';
    import SelectCards from './SelectCards.vue'
    import SubmitTerms from '../SubmitTerms.vue'
    import { mapGetters } from 'vuex'
    export default {
        components: {
            SelectVue,
            ButtonVue,
            SelectCards,
            SubmitTerms
        },
        computed: {
            ...mapGetters('checkoutOnePage', {
                expirationYears: 'expirationYears',
                totals: 'totals',
                forms: "forms",
                tripPassengers: "tripPassengers",
                resources: 'resources',
            }),
            installmentOptions() {
                return this.totals.installmentOptions.map(installment => ({
                    value: installment.htmlValue,
                    label: `${installment.htmlValue}${this.resources.payment.credit.installment} ${installment.installmentPrice} ${this.resources.payment.credit.interestFree}`,
                }))
            },
        },
        data() {
            return {
                form: {
                    creditCard: {
                        installments: '',
                    },
                }
            }
        },
        mounted() {
            this.validateInstallmentOptions();
        },
        methods: {
            submitCardForm() {
                this.$root.$refs.CreditPayment.form.creditCard.installments = this.form.creditCard.installments
                this.$root.$refs.CreditPayment.submitCardForm()
            },
            validateInstallmentOptions() {
                if (!this.totals || !this.totals.installmentOptions) return;

                this.totals.installmentOptions.forEach(option => {
                    if (!option.label.includes(this.resources.payment.credit.interestFree)) {
                        option.label += ` ${this.resources.payment.credit.interestFree || 'sem juros'}`;
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

.card-box-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
}
</style>
