<template lang="html">
    <div class="searchTicketsLoading js-searchTicketsLoading" :style="{ display: 'block'}">
        <div class="searchTicketsLoading__content">
            <span class="searchTicketsLoading__title">
                {{ computedTitle }}
            </span>

            <span class="searchTicketsLoading__wrapperImg">        
                <img class="searchTicketsLoading__animation" :src="`${staticPath}images/bus-loading-min.png`" alt="Loading" width="60" height="61"/>
            </span>

            <span class="searchTicketsLoading__subTitle">
                <span class="searchTicketsLoading__subTitle--orangeColor">
                    {{ computedSubtitle }}
                </span>

                {{ computedSubtitle2 }}
            </span>
        </div>
    </div>
</template>

<script>
import routes from '../../api/routes';
import { mapGetters } from 'vuex';

    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            loadimage: {
                type: String,
            },
            subtitle: {
                type: String,
                default: ''
            },
            subtitle2: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapGetters('checkoutOnePage', {
                resources: 'resources',
            }),
            computedTitle() {
                return this.title || this.resources.base.loadTitle;
            },
            computedSubtitle() {
                return this.subtitle || this.resources.base.loadSubtitle;
            },
            computedSubtitle2() {
                return this.subtitle2 || this.resources.base.loadSubtitle2;
            },
        },
        data() {
            return {
                staticPath: routes.urlStatic,
            };
        },
    }
</script>
