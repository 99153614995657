<template>
    <card-wrapper class="p-0 fixed-bottom">
        <div class="card" v-if="this.activeStep > 1">
            <div
                class="card-header bg-white d-flex justify-content-end border-0"
            >
                <button
                    class="btn-orderDetails mob-text--small-bold px-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSummary"
                    aria-expanded="false"
                    aria-controls="collapseSummary"
                >
                    {{ resources.sidebar.orderDetails }}
                </button>
            </div>
            <div id="collapseSummary" class="collapse">
                <div class="card-body p-0">
                    <h5 class="mob-heading--small px-3">
                        {{ resources.sidebar.orderSummary }}
                    </h5>
                    <travel-data></travel-data>
                    <order-summary
                        class="py-0 orderSummary--mobile"
                    ></order-summary>
                </div>
            </div>
            <div class="collapse-content">
                <div class="card-footer bg-white border-0 p-0">
                    <card-wrapper class="py-0">
                        <p class="d-flex justify-content-between my-0">
                            <span class="mob-subtitle--medium">
                                {{ resources.sidebar.totalValue }}
                            </span>
                            <span class="mob-heading--large mob-color--grayTwo">
                                {{ totalValue }}
                            </span>
                        </p>
                    </card-wrapper>
                </div>
            </div>
        </div>
        <ButtonVue
            :label="buttonData.text"
            :className="buttonData.classes"
            @click="nextStepButton"
            :disabled="disableNextStepButton"
            :attributes="[
                { name: 'orange-id', value: 'sidebarNextButton' },
            ]"
        />
    </card-wrapper>
</template>

<script>
import CardWrapper from './CardWrapper'
import TravelData from './TravelData'
import OrderSummary from './OrderSummary'
import ButtonVue from '../ui/ButtonVue.vue'
import NavigateStepsMixins from './Mixins/NavigateStepsMixins'
import { mapState, mapActions } from 'vuex'

export default {
    components: {
        'card-wrapper': CardWrapper,
        'travel-data': TravelData,
        'order-summary': OrderSummary,
        ButtonVue,
    },
    mixins: [NavigateStepsMixins],
    computed: {
        ...mapState({
            totals: (state) => state.checkout.totals,
            resources: (state) => state.checkout.resources,
            activeInsurance: (state) => state.checkout.activeInsurance,
            tripsPassengers: (state) => state.checkout.tripsPassengers,
            passengersFilled: (state) => state.checkout.passengersFilled,
            passengerForm: (state) => state.checkout.forms.passengerForm,
            isLogged: (state) => state.account.isLogged,
            email: (state) => state.account.email,
            identifyStepCompleted: (state) => state.account.identifyStepCompleted,
            customer: (state) => state.account.customer,
            userTypeRegister: (state) => state.account.userTypeRegister,
        }),
        activeStep() {
            return this.$root.$refs.Checkout.activeStep
        },
        totalValue() {
            if (this.activeInsurance && this.totals.totalWithInsurance) {
                return this.totals.totalWithInsurance.formatted
            } else {
                return this.totals.totalPrice.formatted
            }
        },
        buttonData() {
            let classes = '';
            let text = this.resources.base.next;
            if (this.activeStep === 1) {
                classes += 'spaced';
            }
            if (this.customer.userType === 'identified' && this.identifyStepCompleted) {
                classes += ' ghost';
                text = this.resources.base.nextAsGuest;
            }

            if (this.$root.$refs.Checkout.activeStep === 2) {
                classes = 'spaced';
                text = this.resources.base.next;
            }

            if (this.$root.$refs.Checkout.activeStep === 3) {
                classes += ' d-none';
            }

            return { classes, text };
        },
    },
    methods: {
        ...mapActions('account', ['triggerUserGuest']),
        nextStepButton() {
            if (this.buttonData.text === this.resources.base.nextAsGuest) {
                this.triggerUserGuest();
            }
            this.nextStep();
        }
    },
}
</script>

<style scoped>
.card,
.card-header {
    border: none;
    border-radius: 10px 10px 0px 0px !important;
}
.card {
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
}
.card-header {
    align-self: center;
}

button.vue-button {
    align-self: center;
    width: 80%;
}
</style>
