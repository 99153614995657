<template >
    <div class="countdown-wrapper" >
        <div class="text" :class="{ 'd-none': isMobile() }">
            {{ resources.countDown.textLineOne }}<br />
            {{ resources.countDown.textLineTwo }}
        </div>

        <ProgressCircle
            v-if="timeLeft > 0"
            :duration="timeLeft"
        >
        </ProgressCircle>
    </div>
</template>

<script>
import ProgressCircle from "../ui/ProgressCircle.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        ProgressCircle,
    },
    methods: {
        isMobile() {
            return window.innerWidth <= 600;
        },
    },
    data() {
        return {
            timeLeft: 0,
        };
    },
    computed: {
        ...mapGetters("checkoutOnePage", {
            checkoutTimeout: "checkoutTimeout",
            loadData: "loadData",
            resources: "resources",
        }),
    },
    watch: {
        checkoutTimeout: function (newActiveStep) {
            this.timeLeft = newActiveStep;
        },
    },
    mounted() {
        this.timeLeft = this.checkoutTimeout;
    },
};
</script>

<style lang="scss">
@import "../../../../scss/core/colors.scss";

.countdown-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 100px;
    top: 7px;

    .text {
        color: $mob-white;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.16px;
    }

    @media screen and (max-width: 768px){
        right: 5px;
    }
}
</style>
