<template>
    <div v-if="sections.length && lastSection !== null" class="section-header__container">
        <div class="d-flex justify-content-between order__header clearfix flex-lg-row flex-column">
            <p class="w-100 text--isNormal text--isBold text--isPurple">{{ havereturnsection ?  havereturnsection : '' }} {{ sections[0].tripExtendedDate }} | {{sections[0].departure.hour }}</p>
            
            <!-- TODO: Definir um melhor layout para a proxima sprint - CC-2949 -->
            <!-- <div class="d-lg-none w-100 d-flex flex-row justify-content-between">
                <ButtonVue
                    class="sm mb-3 mr-sm-3 ghost"
                    :label="!fromOrderDetails ? resources.base.backTicketVerify : resources.base.backToTrips"
                    @click="() => fromOrderDetails ? goHistory() : goConsult()"
                />
                
                <ButtonVue
                    class="sm ghost"
                    :label="resources.base.backHome"
                    @click="goHome"
                />
            </div> -->
            
            <!-- <div class="d-lg-flex w-100 justify-content-around d-none">
                <ButtonVue
                    class="sm ml-auto"
                    :label="!fromOrderDetails ? resources.base.backTicketVerify : resources.base.backToTrips"
                    @click="() => fromOrderDetails ? goHistory() : goConsult()"
                />
                
                <ButtonVue
                    class="sm ml-5"
                    :label="resources.base.backHome"
                    @click="goHome"
                />
            </div> -->
        </div>

        <div class="itinerary__content">
            <div class="order__hours d-inline-block mr-2">
                <div class="d-inline-block mr-2">
                    <p class="ticket__time text--isBold text--isSmall text-right">{{ sections[0].departure.hour }}</p>
                    <p class="ticket__time text--isBold text--isSmall text-right">{{ sections[0].arrival.hour }}</p>
                </div>
                <div class="divisor">
                    <span class="empty-circle">&nbsp;</span>
                    <span class="line">&nbsp;</span>
                    <span class="full-circle">&nbsp;</span>
                </div>
            </div>
            <div class="d-inline-block">
                <p class="ticket__trip text--isSmall">{{ sections[0].departure.location }}</p>
                <p class="ticket__trip text--isSmall">{{ lastSection.arrival.location }}</p>
            </div>
        </div>

        <div class="order__body">
            <div class="row">
                <div class="col-6 col-md-3">
                    <p class="order__infos">
                        ID do Pedido
                        <strong>{{$root.$refs.TicketConsult.order.orderNumber}}</strong>
                    </p>
                </div>
                <div class="col-6 col-md-3">
                    <p class="order__infos">
                        Data do Pedido
                        <strong>{{$root.$refs.TicketConsult.order.orderDate}}</strong>
                    </p>
                </div>
                <div class="col-6 col-md-3">
                    <p class="order__infos">
                        Total
                        <strong class="order__infos--price">{{$root.$refs.TicketConsult.order.priceTotal}}</strong>
                    </p>
                </div>
                <div class="col-6 col-md-3">
                    <p class="order__infos">
                        Forma de Pagamento
                        <template v-if="$root.$refs.TicketConsult.order" v-for="payment in $root.$refs.TicketConsult.order.paymentInstrument.selectedPaymentInstruments">
                            <template v-if="payment.paymentMethod === 'CIELO_CREDIT_CARD'">
                                <strong>Cartão de Crédito</strong>
                                <strong>{{ payment.maskedCreditCardNumber }}</strong>
                            </template>
                            <p v-if="payment.paymentMethod === 'PIX'" class="order__infos">
                                <img :src="`${staticPath}/images/pix-image.png`" alt="Pix" class="paymentMethod--pix"/>
                            </p>
                        </template>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import routes from '../../api/routes';
import ButtonVue from '../ui/ButtonVue.vue';

export default {
    props: ['havereturnsection', 'sections'],
    components: {
        ButtonVue
    },
    data() {
        return {
            staticPath: routes.urlStatic,
            fromOrderDetails: false,
        };
    },
    computed: {
        ...mapState({
            resources: (state) => state.checkout.resources,
        }),
        lastSection() {
            if (this.sections.length > 0) return this.sections[this.sections.length - 1];
            else return null;
        }
    },
    methods: {
        goHome(){
            window.location.href = routes.home.show;
        },
        goConsult(){
            window.location.href = routes.order.consult;
        },
        checkFromOrderDetails() {
            const urlParams = new URLSearchParams(window.location.search);
            this.fromOrderDetails = urlParams.has('from') && urlParams.get('from') === 'orderDetails';
        },
        goHistory(){
            window.location.href = routes.order.history;
        }
    },
    mounted(){
        this.checkFromOrderDetails();
    }
};
</script>
