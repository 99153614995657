<template>
    <button
        :class="['vue-button', className, showLoad]"
        :disabled="disabled"
        :type="type"
        @click="handleClick"
        v-bind="extraAttributes"
    >
        {{ label }}
    </button>
</template>

<script>
export default {
    name: 'ButtonVue',
    props: {
        type: {
            type: String,
            default: 'default',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        attributes: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: 'solid',
        },
        isLoad: {
            type: Boolean,
            default: false,
        },
        redirectTo: {
            default: false,
        }
    },
    computed: {
        extraAttributes() {
            let extraAttributes = {}

            if (this.attributes) {
                this.attributes.forEach(({ name, value }) => {
                    extraAttributes[name] = value
                })
            }

            return extraAttributes
        },
        showLoad() {
            return this.isLoad ? 'is__loading' : ''
        },
    },
    methods: {
        handleClick(event) {
            if (!this.disabled) {
                if (this.redirectTo) {
                    this.isLoad = true
                    window.location.assign(this.redirectTo)
                } else {
                    this.$emit('click', event)
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import '../../../../scss/core/colors.scss';

.vue-button {
    height: 64px;
    padding: 20px 32px;
    gap: 8px;
    border: 1px solid $mob-secondary-light-1;
    border-radius: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.4s;

    background-color: $mob-secondary-light-1;
    color: $mob-white;

    &:hover {
        background-color: $mob-primary;
        border: none;
    }

    &:disabled {
        background-color: lighten($mob-secondary-light-1, 20%);
        cursor: not-allowed;
    }

    &.lg {
        height: 72px;
        padding: 24px 40px;
        font-size: 18px;
    }

    &.sm {
        height: 48px;
        padding: 12px 24px;
        font-size: 14px;
    }

    &.fill {
        width: 100%;
    }

    &.outlined {
        background-color: unset;
        color: $mob-secondary-light-1;

        &:hover {
            border: 1px solid $mob-secondary-light-1;
            color: darken($mob-secondary-light-1, 10%);
        }

        &:disabled {
            background-color: lighten($mob-secondary-light-1, 20%);
        }
    }

    &.ghost {
        background-color: unset;
        color: $mob-secondary-light-1;
        text-decoration: underline;
        border: none;
        padding: unset;

        &:hover {
            color: darken($mob-secondary-light-1, 10%);
        }
    }
    &.link {
        background-color: unset;
        color: $mob-secondary-light-1;
        text-decoration: none;
        border: none;
        padding: unset;
        font-size: .75rem;
        font-weight: 700;
        height: min-content !important;

        &:hover {
            color: darken($mob-secondary-light-1, 10%);
        }
    }

}
</style>
