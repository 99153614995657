<template lang="html">
    <section class="checkout-passengers">
        <div class="step-wrapper">
            <div class="step-title mt-3">
                <h2 class="mob-heading--medium mob-color--primary">{{ resources.payment.title }}</h2>
            </div>
            <hr>
            <div class="passengers-section">
                <Payment />
            </div>
        </div>
    </section>
</template>

<script>
import Accordion from "../../ui/Accordion.vue";
import AccordionItem from "../../ui/AccordionItem.vue";
import Payment from "./Payment.vue";
import { mapGetters } from 'vuex';

export default {
    computed: {},
    components: {
        Accordion,
        AccordionItem,
        Payment,
    },
    computed: {
        ...mapGetters('checkoutOnePage', ['resources']),
    },
};
</script>

<style scoped></style>
