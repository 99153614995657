<template>
    <div>
        <div class="custom-select-wrapper">
            <div class="custom-select">
                <div class="selected-option" @click="toggleDropdown">
                    <div class="card-name">
                        <img
                            v-if="selectedCard"
                            :src="selectedCard.cardTypeImage.src"
                            :alt="selectedCard.cardTypeImage.alt"
                            class="card-image"
                        />
                        {{
                            selectedCard
                                ? `Final ${selectedCard.creditCardNumber.replaceAll('*', '').slice(-4)}`
                                : resources.payment.credit.selectCard
                        }}
                    </div>
                </div>
                <div class="options" v-if="dropdownOpen">
                    <div
                        v-for="card in savedCards"
                        :key="card.UUID"
                        class="card-option"
                        @click="selectCard(card)"
                    >
                    <div class="card-name">
                        <img
                            :src="card.cardTypeImage.src"
                            :alt="card.cardTypeImage.alt"
                            class="card-image"
                        />
                        Final {{ card.creditCardNumber.replaceAll('*', '') }}
                    </div>
                        <button
                            type="button"
                            class="remove-card"
                            @click.stop="showModal(card.UUID)"
                        >
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <modal
            v-for="card in savedCards"
            :key="card.UUID"
            :modalId="`remove-card-${card.UUID}`"
            class="remove-card-modal"
            size="md"
        >
            <template v-slot:title class="center">
                {{ resources.payment.credit.removeCard }}
            </template>
            <template v-slot:body>
                <p class="title">
                    {{ resources.payment.credit.removeCardMessage }}
                </p>
                <div class="selected-option">
                    <img
                        :src="card.cardTypeImage.src"
                        :alt="card.cardTypeImage.alt"
                        class="card-image"
                    />
                    Final {{ card.creditCardNumber.replaceAll('*', '') }}
                </div>
            </template>
            <template v-slot:footer>
                <button-vue
                    :data-dismiss="`remove-card-${card.UUID}`"
                    :label="resources.forms.cancel"
                    className="outlined sm"
                    @click="showModal(card.UUID)"
                />
                <button-vue
                @click="removeCard(card.UUID)"
                    :label="resources.payment.credit.removeCardButton"
                    class="sm"
                />
            </template>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "../../../ui/Modal.vue";
import ButtonVue from "../../../ui/ButtonVue.vue";

export default {
    components: {
        Modal,
        ButtonVue,
    },
    computed: {
        ...mapGetters("account", {
            savedCards: "savedCards",
        }),
        ...mapGetters("checkout", {
            resources: "resources",
        }),

        selectedCard() {
            if (this.savedCards) {
                return this.savedCards.find(
                    (card) => card.UUID === this.selectedCardUUID
                );
            }
        },
    },
    mounted() {
        if (this.savedCards) {
            this.selectedCardUUID = this.savedCards[0].UUID;
        }
    },
    created() {
        this.$root.$refs.SelectCards = this;
    },
    data() {
        return {
            selectedCardUUID: null,
            dropdownOpen: false,
        };
    },
    watch: {
        savedCards: {
            immediate: true,
            handler(cards) {
                if (cards && !this.selectedCardUUID) {
                    this.selectedCardUUID = cards[0].UUID;
                    this.toggleCard();
                }
            },
        },
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        selectFirstCardUUID() {
            this.$root.$refs.CreditPayment.form.creditCard.selectedCardUUID = this.savedCards[0].UUID
        },
        selectCard(card) {
            this.selectedCardUUID = card.UUID;
            this.dropdownOpen = false;
            this.toggleCard();
        },
        addLeadingZero(number) {
            return number < 10 ? '0' + number : number.toString();
        },
        toggleCard() {
            var selectedCard = this.savedCards.find(
                (card) => card.UUID === this.selectedCardUUID
            );
            this.$root.$refs.CreditPayment.form.creditCard.cardNumber = selectedCard.creditCardNumber;
            this.$root.$refs.CreditPayment.form.creditCard.cardName = selectedCard.creditCardHolder;
            this.$root.$refs.CreditPayment.form.creditCard.cardYear = selectedCard.creditCardExpirationYear;
            this.$root.$refs.CreditPayment.form.creditCard.cardMonth = this.addLeadingZero(selectedCard.creditCardExpirationMonth);
            this.$root.$refs.CreditPayment.form.creditCard.cardBrand = selectedCard.creditCardType;
            this.$root.$refs.CreditPayment.form.creditCard.cardCvv = '000';
            this.$root.$refs.CreditPayment.form.creditCard.selectedCardUUID = this.selectedCardUUID;

        },
        showModal(uuid) {
            $(`#remove-card-${uuid}`).modal('show')
        },
        hideModal(uuid) {
            $(`#remove-card-${uuid}`).modal('hide')
        },
        async removeCard(uuid) {
            this.$store.dispatch('account/removeCard', uuid);
            $(`#remove-card-${uuid}`).modal('hide')
        },
    },
};
</script>
