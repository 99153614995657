import routes from '../../api/routes';
import ticketApi from '../../api/ticket';

const state = () => ({
    loadDownload: false,
    error: {
        show: false,
        message: '',
        title: '',
        timer: false,
        goHome: false,
    },
    cancelSuccess: {
        show: false,
        message: '',
        title: " ",
        timer: false,
        goHome: false,
    },
    isTicketCancelled: false,
});

const getters = {
    loadDownload: (state) => {
        return state.loadDownload;
    },
    error: (state) => {
        return state.error;
    },
    cancelSuccess: (state) => {
        return state.cancelSuccess;
    },
    isTicketCancelled: (state) => {
        return state.isTicketCancelled;
    }
};

const actions = {
    async cancelOrder({ commit }, data) {
        const params = new URLSearchParams({
            email: data.email,
            orderNumber: data.orderNumber
        });
        const url = `${routes.order.cancelOrder}?${params}`;

        try {
            const response = await fetch(url);
            const responseData = await response.json();
            var reservamosDataAndMeta = {
                "Operation Id": data.orderNumber,
            }
            let reservamosMeta = { };

            if(!responseData.error) {
                commit('setIsTicketCancelled', true);

                reservamosMeta['Success'] = true
                reservamosDataAndMeta.meta = reservamosMeta
                $(window).trigger('reservamos:purchaseCanceled', reservamosDataAndMeta);

            } else {
                console.error(responseData.errorMessage)
                commit('setError', {
                    show: true,
                    message: responseData.error && responseData.errorMessage ? responseData.errorMessage : ' ',
                    title: responseData.error && responseData.error.title ? responseData.error.title : '❗️ Algo deu errado',
                    timer: false,
                    goHome: true,
                });

                reservamosMeta['Error'] = true
                reservamosMeta['Error Message'] = responseData.error && responseData.errorMessage ? responseData.errorMessage : ' '
                reservamosDataAndMeta.meta = reservamosMeta
                $(window).trigger('reservamos:purchaseCanceled', reservamosDataAndMeta);
            }
        } catch (error) {
            console.error('Error fetching cancel order:', error);
        }
    },
    async downloadDistribusionLink({ commit, dispatch, rootState }, data) {
        let req = ticketApi.downloadDistribusionReq(
            routes.order.downloadDistribusion,
            data
        );

        try {
            commit('setLoadDownload', true);

            const response = await fetch(req.url, req.options);
            const data = await response.json();

            if(data.url){
                commit('setLoadDownload', false);
                return data;
            } else {
                commit('setLoadDownload', false);
                throw new Error(data.error.message || 'Erro ao gerar a url de Download do cartão de embarque');
            }
        } catch (error) {
            console.error('Fetch error Download do cartão de embarque: ', error);
        }
    },
};

const mutations = {
    setLoadDownload(state, data) {
        state.loadDownload = data
    },
    setError(state, data) {
        state.error = data;
    },
    setCancelSuccess(state, data) {
        state.cancelSuccess = data;
    },
    setIsTicketCancelled(state, data) {
        state.isTicketCancelled = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
