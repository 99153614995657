'use strict';

var scrollAnimate = require('../components/scrollAnimate');
var formValidation = require('../components/formValidation');
var placingLabels = require('../components/placingLabels');
var toggleModal = require('../components/toggleModal');
const amplitudeTriggersAccount = require('../amplitude/amplitudeTriggersAccount');
const amplitudeHelpers = require('../amplitude/amplitudeHelpers');

function changeInputField(event) {
    var mask = require('../components/cleave');

    if ($(this).val() == 'cpf') {
        $('.registercpf').css('display', 'block');
        $('.registerpassport').css('display', 'none');
        $('#registration-form-passportfield').val('');
        $('.label-passport').removeClass('required');
        $('.label-cpf').addClass('required');
        $('#document-radios').val('cpf');
        $('#document-radios option[value=cpf]').attr('selected', 'selected');
    } else if ($(this).val() == 'passport') {
        $('.registercpf').css('display', 'none');
        $('.registerpassport').css('display', 'block');
        $('#registration-form-cpffield').val('');
        $('.label-passport').addClass('required');
        $('.label-cpf').removeClass('required');
        $('#document-radios').val('passport');
        $('#document-radios option[value=passport]').attr('selected', 'selected');
    } else {
        $('.registercpf').css('display', 'none');
        $('.registerpassport').css('display', 'none');
        $('#registration-form-cpffield').val('');
        $('#registration-form-passportfield').val('');
    }
}


function initializePage() {
    var mask = require('../components/cleave');

    if ($('#document').val() == 'cpf') {
        $('.registercpf').css('display', 'block');
        $('.registercpf').attr('required', 'required');
        $('#registration-form-passportfield').removeAttr('required');
        $('#registration-form-passportfield').css('display', 'none');
    } else if ($('#document').val() == 'passport') {
        $('.registercpf').css('display', 'none');
        $('.registerpassport').css('display', 'block');
        $('#registration-form-cpffield').removeAttr('required', 'required');
        $('#registration-form-passportfield').attr('required');
    }

    try {
        if ($('#registration-form-birthfield').length > 0 && $('#registration-form-cep').length > 0) {
            mask.handleBirthday('#registration-form-birthfield');
            mask.handleCEP('#registration-form-cep');
        }
    } catch (e) {
        console.error(e);
    }

    placingLabels.start();
}

function validarCampo(selector) {
    const campo = $(selector);
    if (campo.val() === '') {
        campo.addClass('is-invalid');
        return false;
    }
    return true;
}

function validarFormulario(form) {
    const validacoes = [
        { selector: '#registration-form-cellphonewithddd', valido: validarCampo('#registration-form-cellphonewithddd') },
        { selector: '#registration-form-fname', valido: validarCampo('#registration-form-fname') },
        { selector: '#registration-form-birthfield', valido: validarCampo('#registration-form-birthfield') },
        {
            selector: $('#documento').val() === 'cpf' ? '#registration-form-cpffield' : '#registration-form-passportfield',
            valido: validarCampo($('#documento').val() === 'cpf' ? '#registration-form-cpffield' : '#registration-form-passportfield')
        }
    ];

    const campoInvalido = validacoes.find(validacao => !validacao.valido);
    if (campoInvalido) {
        scrollAnimate($('.is-invalid').first());
        return false;
    }

    return true;
}



function submitDataMyAccount(event) {
    event.preventDefault();
    var form = $(this);
    var url = form.attr('action');
    form.spinner().start();

    validarFormulario(form);

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            window.location.href = data.redirectUrl;
        },
        error: function (error) {
            form.spinner().stop();
            formValidation(form, error.responseJSON);
            scrollAnimate($('.is-invalid'));
        }
    });
}

function submitNewPassword(event) {
    event.preventDefault();
    var form = $(this);
    var url = form.attr('action');
    form.spinner().start();
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function () {
            form.spinner().stop();
            $('div.modal-header').css('display', 'none');
            $('div.modal-senha').css('display', 'none');
            $('div.modal-resposta').css('display', 'flex');
        },
        error: function (error) {
            form.spinner().stop();
            formValidation(form, error.responseJSON);
            scrollAnimate($('.is-invalid'));
        }
    });
}

function submitDataRegistration(event) {
    var mask = require('../components/cleave');
    $('body').trigger('busLoading:init');
    event.preventDefault();
    var form = $(this);
    var url = form.attr('action');
    const utmData = amplitudeHelpers.getUtmData();
    $('.modal-register').css('z-index', '1');

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            if (!data.success) {
                formValidation(form, data);
                scrollAnimate($('.is-invalid'));
                $('body').trigger('busLoading:stop');
                $('.modal-register').css('z-index', '3');
                toggleModal.show('#modal-register');
                $('body').trigger('busLoading:stop');
                
                $('.btn-link-login').on('click', function () {
                    toggleModal.show('#login-modal');
                    if (window.innerWidth < 800) {
                        $('.modal-background').remove()
        }
                    toggleModal.hide('#modal-register');
                });
            } else if (url.includes('gocheckout=true')) {
                amplitudeTriggersAccount.accountCreated({
                    tipo_documento: $('#cpf').is(':checked') ? 'cpf' : 'passport',
                    ...utmData
                });
                window.location.href = 'https://' + window.location.host + '/s/Mobifacil/cart';
            } else {
                amplitudeTriggersAccount.accountCreated({
                    tipo_documento: $('#cpf').is(':checked') ? 'cpf' : 'passport',
                    ...utmData
                });
                window.location.href = data.redirectUrl;
            }
        },
        error: function (error) {
            form.spinner().stop();
            formValidation(form, error.responseJSON);
            scrollAnimate($('.is-invalid'));
            $('body').trigger('busLoading:stop');

            $('.btn-link-login').on('click', function () {
                toggleModal.show('#login-modal');
                if (window.innerWidth < 800) {
                    $('.modal-background').remove()
    }
                toggleModal.hide('#modal-register');
            });
            toggleModal.show('#modal-register');
        }
    });
}

$(document).ready(function() {
    $('.submit-data').on('click', function(event) {
        event.preventDefault();

        var hasError = false;

        $('.requiredField').each(function() {
            var field = $(this);

            if (field.val().trim() === '') {
                var errorMessage = field.siblings('.invalid-feedback');
                errorMessage.addClass('d-block').text('Campo obrigatório');

                setTimeout(function() {
                    errorMessage.removeClass('d-block').text('');
                }, 5000);

                hasError = true;
            } else {
                field.siblings('.invalid-feedback').removeClass('d-block').text('');
            }
        });

        var birthField = $('#registration-form-birthfield');
        var birthDate = birthField.val().trim();
        var today = new Date();
        var todayFormatted = today.toLocaleDateString('pt-BR');

        if (birthDate === todayFormatted) {
            var birthError = birthField.siblings('.invalid-feedback');
            birthError.addClass('d-block').text('A data de nascimento não pode ser igual à data de hoje.');

            hasError = true;
        } else {
            birthField.siblings('.invalid-feedback').removeClass('d-block').text('');
        }

        var firstInvalidField = $('.requiredField').filter(function() {
            return $(this).next('.invalid-feedback:visible').length > 0;
        }).first();

        if (hasError) {
            var offset = firstInvalidField.offset().top - 100;
            $('html, body').animate({ scrollTop: offset }, 500);
        } else {
            $(this).closest('form').off('submit').submit();
        }
    });
});

module.exports = function () {
    $('#document').on('change', changeInputField);

    $(document).ready(initializePage);

    $('#unfinishedProfile')
        .find('.close')
        .on('click', function (e) {
            e.preventDefault();
            $('#unfinishedProfile').removeClass('show');
            $('#unfinishedProfile').css('display', 'none');
            $('.modal-backdrop').css('display', 'none');
        });

    $(document).on('submit', 'form.profile-form', submitDataMyAccount);
    $('form.changePasswordForm').submit(submitNewPassword);
    $('#login-currentpassword').val('');
    $('#login-password').val('');
    $('#login-passwordconfirm').val('');

    $('form.registration-form').submit(submitDataRegistration);

    $('form.registration-form #registerSubmitButton').click(function () {
        setTimeout(function () {
            if ($('.is-invalid').length > 0) {
                var visibleElements = $('.is-invalid').filter(function (
                    idx,
                    elem
                ) {
                    return $(elem).eq(0).offset().top > 0;
                });
                var firstVisibleElemPosition = $(visibleElements).length
                    ? $(visibleElements).eq(0).offset().top
                    : 0;
                $(window).scrollTop(firstVisibleElemPosition);
            }
        }, 700);
    });

    $('.form-group input').on('keydown', function (e) {
        e.currentTarget.classList.remove('is-invalid');
    });

    $('.form-group select').on('click', function (e) {
        e.currentTarget.classList.remove('is-invalid');
    });

    $(document).ready(function () {
        $("input[name$='document']").click(function () {
            changeInputField($(this));
        });
    });

    $('.privacy-close').on('click', function () {
        toggleModal.hide('#privacyPolicy');
    });

    $('.terms-close').on('click', function () {
        toggleModal.hide('#termsOfUse');
    });
};
