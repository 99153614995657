'use strict';

module.exports = function () {
    const loading = $('.searchTicketsLoading.js-searchTicketsLoading');

    $(document).on('checkoutLoading:init', function () {
        loading.show();
    });

    $(document).on('checkoutLoading:stop', function () {
        loading.hide();
    });
};
