export default {
    getResources(path) {
        return {
            url: path,
            options: {
                method: 'get',
            },
        };
    },
    submitLogintReq(path, loginItem, rootState) {
        let formData = new URLSearchParams();

        formData.append('csrf_token', rootState.checkout.csrf.token);

        for (var key in loginItem) {
            formData.append(key, loginItem[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            },
        };
    },
    createBasicAccount(path, data, rootState) {
        let formData = new URLSearchParams();

        formData.append('csrf_token', rootState.checkout.csrf.token);

        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            },
        };
    },
    logout(path, data, rootState) {
        let formData = new URLSearchParams();

        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'get',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
            },
        };
    },
    identifyCustomerRegisterReq(path, data, rootState) {
        let formData = new URLSearchParams();
        formData.append('csrf_token', rootState.checkout.csrf.token);

        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            },
        };
    },
    submitRegistrationReq(path, data, rootState) {
        let formData = new URLSearchParams();

        formData.append('csrf_token', rootState.checkout.csrf.token);

        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            },
        };
    },
    passwordReset(path, data, rootState) {
        let form = new URLSearchParams();

        form.append('csrf_token', rootState.checkout.csrf.token);

        for (var key in data) {
            form.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: form.toString(),
            },
        };
    },
    removeCardReq(path, uuid, rootState) {
        const url = new URL(path, window.location.origin);
        url.searchParams.append('UUID', uuid);

        return {
            url: url.toString(),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                }
            }
        }
    }
};
