<template lang="html">
    <div>
        <Accordion id="coupon" variant="primary">
            <template v-slot:items>
                <AccordionItem id="coupon-tab" parent="coupon">
                    <template v-slot:header>
                        <i class="icon-percent mr-2"></i>
                        <p class="mob-text--section-title mob-color--grayTwo">
                            {{ resources.coupon.title }}
                        </p>
                    </template>
                    <template v-slot:body>
                        <CouponForm />
                    </template>
                </AccordionItem>
            </template>
        </Accordion>
    </div>

</template>

<script>
import Accordion from "../../ui/Accordion.vue";
import AccordionItem from "../../ui/AccordionItem.vue";
import CouponForm from "./CouponForm.vue";
import { mapGetters } from 'vuex';

export default {
    computed: {},
    components: {
        Accordion,
        AccordionItem,
        CouponForm
    },
    computed: {
        ...mapGetters('checkoutOnePage', ['resources']),
    },
};
</script>

<style scoped lang="scss">
    @media(max-width: 992px) {
        .mob-text--section-title.mob-color--grayTwo{
            font-size: 15px !important;
        }
    }
</style>
