<template>
    <div class="payment-content-pix selected">
        <div class="info">
            <p>
                {{ resources.payment.pix.paragraph1a }}
            </p>
            <strong> {{` ${resources.payment.pix.paragraph1b} ${pixTimeLeft} `}}</strong>
        </div>
        <div class="qr-code-wrapper">
            <canvas ref="qrcodeCanvas" width="200" height="200"></canvas>
            <button
                type="button"
                class="copy mob-color--primary"
                @click="copyAndPast"
            >
                {{ resources.payment.pix.copyCode }}
            </button>
            <div class="alert alert-info" role="alert" v-if="copiedCode">
                {{ resources.payment.pix.copiedCode }}
            </div>
        </div>
        <p class="pix-title">{{ resources.payment.pix.howToPayWithPix }}</p>
        <div class="info-box">
            <div class="mob-color--primary mob-subtitle--default pix-subtitle">
                {{ resources.payment.pix.step1 }}
            </div>
            <p class="step-description mob-text--medium">
                {{ resources.payment.pix.step1Text }}
            </p>
            <div
                class="step-number mob-color--primary mob-subtitle--default pix-subtitle"
            >
                {{ resources.payment.pix.step2 }}
            </div>
            <p class="step-description mob-text--medium">
                {{ resources.payment.pix.step2Text }}
            </p>
            <div
                class="step-number mob-color--primary mob-subtitle--default pix-subtitle"
            >
                {{ resources.payment.pix.step3 }}
            </div>
            <p class="step-description mob-text--medium">
                {{ resources.payment.pix.step3Text }}
            </p>
        </div>
        <p class="confirm-terms">
            {{ resources.payment.pix.confirmTerms }}
        </p>

        <CheckboxVue
            id="subscribe"
            :label="resources.forms.receivePromotionsCheckbox"
            :checked="false"
            v-model="form.saveInfo"
        />
    </div>
</template>

<script>
import PersonalDataForm from "../PersonalDataForm.vue";
import SubmitTerms from "../SubmitTerms.vue";
import CheckboxVue from "../../../ui/CheckboxVue.vue";
import { mapGetters } from "vuex";
import QRCode from "qrcode";
import Modal from "../../../ui/Modal.vue";

export default {
    components: {
        PersonalDataForm,
        SubmitTerms,
        CheckboxVue,
        Modal,
    },
    computed: {
        ...mapGetters("checkout", {
            pixData: "pixData",
            resources: "resources",
        }),
    },
    data() {
        return {
            pixTimeLeft: null,
            form: {
                saveInfo: false,
            },
            timeOut: false,
            copiedCode: false,
        };
    },
    mounted() {
        this.countDown();
        this.generateQRCode();
    },
    watch: {
        "pixData.QRCopyAndPaste": "generateQRCode",
        "$root.$refs.ProgressCircle.remainingTime": "countDown",
    },
    methods: {
        copyAndPast() {
            navigator.clipboard
                .writeText(this.pixData.QRCopyAndPaste)
                .then(() => {
                    console.info("Texto copiado para a área de transferência!");
                    this.copiedCode = true
                })
                .catch((err) => {
                    this.copiedCode = false

                    console.info("Erro ao copiar o texto: ", err);
                });
        },
        countDown() {
            const expiracao = this.$root.$refs.ProgressCircle.remainingTime
            if (expiracao < 0) {
                this.pixTimeLeft = "00:00";
            } else {
                const minutes = Math.floor(
                    (expiracao % (1000 * 60 * 60)) / (1000 * 60)
                );
                const seconds = Math.floor((expiracao % (1000 * 60)) / 1000);
                this.pixTimeLeft = `${this.padTime(minutes)}:${this.padTime(
                    seconds
                )}`;
            }
        },
        padTime(time) {
            return time < 10 ? `0${time}` : time;
        },
        generateQRCode() {
            if (this.pixData && this.pixData.QRCopyAndPaste) {
                QRCode.toCanvas(
                    this.$refs.qrcodeCanvas,
                    this.pixData.QRCopyAndPaste,
                    { width: 200, height: 200 },
                    (error) => {
                        if (error)
                            console.error("Erro ao gerar QR code:", error);
                    }
                );
            }
        },
    },
};
</script>

<style scoped></style>
