<template>
    <div>
        <div v-if="isMobile" id="accordion" class="checkout-payment">
            <div
                class="card collapseBody"
            >
                <div
                    class="card-header"
                    :class="{
                        disabled: paymentStarted
                    }"
                    id="headingOne"
                    @click="selectMethod('PIX')"
                    data-toggle="collapse"
                    data-target="#collapsePix"
                    aria-expanded="true"
                    aria-controls="collapsePix"
                >
                    <div
                        class="collapseHeader--content d-flex justify-content-between"
                    >
                        <h5 class="mb-0 paymentIcon--pix mob-text--default">
                            {{ resources.payment.pixText }}
                        </h5>
                        <span class="rotate-icon"></span>
                    </div>
                </div>

                <div
                    id="collapsePix"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                >
                    <div class="card-body">
                        <PixPayment v-if="selectedMethod === 'PIX'" />
                    </div>
                </div>
            </div>

            <div
                class="card collapseBody"
                v-if="!disableCreditCard"
            >
                <div
                    class="card-header"
                    :class="{
                        disabled: paymentStarted
                    }"
                    id="headingTwo"
                    @click="selectMethod('CREDIT')"
                    data-toggle="collapse"
                    data-target="#collapseCredit"
                    aria-expanded="false"
                    aria-controls="collapseCredit"
                >
                    <div
                        class="collapseHeader--content d-flex justify-content-between"
                    >
                        <h5 class="mb-0 paymentIcon--credit mob-text--default">
                            {{ resources.payment.creditText }}
                        </h5>
                        <span class="rotate-icon"></span>
                    </div>
                </div>
                <div
                    id="collapseCredit"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                >
                    <div class="card-body">
                        <CreditPayment v-if="selectedMethod === 'CREDIT'" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isMobile" class="checkout-payment">
            <PaymentMethod
                :selected-method="selectedMethod"
                @select-method="selectMethod"
            />
            <card-wrapper>
                <div v-if="selectedMethod === 'PIX'">
                    <PixPayment />
                </div>
                <div v-if="selectedMethod === 'CREDIT' && !disableCreditCard">
                    <CreditPayment />
                </div>
            </card-wrapper>
        </div>
        <load
            :title="loadMessage.title"
            :subtitle="loadMessage.subtitle"
            :subtitle2="loadMessage.subtitle2"
            v-if="
                loadPlaceOrder ||
                loadSubmitPaymentPix ||
                loadSubmitPayment ||
                loadPooling
            "
        />
        <payment-error-modal />
    </div>
</template>

<script>
import PaymentMethod from './PaymentMethod.vue'
import PixPayment from './Pix/PixPayment.vue'
import CreditPayment from './Credit/CreditPayment.vue'
import CardWrapper from '../CardWrapper.vue'
import Load from '../../ui/Load.vue';
import Modal from '../../ui/Modal.vue';
import PaymentErrorModal from './PaymentErrorModal.vue';

import { mapGetters } from 'vuex'
import { validarFormatoCelular } from './validationPersonalData';


export default {
    components: {
        PaymentMethod,
        PixPayment,
        CreditPayment,
        CardWrapper,
        Load,
        Modal,
        PaymentErrorModal
    },
    computed: {
        ...mapGetters('account', {
            email: 'email',
            customerNumber: 'customerNumber',
            userTypeRegister: 'userTypeRegister',
            isLogged: 'isLogged',
            customer: 'customer',
        }),
        ...mapGetters('checkout', {
            loadSubmitPayment: 'loadSubmitPayment',
            loadPlaceOrder: 'loadPlaceOrder',
            loadPooling: 'loadPooling',
            loadSubmitPaymentPix: 'loadSubmitPaymentPix',
            paymentStarted: 'paymentStarted',
            resources: 'resources',
            isMobile: 'isMobile',
            device: 'device',
            disableCreditCard: 'disableCreditCard'
        }),
        loadMessage: {
            get() {
                return {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: "",
                }
            },
            set(value) {
                return value
            }
        },
    },
    data() {
        return {
            selectedMethod: 'PIX',
            customerData: {
                name: '',
                email: '',
                phone: '',
                documentNumber: '',
                documentType: 'cpf',
                customerNumber: '',
                device: '',
            },
            erros: {
                canBeSubmitted: false,
                name: '',
                email: '',
                phone: '',
                documentNumber: '',
            },
            showLoad: false,
            completedEmail:false
        }
    },
    created() {
        this.customerData.device = this.device;
        this.$root.$refs.Payment = this;
    },
    mounted() {
        this.customerData.email = this.email
        if(this.email) {
            this.completedEmail = true;
        }
        this.customerData.customerNumber = this.customerNumber

        if(this.isLogged) {
            this.customerData.name = this.customer.profile.fullName
            this.customerData.email = this.customer.profile.email
            this.customerData.phone = this.customer.profile.phoneHome
            this.customerData.documentType = this.customer.profile.docType || 'cpf'
            this.customerData.documentNumber = this.customer.profile[this.customerData.documentType]
        }
    },
    methods: {
        selectMethod(method) {
            this.selectedMethod = method
        },
        isValidCPF(cpf) {
            cpf = cpf.replace(/\D/g, '');

            if (cpf.length !== 11) {
                return false;
            }

            if (/^(\d)\1{10}$/.test(cpf)) {
                return false;
            }

            let sum = 0;
            let mod = 0;

            for (let i = 0; i < 9; i++) {
                sum += parseInt(cpf.charAt(i)) * (10 - i);
            }

            mod = 11 - (sum % 11);
            if (mod >= 10) {
                mod = 0;
            }

            if (mod !== parseInt(cpf.charAt(9))) {
                return false;
            }

            sum = 0;
            for (let i = 0; i < 10; i++) {
                sum += parseInt(cpf.charAt(i)) * (11 - i);
            }

            mod = 11 - (sum % 11);
            if (mod >= 10) {
                mod = 0;
            }

            if (mod !== parseInt(cpf.charAt(10))) {
                return false;
            }

            return true;
        },
        validateName() {
            let fullNamePattern = /^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+(?: [a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+)+$/;

            if (!fullNamePattern.test(this.customerData.name.trim())) {
                this.erros.name = this.resources.errors.name;
                return false;
            }
            this.erros.name = '';
            return true;
        },
        validateEmail() {
            if (!this.customerData.email) {
                this.erros.email = this.resources.errors.email;
                return false;
            }
            this.erros.email = '';
            return true;
        },
        validatePhone() {
            if (!this.customerData.phone) {
                this.erros.phone = this.resources.errors.phone;
                return false;
            }

            if (!validarFormatoCelular(this.customerData.phone)) {
                this.erros.phone = this.resources.errors.phoneInvalid;
                return false;
            }

            this.erros.phone = '';
            return true;
        },
        validateDocumentNumber() {
            if (!this.customerData.documentNumber) {
                this.erros.documentNumber = this.resources.errors.document;
                return false;
            }
            if (this.customerData.documentType === "cpf" && !this.isValidCPF(this.customerData.documentNumber)) {
                this.erros.documentNumber = this.resources.errors.invalidCpf;
                return false;
            }
            this.erros.documentNumber = '';
            return true;
        },
        validateCustomerData() {
            const isNameValid = this.validateName();
            const isEmailValid = this.validateEmail();
            const isPhoneValid = this.validatePhone();
            const isDocumentNumberValid = this.validateDocumentNumber();
            this.erros.canBeSubmitted = isNameValid && isEmailValid && isPhoneValid && isDocumentNumberValid;
        },
        handleLoadMessage(type) {
            const messages = {
                loadSubmitPayment: {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: this.resources.payment.loadingSubtitle2a,
                },
                loadSubmitPaymentPix: {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: this.resources.payment.loadingSubtitle2b,
                },
                loadPlaceOrder: {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: this.resources.payment.loadingSubtitle2c,
                },
                loadPooling: {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: this.resources.payment.loadingSubtitle2d,
                },
            }
            this.loadMessage = messages[type]
        },
        showPersonalForm() {
            return !this.isLogged
        },
        showIncompleteRegistration() {
            return !this.customer.hasPhoneNumber || !this.customer.hasCpf;
        },
    },
    watch: {
        loadSubmitPayment: function(newVal) {
            this.handleLoadMessage('loadSubmitPayment');
        },
        loadSubmitPaymentPix: function(newVal) {
            this.handleLoadMessage('loadSubmitPaymentPix');
        },
        loadPlaceOrder: function(newVal) {
            this.handleLoadMessage('loadPlaceOrder');
        },
        loadPooling: function(newVal) {
            this.handleLoadMessage('loadPooling');
        },
    },
}
</script>

<style scoped lang="scss">
.card.collapseBody {
    padding: 10px;
    background: #ffff;
    border-radius: 10px;
    border: none;

    .card-header {
        background: white;
        border: none;
    }
}
</style>
