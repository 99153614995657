var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal-data-form"},[_c('div',{staticClass:"d-flex justify-content-center purple--messageButton"},[_c('p',{staticClass:"mob-text--large"},[_vm._v("\n            Já tem uma conta Mobifácil?\n        ")]),_vm._v(" "),_c('ButtonVue',{attrs:{"label":"Entrar","className":"ghost h-100 mob-text--large mob-color--primary","attributes":[
            { name: 'orange-id', value: 'checkoutLogoutButton' },
        ]},on:{"click":_vm.openLoginModal}}),_vm._v(" "),_c('LoginModal',{attrs:{"hideRegister":true}}),_vm._v(" "),_c('RegisterModal')],1),_vm._v(" "),_c('h4',{staticClass:"mob-text--section-title bd my-3"},[_vm._v("Ou insira seus dados para continuar com a compra.")]),_vm._v(" "),_c('h4',{staticClass:"mob-subtitle--small mob-color--secondary bd mb-3"},[_vm._v("Dados Pessoais")]),_vm._v(" "),_c('InputVue',{staticClass:"form-group",class:{
            disabled: _vm.$root.$refs.Identification.completedEmail
        },attrs:{"id":"personal-form-email","name":"personal-form-email","type":"email","placeholder":_vm.resources.forms.email,"attributes":[
            { name: 'orange-id', value: 'personal-form-email' },
            { name: 'autocomplete', value: 'off'}
        ],"errorMessage":_vm.$root.$refs.Identification.erros.email},model:{value:(_vm.$root.$refs.Identification.customerData.email),callback:function ($$v) {_vm.$set(_vm.$root.$refs.Identification.customerData, "email", $$v)},expression:"$root.$refs.Identification.customerData.email"}}),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('InputVue',{staticClass:"form-group col-md-6",attrs:{"id":"personal-form-name","name":"personal-form-name","className":"personal-form-name","placeholder":_vm.resources.forms.fullName,"attributes":[{ name: 'orange-id', value: 'personal-form-name' }],"errorMessage":_vm.$root.$refs.Identification.erros.name},model:{value:(_vm.$root.$refs.Identification.customerData.name),callback:function ($$v) {_vm.$set(_vm.$root.$refs.Identification.customerData, "name", $$v)},expression:"$root.$refs.Identification.customerData.name"}}),_vm._v(" "),_c('InputVue',{staticClass:"form-group col-md-6",attrs:{"id":"personal-form-cellphone","name":"personal-form-cellphone","useMask":"phone","placeholder":_vm.resources.forms.phoneWithDDD,"attributes":[
                { name: 'orange-id', value: 'personal-form-cellphone' },
                { name: 'minlength', value: 11 },
                { name: 'autocomplete', value: 'tel' },
            ],"errorMessage":_vm.$root.$refs.Identification.erros.phone},model:{value:(_vm.$root.$refs.Identification.customerData.phone),callback:function ($$v) {_vm.$set(_vm.$root.$refs.Identification.customerData, "phone", $$v)},expression:"$root.$refs.Identification.customerData.phone"}})],1),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('SelectVue',{staticClass:"form-group col-md-6",attrs:{"id":"personal-form-documentType","name":"personal-form-documentType","attributes":[
                { name: 'orange-id', value: 'personal-form-documentType'},
            ],"options":_vm.documentTypeOptions,"errorMessage":_vm.$root.$refs.Identification.erros.documentType},model:{value:(_vm.$root.$refs.Identification.customerData.documentType),callback:function ($$v) {_vm.$set(_vm.$root.$refs.Identification.customerData, "documentType", $$v)},expression:"$root.$refs.Identification.customerData.documentType"}}),_vm._v(" "),_c('InputVue',{staticClass:"form-group col-md-6",attrs:{"id":"personal-form-documentNumber","name":"personal-form-documentNumber","placeholder":_vm.resources.forms.documentNumber,"attributes":[{ name: 'orange-id', value: 'personal-form-documentNumber' }],"useMask":_vm.$root.$refs.Identification.customerData.documentType.toLowerCase(),"errorMessage":_vm.$root.$refs.Identification.erros.documentNumber},model:{value:(_vm.$root.$refs.Identification.customerData.documentNumber),callback:function ($$v) {_vm.$set(_vm.$root.$refs.Identification.customerData, "documentNumber", $$v)},expression:"$root.$refs.Identification.customerData.documentNumber"}})],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }