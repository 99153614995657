<template>
    <div>
        <label v-if="label" :for="id" :class="{ error: errorMessage }">{{ label }}</label>
        <input
            :id="id"
            :type="computedType"
            :value="inputVal"
            :placeholder="placeholder"
            :class="{ error: errorMessage, [className]: className }"
            :disabled="disabled"
            :name="name ? name : id"
            :readonly="readonly"
            @input="onInput"
            @change="onChange"
            v-bind="extraAttributes"
            v-mask="mask"
        />
        <span
            v-if="isPassword"
            :class="showPassword ? 'password-icon show' : 'password-icon hide'"
            @click="togglePasswordVisibility"
        ></span>
        <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
    </div>
</template>

<script>
export default {
    name: 'InputVue',
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: false,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        attributes: {
            type: Array,
            default: () => [],
        },
        isPassword: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        useMask: {
            type: String,
            default: '',
        },
        errorMessage: {
            type: String,
            default: '',
        },
        inputValidation: {
            type: Function,
        },
    },
    data() {
        return {
            showPassword: false,
            inputVal: this.value,
        };
    },
    computed: {
        computedType() {
            return this.isPassword && this.showPassword ? 'text' : this.type;
        },
        extraAttributes() {
            let extraAttributes = {};
            if (this.attributes) {
                this.attributes.forEach(({ name, value }) => {
                    extraAttributes[name] = value;
                });
            }
            return extraAttributes;
        },
        mask() {
            switch (this.useMask) {
                case 'cpf':
                    return '###.###.###-##';
                case 'rg':
                    return this.inputVal.length > 12 ? '###.###.###-##' : 'NN.###.###-N';
                case 'passport':
                    return 'NNNNNNNNNNNNNNNNNNNNNNNNNNNN';
                case 'phone':
                    return '(##) # ####-####';
                case 'cep':
                    return '#####-###';
                case 'date':
                    return '##/##/####';
                case 'time':
                    return '##:##';
                case 'credit-card':
                    return '#### #### #### ####';
                default:
                    return '';
            }
        },
    },
    watch: {
        value(newVal) {
            this.inputVal = newVal;
        },
        inputVal(newVal) {
            this.$emit('input', newVal);
        }
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        onInput(event) {
            if (this.inputValidation) {
                this.inputValidation(event);
            }
            this.inputVal = event.target.value;
            this.$emit('input', this.inputVal);
            this.$emit('input-helper', this.inputVal);
        },
        onChange(event) {
            this.inputVal = event.target.value;
            this.$emit('input', this.inputVal);
        },
    },
    mounted() {
        setTimeout(() => {
            const emailField = document.getElementById(this.id);
            if (emailField) {
                this.inputVal = emailField.value;
                this.$emit('input', this.inputVal);
            }
        }, 100);
    }
};
</script>

<style scoped lang="scss">
@import '../../../../scss/core/colors.scss';

div {
    margin: 0;
    position: relative;
}

div label {
    display: block;
    margin-bottom: 5px;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

div input {
    width: 100%;
    padding: 1rem;
    border: 1px solid $mediumGray;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: -0.01em;
    color: $mob-black;
    background-color: $mob-white;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.error-message {
    color: $mob-error;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin-top: 5px;
    text-align: left;
}

label.error {
    color: $mob-error;
}

.error {
    border-color: $mob-error;
}

input[readonly] {
    background-color: $lightGray;
    color: $mob-black;
    border: 1px solid $mob-gray-3;
    cursor: not-allowed;
}

input[readonly] {
    background-color: $lightGray;
    color: $mob-black;
    border: 1px solid $mob-gray-3;
    cursor: not-allowed;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $mob-white inset !important;
    box-shadow: 0 0 0 30px $mob-white inset !important;
    -webkit-text-fill-color: $mob-black !important;
}
</style>
