<template lang="html">
    <div v-if="!paymentStarted">
        <div
            class="d-flex align-items-center justify-content-start form--coupomCode"
            :class="{ 'loading-skeleton': loadData }"
        >
            <input-vue
                id="couponCode"
                type="text"
                className="w-full"
                :placeholder="resources.forms.addCoupon"
                v-model="couponCode"
            />
            <button-vue
                :label="resources.forms.apply"
                class="mob-text--large ml-3"
                id="applyCouponCode"
                @click="submitCoupon"
                :isLoad="load"
            />
        </div>
        <div v-if="errorMessage" class="error-message m-0 mt-2 mob-text--small-bold">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="successMessage m-0 mt-2 mob-text--small-bold">
            {{ successMessage }}
        </div>
        <CouponRemoveModal />
    </div>
</template>

<script>
import CouponRemoveModal from './CouponRemoveModal.vue';
import InputVue from '../../ui/InputVue.vue';
import ButtonVue from '../../ui/ButtonVue.vue';
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('checkoutOnePage', {
            resources: 'resources',
            loadData: 'loadData',
            paymentStarted: 'paymentStarted',
        }),
    },
    components: {
        InputVue,
        ButtonVue,
        CouponRemoveModal
    },

    data() {
        return {
            couponCode: '',
            errorMessage: '',
            successMessage: '',
            load: false
        };
    },

    methods: {
        async submitCoupon() {
            this.load = true;
            try {
                const result = await this.$store.dispatch(
                    'checkoutOnePage/addCouponCode',
                    this.couponCode
                );
                if (result.success) {
                    this.errorMessage = '';
                    this.successMessage = this.resources.payment.couponApplied;
                    this.couponCode = '';
                    this.resetMessages();
                } else {
                    this.errorMessage = result.message || this.resources.payment.couponError;
                    this.successMessage = '';
                    this.resetMessages();
                }
            } catch (error) {
                this.errorMessage = error.message || this.resources.payment.couponError;
                this.successMessage = '';
                this.resetMessages();
            }
            this.load = false;
        },
        resetMessages() {
            setTimeout(() => {
                this.errorMessage = '';
                this.successMessage = '';
            }, 7000);
        },
    },
};
</script>

<style scoped>
.error-message {
    color: red;
    margin-top: 10px;
}
.successMessage {
    color: #65c466;
    margin-top: 10px;
}
</style>
