<template>
    <div class="timer-container" orange-id="timer_countdown">
        <svg viewBox="0 0 100 100" class="timer-svg">
            <g transform="rotate(-90 50 50)">
                <circle
                    cx="50"
                    cy="50"
                    r="45"
                    stroke="lightgray"
                    stroke-width="6"
                    fill="none"
                />
                <circle
                    cx="50"
                    cy="50"
                    r="45"
                    stroke="#F8A629"
                    stroke-width="6"
                    fill="none"
                    :stroke-dasharray="circumference"
                    :stroke-dashoffset="strokeDashoffset"
                    class="timer-circle"
                    transform="rotate(0 50 50)"
                />
                <text
                    x="50%"
                    y="40%"
                    class="timer-text"
                    text-anchor="middle"
                    dominant-baseline="middle"
                    transform="rotate(90 50 50)"
                    orange-id="timer-minutes"
                >
                    {{ minutes }}m
                </text>
                <text
                    x="50%"
                    y="68%"
                    class="timer-text"
                    text-anchor="middle"
                    dominant-baseline="middle"
                    transform="rotate(90 50 50)"
                    orange-id="timer-seconds"
                >
                    {{ seconds }}s
                </text>
            </g>
        </svg>
        <modal
            v-if="showModal"
            modalId="expiredTime"
            :isStatic="true"
            :showCloseButton="false"
            :startOpen="true"
        >
            <template v-slot:title>{{ resources.countDown.modalTitle }}</template>
            <template v-slot:body
                >{{ resources.countDown.modalMessage }}</template
            >
            <button-vue
                slot="footer"
                @click="continueShopping"
                :label="resources.countDown.modalButton"
                className="sm"
            ></button-vue>
        </modal>
    </div>
</template>

<script>
    import Modal from "./Modal.vue";
    import routes from "../../api/routes";
    import ButtonVue from "./ButtonVue.vue";
    import { mapGetters } from "vuex";

    export default {
    components: {
        modal: Modal,
        "button-vue": ButtonVue,
    },
    props: {
        duration: {
            type: Number,
            required: true,
        },
    },
    created() {
        this.$root.$refs.ProgressCircle = this;
    },
    data() {
        return {
            remainingTime: this.duration,
            startTime: null,
            circumference: 2 * Math.PI * 45,
            showModal: false,
        };
    },
    computed: {
        ...mapGetters("checkout", {
            resources: "resources",
            loadData: "loadData",
        }),
        minutes() {
            return Math.floor(this.remainingTime / 60000)
                .toString()
                .padStart(2, "0");
        },
        seconds() {
            return Math.floor((this.remainingTime % 60000) / 1000)
                .toString()
                .padStart(2, "0");
        },
        strokeDashoffset() {
            return (
                this.circumference +
                (this.remainingTime / this.duration) * this.circumference
            );
        },
    },
    mounted() {
        this.startTime = performance.now();
        this.updateTimer();
    },
    beforeDestroy() {
        cancelAnimationFrame(this.intervalId);
    },
    methods: {
        updateTimer() {
            const now = performance.now();
            const elapsedTime = now - this.startTime;
            this.remainingTime = this.duration - elapsedTime;

            if (this.remainingTime > 0) {
                this.intervalId = requestAnimationFrame(this.updateTimer);
            } else {
                this.remainingTime = 0;
                this.showModal = true;
                cancelAnimationFrame(this.intervalId);
            }
        },
        continueShopping() {
            window.location.href = routes.home.show;
        },
    },
};
</script>

<style scoped>
.timer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
}

.timer-svg {
    width: 100%;
    height: 100%;
}

.timer-circle {
    transition: stroke-dashoffset 1s linear, stroke 1s linear;
}

.timer-text {
    fill: #ffffff;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.14px;
}
</style>
