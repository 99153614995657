import api from '../../api/trips';
import routes from '../../api/routes';

const state = () => ({
    dates: [],
    servicos: []
});

const getters = {
    servicos: state => state.servicos,
    dates: state => state.dates
};

const actions = {
    setServices({ commit }, servicos) {
        commit("setServices", servicos);
    },
    setDates({ commit }, dates) {
        commit("setDates", dates);
    },
    updateDates({ commit }, updatedDate) {
        commit("updateDate", updatedDate);
    },
    async fetchAvailability({ commit, rootState }, data) {
        const { url, options } = api.verifyAvailability(routes.ticket.availability, data, rootState);

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const { datesAvailability } = await response.json();
            return datesAvailability;
        } catch (error) {
            console.error('Error fetching availability:', error);
        }
    },
    async getAvailabilityDates({ commit }) {
        const url = routes.ticket.availability;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const { datesAvailability } = await response.json();
            commit("updateDate", datesAvailability);
        } catch (error) {
            console.error('Error fetching availability dates:', error);
        }
    },
};

const mutations = {
    setServices(state, servicos) {
        state.servicos = servicos;
    },
    setDates(state, dates) {
        state.dates = dates;
    },
    updateDate(state, data) {
        if (!state.dates || state.dates.length === 0) {
            state.dates = data.days;
        }
        data.updatedDate.forEach(upDate => {
            const index = state.dates.findIndex(date => date.date === upDate.date);
            if (index !== -1) {
                state.dates[index].enabled = upDate.enabled;
            } else {
                state.dates.push(upDate);
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
