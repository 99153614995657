<template lang="html">
    <div class="travelData">
        <card-wrapper v-for="(tripGroup, index) in trips" :key="index" :class="{'connection-trip': isConnectionTrip(tripGroup), }" class="px-1 py-0">
            <template v-for="(trip, tripIndex) in tripGroup">
                <div
                    v-if="trip.type !== 'connection'"
                    :key="'normal-' + tripIndex"
                    :class="{ 'mt-5': isFirstTripSection(tripGroup, tripIndex) && trip.tripSection == 'VOLTA' }"
                >
                    <p
                        class="mob-subtitle--default d-block mt-4"
                        v-if="isFirstTripSection(tripGroup, tripIndex)"
                        >
                        {{ trip.tripSection.charAt(0).toUpperCase() + trip.tripSection.slice(1).toLowerCase() }}: {{ trip.tripExtendedDateCheckout }}
                    </p>
                    <div class="d-flex justify-content-between mb-3">
                        <img :src="trip.companyLogo.src" :alt="trip.companyLogo.title" class="companyLogo" width="76" height="25"/>
                        <div class="rounded border mob-text--large px-2 py-1 justify-content-between tripClass">{{ trip.class }} <span class="classInfo"></span></div>
                    </div>
                    <div class="borderTravel pl-3 d-flex justify-content-between">
                        <div class="">
                            <p class="mob-text--large travelData--origin mb-1 mb-md-3">{{ trip.departure.hour }} <span class="mob-text--small mob-color--grayTwo">{{ trip.departure.location }}</span></p>
                            <p class="mob-text--large travelData--destination mb-0">{{ trip.arrival.hour }} <span class="mob-text--small mob-color--grayTwo">{{ trip.arrival.location }}</span></p>
                        </div>
                        <div class="d-flex flex-wrap flex-row justify-content-end seatsContainer" v-if="!isMobile">
                            <span class="vue-seat" v-for="(seat, seatIndex) in trip.seatNumbers" :key="seatIndex">{{ seat }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="isConnectionTrip(tripGroup, trip.tripSection) && tripIndex === getFirstConnectionIndex(tripGroup, trip.tripSection)" class="d-flex justify-content-start mt-5 mb-4">
                    <div class="connectionTripBorder pr-2"></div>
                    <div class="connectionMessage py-3">
                        <p class="mob-text--small-bold travelData--connectionMessage">{{ resources.base.paragraph1 }}</p>
                        <p class="mob-text--small-bold travelData--connectionMessageTwo">{{ `${resources.base.paragraph2} ${trip.waitingTime}` }}</p>
                    </div>
                </div>
            </template>
        </card-wrapper>
    </div>
</template>

<script>
import CardWrapper from './CardWrapper';
import { mapGetters, mapState } from "vuex";

export default {
    computed: {
        ...mapGetters('checkoutOnePage', {
            trips: "trips",
            resources: "resources",
            loadData: "loadData"
        }),
        ...mapState('checkoutOnePage', {
            isMobile: state => state.isMobile
        })
    },
    components: {
        'card-wrapper': CardWrapper,
    },
    methods: {
        isConnectionTrip(tripGroup, tripSection = null) {
            return tripGroup.some(trip => trip.isConnection === true && (!tripSection || trip.tripSection === tripSection));
        },
        getFirstConnectionIndex(tripGroup, tripSection = null) {
            return tripGroup.findIndex(trip => trip.isConnection === true && (!tripSection || trip.tripSection === tripSection));
        },
        isFirstTripSection(tripGroup, tripIndex) {
        const currentTrip = tripGroup[tripIndex];
        return tripGroup.findIndex(trip => trip.tripSection === currentTrip.tripSection && trip.type !== 'connection') === tripIndex;
    }
    },
    mounted() {
        console.log(this.trips);
    }
}
</script>

<style scoped>
</style>
