<template>
    <div class="personal-data-form">
        <p class="mob-subtitle--medium mob-color--secondary-light-1 bd">
            {{ resources.forms.personalData }}
        </p>
        <InputVue
            id="personal-form-name"
            name="personal-form-name"
            className="personal-form-name"
            :placeholder="resources.forms.fullName"
            :attributes="[{ name: 'orange-id', value: 'personal-form-name' }]"
            v-model="$root.$refs.Payment.customerData.name"
            :errorMessage="$root.$refs.Payment.erros.name"
        />
        <div class="form-row">
            <InputVue
                class="form-group col-md-6"
                id="personal-form-email"
                name="personal-form-email"
                type="email"
                :placeholder="resources.forms.email"
                :attributes="[
                    { name: 'orange-id', value: 'personal-form-email' }
                ]"
                :class="{
                    disabled: $root.$refs.Payment.completedEmail
                }"
                v-model="$root.$refs.Payment.customerData.email"
                :errorMessage="$root.$refs.Payment.erros.email"
            />
            <InputVue
                class="form-group col-md-6"
                id="personal-form-cellphone"
                name="personal-form-cellphone"
                useMask="phone"
                :placeholder="resources.forms.phoneWithDDD"
                :attributes="[
                    { name: 'orange-id', value: 'personal-form-cellphone' },
                    { name: 'minlength', value: 11 },
                    { name: 'autocomplete', value: 'tel' },
                ]"
                v-model="$root.$refs.Payment.customerData.phone"
                :errorMessage="$root.$refs.Payment.erros.phone"
            />
        </div>
        <div class="form-row">
            <SelectVue
                class="form-group col-md-6"
                id="personal-form-documentType"
                name="personal-form-documentType"
                :attributes="[
                    { name: 'orange-id', value: 'personal-form-documentType'},
                ]"
                :options="documentTypeOptions"
                v-model="$root.$refs.Payment.customerData.documentType"
                :errorMessage="$root.$refs.Payment.erros.documentType"
            />
            <InputVue
                class="form-group col-md-6"
                id="personal-form-documentNumber"
                name="personal-form-documentNumber"
                :placeholder="resources.forms.documentNumber"
                :attributes="[{ name: 'orange-id', value: 'personal-form-documentNumber' }]"
                :useMask="$root.$refs.Payment.customerData.documentType.toLowerCase()"
                v-model="$root.$refs.Payment.customerData.documentNumber"
                :errorMessage="$root.$refs.Payment.erros.documentNumber"
            />
        </div>
    </div>
</template>

<script>
import InputVue from '../../ui/InputVue.vue'
import SelectVue from '../../ui/SelectVue.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        InputVue,
        SelectVue,
    },
    computed: {
        ...mapGetters('checkout', {
            resources: 'resources',
        }),
        documentTypeOptions() {
            return [
                { value: 'cpf', label: 'CPF' },
                { value: 'passport', label: this.resources.forms.passport },
                { value: 'rg', label: 'RG' },
            ]
        },
    },
}
</script>
