/**
 * Função sendLogToGoogleChat
 *
 * Envia logs de erros para um canal específico no Google Chat usando Webhook.
 * A função constrói uma mensagem personalizada com base nos parâmetros fornecidos e envia via uma requisição AJAX.
 *
 * @param {Object} params - Objeto contendo os parâmetros da função.
 * @param {string} params.message - Mensagem principal que será exibida no campo `text` do Google Chat.
 * @param {string} params.title - Título do log que aparece no header da mensagem do Google Chat.
 * @param {string} params.deviceType - Tipo de dispositivo ou contexto onde o erro ocorreu, usado como header da seção.
 * @param {string} params.eventName - Nome do evento que gerou o erro, exibido como subtítulo do header.
 * @param {string} params.errorMessage - Mensagem de erro detalhada que será exibida na mensagem do Google Chat.
 * @param {string} params.params - Parâmetros enviados para amplitude.
 *
 * @returns {void} - A função não retorna nenhum valor.
 */
function sendLogToGoogleChat({
    message,
    title,
    deviceType,
    subtitle,
    errorMessage,
    params
}) {
    const webhookUrl = 'https://chat.googleapis.com/v1/spaces/AAAAhyVtzk8/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=utvzE6ZJRcuVvUIX0qf33xonN2rx51Ty9QywZx8LV3k';

    const logData = {
        text: message,
        cards: [{
            header: {
                title: `${title} - code: ${subtitle}`,
                subtitle: errorMessage
            },
            sections: [{
                header: deviceType,
                widgets: [{
                    textParagraph: {
                        text: params
                    }
                }]
            }]
        }]
    };

    $.ajax({
        url: webhookUrl,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(logData),
        error: function (xhr, status, error) {
            console.error('Erro ao enviar log para o Google Chat:', error);
        }
    });
}

module.exports = {
    sendLogToGoogleChat,
};
