var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment-methods"},[_c('div',{staticClass:"method",class:{
            selected: _vm.selectedMethod === 'PIX',
            disabled: _vm.paymentStarted
        },on:{"click":function($event){return _vm.$emit('select-method', 'PIX')}}},[_c('span',{staticClass:"payment-pix"},[_vm._v(_vm._s(_vm.resources.payment.pixText))]),_vm._v(" "),_c('span',{staticClass:"arrow-right"})]),_vm._v(" "),(!_vm.disableCreditCard)?_c('div',{staticClass:"method",class:{
            selected: _vm.selectedMethod === 'CREDIT',
            disabled: _vm.paymentStarted
        },on:{"click":function($event){return _vm.$emit('select-method', 'CREDIT')}}},[_c('span',{staticClass:"payment-credit"},[_vm._v(_vm._s(_vm.resources.payment.creditText))]),_vm._v(" "),_c('span',{staticClass:"arrow-right"})]):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }