<template lang="html">
    <div class="stepMenuContainer">
        <div
            class="stepMenu"
            v-for="step in steps"
            :key="step.number"
            :class="{
                active: isActiveStep(step.number),
                current: activeStep === step.number,
            }"
        >
            <span class="stepIndicatorNumber">{{ step.number }}</span>
            <p class="stepIndicatorName">{{ step.name }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters('checkout', {
            resources: "resources",
        }),
        populateSteps() {
            this.steps[0].name = this.resources.steppers.identify || 'Identificação';
            this.steps[1].name = this.resources.steppers.passengers || 'Passageiros';
            this.steps[2].name = this.resources.steppers.payment || 'Pagamento';
        },
    },
    mounted() {
        this.populateSteps;
    },
    data() {
        return {
            activeStep: this.$root.$refs.Checkout.activeStep || 1,
            steps: [
                { name: '', number: 1 },
                { name: '', number: 2 },
                { name: '', number: 3 },
            ],
        };
    },
    watch: {
        "$root.$refs.Checkout.activeStep": function (newActiveStep) {
            this.activeStep = newActiveStep;
        }
    },
    methods: {
        isActiveStep(stepNumber) {
            return stepNumber <= this.activeStep;
        },
    },
};
</script>
