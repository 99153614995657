<template lang="html">
    <div class="col-lg-8 col-12 checkout-main" :class="{ 'loading-skeleton': loadData }">
        <Stepper />

        <ButtonVue
            v-if="this.isMobile && showBackButton"
            :label="resources.base.back"
            className="ghost back-button"
            :attributes="[{ name: 'orange-id', value: 'checkoutNextStepButton' }]"
            @click="previousStep"
        />

        <div class="checkout-steps">
            <Identification v-if="activeStep === 1" />
            <Passengers v-if="activeStep === 2" />
            <card-wrapper v-if="activeStep === 3 && isMobile && !paymentStarted ">
                <CouponForm />
            </card-wrapper>

            <Insurance v-if="activeStep === 3 && isMobile"/>
            <Payment v-if="activeStep === 3" />
        </div>

        <NavigateButtons v-if="!isMobile && !paymentStarted"/>

        <Modal
            modalId="confirmRemoveModal"
            size="md"
            :showCloseButton="true"
            :isStatic="true"
            :startOpen="false"
        >
            <template v-slot:title> {{ resources.payment.removeCoupon || '' }} </template>
            <template v-slot:body>
                {{ resources.payment.removeCouponMessage }}
            </template>
            <template v-slot:footer>
                <button-vue
                    :label="resources.base.no"
                    className="outlined"
                    data-dismiss="modal"
                />
                <button-vue
                    :label="resources.base.yes"
                    className="solid ml-3"
                    @click="removeCoupon"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import Stepper from './Stepper.vue'
import Identification from './Identification/Identification.vue'
import Passengers from './Passengers/Passengers.vue'
import Payment from './Payment/Payment.vue'
import NavigateButtons from './NavigateButtons.vue'
import ButtonVue from '../ui/ButtonVue.vue'
import { mapState } from 'vuex'
import NavigateStepsMixins from './Mixins/NavigateStepsMixins';
import Insurance from './insurance';
import CouponForm from './CouponForm';
import Modal from '../ui/Modal.vue'
import CardWrapper from './CardWrapper.vue'

export default {
    components: {
        Stepper,
        Identification,
        Passengers,
        Payment,
        NavigateButtons,
        ButtonVue,
        CouponForm,
        Insurance,
        Modal,
        CardWrapper,
    },
    mixins: [NavigateStepsMixins],
    data() {
        return {
            activeStep: this.$root.$refs.Checkout.activeStep,
            backStep: this.$root.$refs.Checkout.backStep,
        }
    },
    computed: {
        ...mapState({
            paymentStarted: (state) => state.checkout.paymentStarted,
            resources: (state) => state.checkout.resources,
            isMobile: (state) => state.checkout.isMobile,
            loadData: (state) => state.checkout.loadData,
            tripsPassengers: (state) => state.checkout.tripsPassengers,
            passengerForm: (state) => state.checkout.forms.passengerForm,
            // account
            email: (state) => state.account.email,
            isLogged: (state) => state.account.isLogged,
            identifyStepCompleted: (state) => state.account.identifyStepCompleted,
            customer: (state) => state.account.customer,
            couponToRemove: (state) => state.checkout.couponToRemove,
        }),
    },
    methods: {
        updatePaymentStatus(status) {
            this.paymentStarted = status
        },
        async removeCoupon() {
            this.$store.dispatch(
                'checkout/removeCouponCode',
                this.couponToRemove
            )
            $(`#confirmRemoveModal`).modal('hide')
        },
    },
    watch: {
        '$root.$refs.Checkout.activeStep': function (newActiveStep) {
            this.activeStep = newActiveStep
            if (this.activeStep === 1) {
                this.$store.commit('account/setLockUserInput', false);
            }
        },
    },
}
</script>
