export default {
    getCheckoutDataReq(path) {
        return {
            url: path,
            options: {
                method: 'get',
            },
        };
    },
    addCouponCode(path, couponCode, rootState) {
        let formData = new URLSearchParams();
        let data = {couponCode};
        data.csrf_token = rootState.checkout.csrf.token;
        data.billingForm = JSON.stringify(rootState.checkout.forms.billingForm);
        data.email = rootState.account.email

        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            },
        };
    },
    handleInsuranceReq(path, data, rootState) {
        let formData = new URLSearchParams();
        data.billingForm = JSON.stringify(rootState.checkout.forms.billingForm);
        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            },
        };
    },
    removeCouponCode(path, couponCode, rootState) {
        const url = new URL(path, window.location.origin);
        url.searchParams.append('uuid', couponCode);

        return {
            url: url.toString(),
            options: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                }
            },
        };
    },
    submitPaymentReq(path, data, rootState) {
        let formData = new URLSearchParams();
        data.csrf_token = rootState.checkout.csrf.token;
        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData,
            },
        };
    },

    placeOrderReq(path, data, rootState) {
        let formData = new URLSearchParams();
        data.csrf_token = rootState.checkout.csrf.token;
        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData,
            },
        };
    },
    verifyPassengers(path, data, rootState) {
        let formData = new URLSearchParams();

        for (var key in data) {
            formData.append(key, JSON.stringify(data[key]));
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            }
        }
    },
    distribusionPoolingReq(path) {
        return {
            url: path,
            options: {
                method: 'get',
            },
        };
    },
    queryPixPoolingReq(path, rootState) {
        const url = new URL(path, window.location.origin);
        url.searchParams.append('csrf_token', rootState.checkout.csrf.token);
        return {
            url: url.toString(),
            options: {
                method: 'get',
            },
        };
    },
    saveAbandonedCartReq(path, data, rootState) {
        let formData = new URLSearchParams();
        for (var key in data) {
            formData.append(key, data[key]);
        }

        return {
            url: path,
            options: {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData,
            },
        };
    },
};
