<template lang="html">
    <div class="travelData" :class="{'loading-skeleton': loadData}">
        <card-wrapper v-for="(tripGroup, index) in trips" :key="index" :class="{'connection-trip': isConnectionTrip(tripGroup), }">
            <template v-for="(trip, tripIndex) in tripGroup">
                <div
                    v-if="trip.type !== 'connection'"
                    :key="'normal-' + tripIndex"
                    :class="{ 'mt-5': trip.tripSection == 'VOLTA' }"
                >
                    <p
                        class="mob-subtitle--default d-none d-md-block"
                        v-if="tripIndex === 0 || trip.tripSection === 'VOLTA' || !isConnectionTrip(tripGroup)"
                        >
                        {{ trip.tripSection.charAt(0).toUpperCase() + trip.tripSection.slice(1).toLowerCase() }}: {{ trip.tripExtendedDateCheckout }}
                    </p>
                    <div class="d-flex justify-content-between mb-3">
                        <img :src="trip.companyLogo.src" :alt="trip.companyLogo.title" class="companyLogo" width="76" height="25"/>
                        <div class="rounded border mob-text--large px-2 py-1 justify-content-between tripClass">{{ trip.class }} <span class="classInfo"></span></div>
                    </div>
                    <div class="borderTravel pl-3">
                        <p class="mob-text--large travelData--origin mb-1 mb-md-3">{{ trip.departure.hour }} <span class="mob-text--small mob-color--grayTwo">{{ trip.departure.location }}</span></p>
                        <p class="mob-text--large travelData--destination mb-0 mb-md-3">{{ trip.arrival.hour }} <span class="mob-text--small mob-color--grayTwo">{{ trip.arrival.location }}</span></p>
                    </div>
                </div>
                <div v-if="isConnectionTrip(tripGroup) && tripIndex === getFirstConnectionIndex(tripGroup)" class="d-flex justify-content-between my-4">
                    <div class="connectionTripBorder pr-2"></div>
                    <div class="connectionMessage py-3 w-100">
                        <p class="mob-text--small-bold travelData--connectionMessage">{{ resources.base.travel.paragraph1 }}</p>
                        <p class="mob-text--small-bold travelData--connectionMessageTwo">{{ `${resources.base.travel.paragraph2} ${trip.waitingTime}` }}</p>
                    </div>
                </div>
            </template>
        </card-wrapper>
    </div>
</template>

<script>
import CardWrapper from './CardWrapper';
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters('checkout', {
            trips: "trips",
            resources: "resources",
            loadData: "loadData"
        }),
    },
    components: {
        'card-wrapper': CardWrapper,
    },
    methods: {
        isConnectionTrip(tripGroup) {
            return tripGroup.some(trip => trip.isConnection === true);
        },
        getFirstConnectionIndex(tripGroup) {
            return tripGroup.findIndex(trip => trip.isConnection === true);
        }
    }
}
</script>

<style scoped>
</style>
