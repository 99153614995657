'use strict';

const iconNotify = $('.icon-notify').attr('src');
const badgeNotify = $('link[rel="icon"]').prop('href');
const swPath = $('.icon-notify').data('sw-path');

function registerServiceWorker() {
    return navigator.serviceWorker
        .register(swPath)
        .then(function (registration) {
            return registration;
        })
        .catch(function (err) {
            console.error('Unable to register service worker.', err);
        });
}

function askPermission() {
    return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    }).then(function (permissionResult) {
        if (permissionResult !== 'granted') {
            throw new Error("We weren't granted permission.");
        } else {
            clevertap?.notifications.push({
                "titleText": "Permitir notificações",
                "bodyText": "Receba notificações sobre as nossas novidades e ofertas!",
                "okButtonText": "Permitir",
                "rejectButtonText": "Não Obrigado",
                "serviceWorkerPath": swPath
            });
        }
    });
}

async function init() {
    await registerServiceWorker();
}

async function initNotifyer() {
    await askPermission();
}

async function notify({
    title, body, icon, actions, image
}) {
    navigator.serviceWorker.getRegistration(swPath).then(function (reg) {
        reg.showNotification(title, {
            body,
            icon: icon || iconNotify || null,
            badge: badgeNotify,
            vibrate: [200, 100, 300, 100, 600],
            image,
            actions
        });
    });
}

module.exports = {
    init: init,
    initNotifyer: initNotifyer,
    notify: notify
};
