<template lang="html">
    <modal modalId="register-modal" size="lg" :centerTitle="true" @close="resetStep">
        <template v-slot:title class="center">
            <img
                :src="`${staticPath}images/logo-branco.svg`"
                :alt="resources.base.mobifacil"
                :title="resources.base.mobifacil"
                width="130"
                height="32"
            />
        </template>
        <template v-slot:body>
            <p class="title mb-3">{{ `${resources.base.hello} ${resources.base.signup}` }}</p>
            <div v-if="stepRegister === 1">
                <identify-account ref="identifyAccount" :email="email"> </identify-account>
            </div>
            <div v-else-if="stepRegister === 2">
                <form-register :email="email"> </form-register>
            </div>
        </template>
    </modal>
</template>

<script>
    import Modal from '../ui/Modal.vue';
    import routes from '../../api/routes';
    import IdentifyAccount from './IdentifyAccount.vue';
    import FormRegister from './FormRegister.vue';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            'modal': Modal,
            'identify-account': IdentifyAccount,
            'form-register': FormRegister,
        },
        created()  {
            this.$root.$refs.Register = this;
        },
        data() {
            return {
                staticPath: routes.urlStatic,
                stepRegister: 1,
            };
        },
        computed: {
            ...mapGetters('account', ['email']),
            ...mapGetters('checkout', ['resources']),
        },
        methods: {
            nextStep() {
                this.stepRegister += 1;
            },
            goToStep(step) {
                this.stepRegister = step;
            },
            resetStep() {
                this.stepRegister = 1;
                if (this.$refs.identifyAccount) {
                    this.$refs.identifyAccount.clientIdentify = false;
                }
            }
        },
        mounted(){
            $('#register-modal').on('show.bs.modal', function (event) {
                if (window.innerWidth < 800) {
                        $('.modal-background').remove()
                }
            });
        },
        watch: {
            changeStep(step) {
                if (step) {
                    this.stepRegister = step;
                }
            },
            stepRegister(step) {
                this.$emit('changeStep', step);
            }
        }
    }
</script>

<style scoped lang="scss">
    #register-modal {
        .title {
            color: #333333;
            font-family: 'Montserrat', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: center;
        }
    }
</style>
