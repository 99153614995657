<template>
    <Accordion id="identification-content" variant="primary" :open="true">
        <template v-slot:items>
            <AccordionItem id="identification" parent="identification-content" :open="true" :collapsible="false">
                <template v-slot:header>
                    <h2 class="mob-heading--medium mob-color--primary mb-0">
                        Identificação
                    </h2>
                    <hr class="my-2">
                </template>
                <template v-slot:body>
                    <input type="hidden" :value="checkoutBegin" class="checkoutBeginData">
                    <LoggedUser v-if="isLogged" />
                    <PersonalDataForm  v-if="showPersonalForm()" />
                    <IncompleteRegistration v-else-if="showIncompleteRegistration()" />
                </template>
            </AccordionItem>
        </template>
    </Accordion>
</template>

<script>
import Accordion from '../../ui/Accordion.vue';
import AccordionItem from '../../ui/AccordionItem.vue';
import LoggedUser from './LoggedUser.vue'
import { mapState } from 'vuex'
import PersonalDataForm from '../PersonalData/PersonalDataForm.vue'
import IncompleteRegistration from '../PersonalData/IncompleteRegistration.vue';
import IdentificationMixins from './IdentificationMixins.js'

export default {
    components: {
        LoggedUser,
        Accordion,
        AccordionItem,
        IncompleteRegistration,
        PersonalDataForm
    },
    props: {},
    mixins: [IdentificationMixins],
    computed: {
        ...mapState({
            checkoutBegin: (state) => state.checkoutOnePage.checkoutBegin,
            //account
            isLogged: (state) => state.account.isLogged,
        }),
    },
    created() {
        this.$root.$refs.Identification = this;
        if (this.isLogged) {
            sessionStorage.setItem('loginChecked', true);
        }
    },
}
</script>
