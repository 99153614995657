<template lang="html">
    <div>
        <h2 class="mob-heading--small mb-3">{{ resources.sidebar.orderSummary }}</h2>
        <travel-data></travel-data>
        <order-summary class="mt-3"></order-summary>
    </div>
</template>

<script>
import CardWrapper from './CardWrapper';
import TravelData from './TravelData';
import OrderSummary from './OrderSummary';
import { mapGetters } from 'vuex';

export default {
    components: {
        'card-wrapper': CardWrapper,
        'travel-data': TravelData,
        'order-summary': OrderSummary,
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            loadData: 'loadData',
            paymentStarted: 'paymentStarted',
            resources: 'resources',
        })
    }
}
</script>
