<template>
    <div class="passengers-wrapper">
        <div class="passenger-data">
            <div class="passenger-title--row">
                <p class="mob-subtitle--large mob-color--secondary-light-1">
                    {{ resources.passengers.passenger }} {{ index + 1 }}
                    <span class="mob-text--small mob-color--primary">
                        {{ passenger.route }}
                    </span>
                </p>
                <span class="vue-seat"> {{ passenger.seat }} </span>
            </div>

            <div v-if="!passenger.isReadonly && isLogged">
                <CheckboxVue
                    v-if="passenger.showUserPassengerButton"
                    :id="'userPassenger' + id"
                    :attributes="[{ name: 'orange-id', value: 'userPassenger' + id }]"
                    :label="resources.passengers.userPassenger"
                    :checked="userPassenger"
                    v-model="userPassenger"
                />
            </div>
            <div class="passenger-input--row-full" v-if="!passenger.isReadonly && savedPassengers">
                <SelectVue
                    v-if="!userPassenger && savedPassengers"
                    :id="'savedPassengers' + id"
                    :attributes="[{ name: 'orange-id', value: 'savedPassengers' + id }]"
                    :options="formattedSavedPassengers"
                    :readonly="readonlyInputs"
                    v-model="activeSavedPassenger"
                />
            </div>

            <div class="passenger-input--row">
                <InputVue
                    :id="'fullName' + id"
                    type="text"
                    :placeholder="resources.forms.fullNameExample"
                    :attributes="[
                        { name: 'orange-id', value: 'fullName' + id },
                        { name: 'pattern', value: `[A-Za-z\\s]+'` }
                    ]"
                    :readonly="readonlyInputs"
                    :inputValidation="inputValidation"
                    :errorMessage="showPassengersErrors ? passenger.errors.name : ''"
                    v-model="passenger.fullName"
                />
                <InputVue
                    :id="'birthdate' + id"
                    type="text"
                    :placeholder="resources.forms.birthdate"
                    useMask="date"
                    :attributes="[
                        { name: 'orange-id', value: 'birthdate' + id }
                    ]"
                    :readonly="false"
                    :errorMessage="showPassengersErrors ? passenger.errors.birthdate : ''"
                    v-model="passenger.birthdate"
                />
                <SelectVue
                    :id="'documentType' + id"
                    :attributes="[{ name: 'orange-id', value: 'documentType' + id }]"
                    :options="documentTypeOptions"
                    :readonly="readonlyInputs"
                    v-model="passenger.documentType"
                />
                <InputVue
                    :id="'passengerID' + id"
                    type="text"
                    :placeholder="resources.forms.number"
                    :attributes="[{ name: 'orange-id', value: 'passengerID' + id }]"
                    :useMask="passenger.documentType.toLowerCase()"
                    :readonly="readonlyInputs"
                    :errorMessage="showPassengersErrors ? passenger.errors.passengerID : ''"
                    v-model="passenger.passengerID"
                />
            </div>
            <div
                class="passenger-input--row savePassenger"
                v-if="isLogged"
            >
                <CheckboxVue
                    :id="'savePassenger' + id"
                    :attributes="[{ name: 'orange-id', value: 'savePassenger' + id }]"
                    :label="resources.passengers.savePassenger"
                    :disabled="userPassenger"
                    v-model="passenger.savePassenger"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CheckboxVue from '../../ui/CheckboxVue.vue';
import InputVue from '../../ui/InputVue.vue';
import SelectVue from '../../ui/SelectVue.vue';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        InputVue,
        SelectVue,
        CheckboxVue,
    },
    props: {
        passengerProp: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        tripType: {
            type: String,
            required: true,
        },
        isLogged: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            passenger: this.passengerProp,
            userPassenger: this.passengerProp.userPassenger,
            activeSavedPassenger: 'newPassenger',
        };
    },
    computed: {
        ...mapState({
            tripsPassengers: (state) => state.checkout.tripsPassengers,
            showPassengersErrors: (state) => state.checkout.showPassengersErrors,
            resources: (state) => state.checkout.resources,
            reservamosPassengers: (state) => state.checkout.reservamosPassengers,
            // account
            customer: (state) => state.account.customer,
            savedPassengers: (state) => state.account.customer.savedPassengers,
        }),
        id() {
            return `${this.tripType.charAt(0)}${this.index}`;
        },
        readonlyInputs() {
            return this.passengerProp.isReadonly || this.userPassenger;
        },
        formattedSavedPassengers() {
            const formattedSavedPassengers = this.savedPassengers.map((passenger) => ({
                value: passenger.name,
                label: passenger.name,
            }));
            const defaultValue = { label: this.resources.forms.selectSavedPassenger, value: 'newPassenger' };
            return [defaultValue, ...formattedSavedPassengers]
        },
        documentTypeOptions() {
            const currentValue = this.passengerProp.originalDocumentType;
            const options = [
                { value: 'cpf', label: this.resources.forms.cpf },
                { value: 'rg', label: this.resources.forms.rg },
                { value: 'passport', label: this.resources.forms.passport },
            ];

            if (currentValue) {
                const currentIndex = options.findIndex(option => option.value === currentValue);
                if (currentIndex > -1) {
                    const currentOption = options.splice(currentIndex, 1)[0];
                    options.unshift(currentOption);
                }
            }
            return options;
        },
    },
    watch: {
        passenger: {
            handler() {
                try {
                    this.updatePassengerData();
                } catch (error) {
                    console.error('Error updating passenger data', error);
                }
            },
            deep: true,
        },
        activeSavedPassenger: {
            handler() {
                if (!(this.activeSavedPassenger === 'newPassenger')) {
                    const savedPassengerData = this.getActiveSavedPassengerData(this.activeSavedPassenger);
                    this.passenger = {
                        ...this.passenger,
                        fullName: savedPassengerData.name,
                        passengerID: savedPassengerData.docNumber,
                        documentType: savedPassengerData.docType.toLowerCase(),
                        birthdate: savedPassengerData.birthDate,
                    };
                } else {
                    this.passenger = {
                        ...this.passenger,
                        fullName: '',
                        passengerID: '',
                        documentType: 'cpf',
                        birthdate: '',
                    };
                }
            },
        },
        userPassenger: {
            handler() {
                this.passenger.userPassenger = this.userPassenger;
                if (this.userPassenger) {
                    const docType = this.customer.profile.docType;
                    docType = docType === 'Passaporte' ? 'passport' : 'cpf';
                    this.passenger = {
                        ...this.passenger,
                        documentType: docType.toLowerCase(),
                        docNumber: this.customer.profile[docType],
                        passengerID: this.customer.profile[docType],
                        fullName: this.customer.profile.fullName,
                        birthdate: this.customer.profile.birthdate,
                    };
                }
            },
        },
    },
    methods: {
        updatePassengerData() {
            const passengersData = this.tripsPassengers[`${this.tripType}PassengersData`];
            passengersData[this.index] = this.passenger;
            const updatedTripsPassengers = {
                departurePassengersData:
                    this.tripType === 'departure'
                        ? passengersData
                        : this.tripsPassengers.departurePassengersData,
                returnPassengersData:
                    this.tripType === 'return'
                        ? passengersData
                        : this.tripsPassengers.returnPassengersData,
            };

            const allPassengersData = this.reservamosPassengers;

            allPassengersData.forEach((passenger, index) => {
                if (passenger["Passenger Seat"] === this.passenger.seat) {
                    this.$set(allPassengersData, index, {
                        "Passenger Name": this.passenger.fullName,
                        "Passenger Document Type": this.passenger.documentType,
                        "Passenger Document Id": this.passenger.passengerID,
                        "Passenger Birthdate": this.passenger.birthdate ? this.passenger.birthdate.split('/').reverse().join('-') : "",
                        "Passenger Seat": this.passenger.seat,
                    });
                }
            });

            this.$store.commit('checkout/setTripsPassengers', updatedTripsPassengers);
            this.$store.commit('checkout/setReservamosPassengers', allPassengersData);

        },
        getActiveSavedPassengerData() {
            return this.savedPassengers.find(
                (passenger) => passenger.name === this.activeSavedPassenger
            );
        },
        inputValidation(event) {
            const name = event.target.value
            const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+$/

            if (!regex.test(name)) {
                event.target.value = name.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+/g, '')
            }
        },
    },
};
</script>
