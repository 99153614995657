<template>
    <div class="card order__card">
        <!-- ida -->
        <template v-if="departureSectionTrip.length">
            <section-header :havereturnsection="returnSectionTrip.length ? 'IDA -' : null" :sections="departureSectionTrip"></section-header>
            <div v-for="section in departureSectionTrip">
                <section-container :section="section"></section-container>
            </div>
        </template>

        <hr v-if="returnSectionTrip.length" style="border: 2px dashed #ccc;">

        <!-- volta -->
        <template v-if="returnSectionTrip.length">
            <section-header :havereturnsection="returnSectionTrip.length ? 'VOLTA -' : null" :sections="returnSectionTrip"></section-header>
            <div v-for="section in returnSectionTrip">
                <section-container :section="section"></section-container>
            </div>
        </template>




    </div>
</template>

<script>
import ButtonVue from '../ui/ButtonVue.vue';
import CancelModal from './CancelModal.vue';
import SectionContainer from './SectionContainer.vue';
import SectionHeader from './SectionHeader.vue';
import routes from '../../api/routes';
import { mapState } from 'vuex';

export default {
    props: ['trip'],
    components: {
        'section-container': SectionContainer,
        'section-header': SectionHeader,
        'cancel-modal': CancelModal,
        'button-vue': ButtonVue
    },
    data() {
        return {
            staticPath: routes.urlStatic,
            fromOrderDetails: false,
        };
    },
    computed: {
        ...mapState({
            resources: (state) => state.checkout.resources,
        }),
        isCancelled() {
            //return false
            return this.$root.$refs.TicketConsult.order.isCancelled
        },
        departureSectionTrip() {
            return this.trip.filter(t => t.tripSection === 'IDA');
        },
        returnSectionTrip() {
            return this.trip.filter(t => t.tripSection === 'VOLTA');
        }
    },
    methods: {
        async canlerOrder(){
        },
        showCancelOrderModal() {
            $(`#cancelOrderModal`).modal('show');
        },
        goHome(){
            window.location.href = routes.home.show;
        },
        goConsult(){
            window.location.href = routes.order.consult;
        },
        checkFromOrderDetails() {
            const urlParams = new URLSearchParams(window.location.search);
            this.fromOrderDetails = urlParams.has('from') && urlParams.get('from') === 'orderDetails';
        },
        goHistory(){
            window.location.href = routes.order.history;
        }
    },
    mounted(){
        this.checkFromOrderDetails();
    }
};
</script>

<style>

.purpleButton{
    color: #55368B !important;
}

</style>
