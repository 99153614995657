<template>
    <div class="personal-data-form">
        <p class="mob-subtitle--medium mob-color--secondary-light-1 bd">
            {{ resources.forms.personalData }}
        </p>
        <p>
            {{ resources.forms.incompleteRegistration }}
        </p>
        <InputVue
            class="form-group col-12 p-0"
            id="personal-form-cellphone"
            name="personal-form-cellphone"
            useMask="phone"
            :placeholder="resources.forms.phoneWithDDD"
            :attributes="[
                { name: 'orange-id', value: 'personal-form-cellphone' },
                { name: 'minlength', value: 11 },
                { name: 'autocomplete', value: 'tel' },
            ]"
            v-model="$root.$refs.Payment.customerData.phone"
            :errorMessage="$root.$refs.Payment.erros.phone"
            v-if="!customer.hasPhoneNumber"
        />
        <div class="form-row" v-if="!customer.hasCpf">
            <SelectVue
                class="form-group col-md-6 disabled"
                id="personal-form-documentType"
                name="personal-form-documentType"
                :attributes="[
                    { name: 'orange-id', value: 'personal-form-documentType'},
                ]"
                :options="documentTypeOptions"
                v-model="$root.$refs.Payment.customerData.documentType"
                :errorMessage="$root.$refs.Payment.erros.documentType"
            />
            <InputVue
                class="form-group col-md-6"
                id="personal-form-documentNumber"
                name="personal-form-documentNumber"
                :placeholder="resources.forms.documentNumber"
                :attributes="[{ name: 'orange-id', value: 'personal-form-documentNumber' }]"
                :useMask="$root.$refs.Payment.customerData.documentType.toLowerCase()"
                v-model="$root.$refs.Payment.customerData.documentNumber"
                :errorMessage="$root.$refs.Payment.erros.documentNumber"
            />
        </div>
    </div>
</template>

<script>
import InputVue from '../../ui/InputVue.vue';
import SelectVue from '../../ui/SelectVue.vue';

import { mapGetters } from 'vuex';

export default {
    components: {
        InputVue,
        SelectVue,
    },
    computed: {
        ...mapGetters('account', {
            customer: 'customer',
        }),
        ...mapGetters('checkout', {
            resources: 'resources',
        }),
        documentTypeOptions() {
            return [
                { value: 'cpf', label: 'CPF' },
            ]
        },
    },
}
</script>
