<template>
    <div class="steps-nav" :class="{ full: showBackButton }" v-if="$root.$refs.Checkout.activeStep <= 2">
        <ButtonVue
            v-if="showBackButton"
            className="ghost"
            :label="resources.base.back"
            :attributes="[{ name: 'orange-id', value: 'checkoutBackStepButton' }]"
            @click="previousStep"
        />
        <ButtonVue
            v-if="showForwardButton && !showGhostButton"
            :label="resources.base.next"
            :disabled="disableNextStepButton"
            :attributes="[{ name: 'orange-id', value: 'checkoutNextStepButton' }]"
            @click="nextStep"
            :isLoad="isLoading"
            className="w-180"
        />
        <ButtonVue
            v-if="showForwardButton && showGhostButton"
            className="ghost"
            :label="resources.base.continueWithoutLogIn"
            :disabled="disableNextStepButton"
            :attributes="[{ name: 'orange-id', value: 'checkoutNextStepButton' }]"
            @click="nextStep"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ButtonVue from '../ui/ButtonVue.vue';
import NavigateStepsMixins from './Mixins/NavigateStepsMixins';

export default {
    components: {
        ButtonVue,
    },
    mixins: [NavigateStepsMixins],
    props: {},
    computed: {
        ...mapState({
            passengersFilled: (state) => state.checkout.passengersFilled,
            tripsPassengers: (state) => state.checkout.tripsPassengers,
            passengerForm: (state) => state.checkout.forms.passengerForm,
            resources: (state) => state.checkout.resources,
            reservamosData: (state) => state.checkout.reservamosData,
            // account
            isLogged: (state) => state.account.isLogged,
            email: (state) => state.account.email,
            identifyStepCompleted: (state) => state.account.identifyStepCompleted,
            customer: (state) => state.account.customer,
            userTypeRegister: (state) => state.account.userTypeRegister
        }),
        showGhostButton() {
            return this.activeStep === 1 && this.customer.userType === 'identified';
        },
        activeStep() {
            return this.$root.$refs.Checkout.activeStep;
        },
    },
    methods: {},
};
</script>

<style scoped>
    .w-180 {
        min-width: 180px;
    }
</style>
