<template>
    <section class="checkout-passengers">
        <div class="step-wrapper">
            <div class="step-title mt-3">
                <h2 class="mob-heading--medium mob-color--primary">{{ resources.steppers.passengers }}</h2>
            </div>
            <hr>
            <div class="passengers-section">
                <h3 class="mob-subtitle--default my-0">{{ resources.passengers.departureTrip }}</h3>
                <PassengersList
                    v-for="(passenger, index) in tripsPassengersData.departurePassengersData"
                    :key="'d_' + index"
                    :passengerProp="passenger"
                    :index="index"
                    :isLogged="customer.userType === 'logged'"
                    tripType="departure"
                />
            </div>

            <div class="passengers-section" v-if="hasReturnTrip">
                <h3 class="mob-subtitle--default mt-5">{{ resources.passengers.returnTrip }}</h3>
                <PassengersList
                    v-for="(passenger, index) in tripsPassengersData.returnPassengersData"
                    :key="'r_' + index"
                    :passengerProp="passenger"
                    :index="index"
                    :isLogged="customer.userType === 'logged'"
                    tripType="return"
                />
            </div>
        </div>
        <Modal
            modalId="errorModalPassengers"
            size="md"
            :showCloseButton="true"
            :isStatic="true"
            :startOpen="true"
            v-if="passengersError.show"
        >
            <template v-slot:body>
                {{ passengersError.message }}
            </template>
        </Modal>
    </section>
</template>

<script>
import PassengersList from './PassengersList.vue';
import Modal from '../../ui/Modal.vue';
import { mapState, mapGetters } from 'vuex';
import validatePassengersData from './passengersValidation.js';

export default {
    components: {
        PassengersList,
        Modal,
    },
    props: {
        activeStep: {
            type: Number,
        },
        isLogged: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            trips: (state) => state.checkoutOnePage.trips,
            passengersFilled: (state) => state.checkoutOnePage.passengersFilled,
            tripsPassengers: (state) => state.checkoutOnePage.tripsPassengers,
            resources: (state) => state.checkoutOnePage.resources,
            passengersError: (state) => state.checkoutOnePage.showModalPassengersErrors,
            passengerForm: (state) => state.checkoutOnePage.forms.passengerForm,

            // account
            customer: (state) => state.account.customer,
        }),
        ...mapGetters({
            hasReturnTrip: 'checkoutOnePage/hasReturnTrip',
        }),
    },
    data() {
        return {
            passengerDataError: false,
            tripsPassengersData: {}
        };
    },
    watch: {
        tripsPassengers: {
            handler(tripsPassengers) {
                const departurePassengersValidated = validatePassengersData(tripsPassengers.departurePassengersData);
                this.passengerDataError = departurePassengersValidated.error;

                if (this.tripsPassengers.returnPassengersData.length) {
                    const returnPassengersValidated = validatePassengersData(tripsPassengers.returnPassengersData);
                    this.passengerDataError = this.passengerDataError || returnPassengersValidated.error;
                }

                if (this.passengerDataError) {
                    this.$store.commit('checkoutOnePage/setPassengersFilled', false);
                    return;
                }

                this.passengerDataError = false;
                this.$store.commit('checkoutOnePage/setPassengersFilled', true);
            },
            deep: true,
        },
    },
    methods: {
        getTripsPassengers() {
            const departurePassengersData = [];
            const returnPassengersData = [];

            this.trips.forEach((trips) => {
                trips.forEach((trip) => {
                    const lineItems = trip.lineItems;
                    const route = trip.departure.location + ' > ' + trip.arrival.location;
                    const serviceNumber = trip.serviceNumber;
                    const savePassenger = false;
                    const departureTime = trip.departure.hour;
                    let isStudent = false;
                    let birthdate = ''
                    lineItems.forEach((lineItem, index) => {
                        const id = trip.ids[index];
                        const seat = lineItem.seatNumber
                        let userPassenger = false;
                        let showUserPassengerButton = false;
                        let isReadonly = false;
                        let fullName = '';
                        let documentType = 'cpf';
                        let passengerID = '';
                        let originalDocumentType = '';

                        if (index === 0 && this.customer.userType === 'logged' && this.customer.profile.docType) {
                            const docType = this.customer.profile.docType;
                            fullName = this.customer.profile.fullName;
                            passengerID = this.customer.profile[docType];
                            documentType = docType.toLowerCase();
                            userPassenger = true;
                            showUserPassengerButton= true;
                            birthdate = this.customer.profile.birthdate
                        } else {
                            birthdate = ''
                        }

                        if (lineItem.isStudent) {
                            isStudent = true;
                        }

                        if (isStudent && lineItem.passengerName) {
                            // when the name passengerName is filled, the passengerData must be readonly (students)
                            isReadonly = true;
                            fullName = lineItem.passengerName;
                            documentType = lineItem.passengerDocType.toLowerCase();
                            passengerID = lineItem.passengerID;
                            userPassenger = false;
                            originalDocumentType = lineItem.passengerDocType.toLowerCase()
                        }

                        const passengerData = {
                            id,
                            isReadonly,
                            fullName,
                            documentType,
                            originalDocumentType,
                            passengerID,
                            route,
                            seat,
                            serviceNumber,
                            savePassenger,
                            departureTime,
                            userPassenger,
                            showUserPassengerButton,
                            isPCD: lineItem.isPCD,
                            isStudent: lineItem.isStudent,
                            errors: {},
                            birthdate,

                        };

                        if (trip.tripSection === 'IDA') {
                            departurePassengersData.push(passengerData);
                        } else {
                            returnPassengersData.push(passengerData);
                        }
                    });
                });
            });

            const result = { departurePassengersData, returnPassengersData };
            this.$store.commit('checkoutOnePage/setTripsPassengers', result);

            return result;
        },
        async validatePassengers() {
            if (!this.passengersFilled) {
                await this.$store.commit('checkoutOnePage/setShowPassengersErrors', true);

                return {
                    error: true
                };
            }

            const departurePassengersData = this.tripsPassengers.departurePassengersData;
            const returnPassengersData = this.tripsPassengers.returnPassengersData;
            const allPassengersData = [...departurePassengersData, ...returnPassengersData];
            const verifyPassengers = [];
            const formName = this.passengerForm.passengerData.htmlName;
            const formNameDoc = this.passengerForm.passengerData.document.htmlName;
            allPassengersData.forEach(passenger => {
                verifyPassengers.push({
                    uuid: passenger.id,
                    passengerName: passenger.fullName,
                    documentType: passenger.documentType,
                    passengerID: passenger.passengerID,
                    savePassenger: passenger.savePassenger,
                    formName: formName,
                    formNameDoc: formNameDoc,
                    departureTime: passenger.departureTime,
                    serviceNumber: passenger.serviceNumber,
                    seatNumber: passenger.seat,
                    userPassenger: passenger.userPassenger,
                    isPCD: passenger.isPCD,
                    isStudent: passenger.isStudent,
                    birthdate: passenger.birthdate,
                });
            });

            const verifyPassengersResult = await this.$store.dispatch('checkoutOnePage/verifyPassengers', { passengers: verifyPassengers });

            if (verifyPassengersResult.error) {
                $('html, body').animate({
                    scrollTop: $('.checkout-passengers').offset().top
                }, 1000);

                return {
                    error: true
                }
            }

            return {
                success: true
            }
        },
    },
    mounted() {
        this.getTripsPassengers();
        this.tripsPassengersData = this.tripsPassengers;
    },
    created() {
        this.$root.$refs.Passengers = this;
    },
};
</script>

<style>
 .checkout-passengers{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0.375rem;
 }
</style>
