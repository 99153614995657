import Vue from 'vue'
import Vuex from 'vuex'
import VMask from 'v-mask'

import trips from './modules/trips';
import checkout from './modules/checkout';
import checkoutOnePage from './modules/checkoutOnePage';
import account from './modules/account';
import tickets from './modules/tickets';

Vue.config.devtools = true;
Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VMask);

export default new Vuex.Store({
    modules: {
        trips,
        checkout,
        account,
        tickets,
        checkoutOnePage
    },
});
