function validateFullName(name) {
    let fullNamePattern = /^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+(?: [a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+)+$/;
    return fullNamePattern.test(name);
}

function checkLength(value, minLength) {
    return value.length >= minLength;
}
function isValidCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');

    if (cpf.length !== 11) {
        return false;
    }

    if (/^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    let sum = 0;
    let mod = 0;

    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }

    mod = 11 - (sum % 11);
    if (mod >= 10) {
        mod = 0;
    }

    if (mod !== parseInt(cpf.charAt(9))) {
        return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }

    mod = 11 - (sum % 11);
    if (mod >= 10) {
        mod = 0;
    }

    if (mod !== parseInt(cpf.charAt(10))) {
        return false;
    }

    return true;
}

function isValidRG(rg) {
    // Remover pontos e hífens
    const rgLimpo = rg.replace(/[.\-]/g, '');

    // Verificar se o RG tem entre 8 e 9 caracteres
    if (rgLimpo.length < 6 || rgLimpo.length > 11) {
        return false;
    }

    // Verificar se o RG começa com duas letras ou contém apenas números e pode terminar com uma letra
    const regexRG = /^([A-Za-z]{2})?\d+([A-Za-z])?$/;

    // Se o RG não seguir o formato, retorna inválido
    if (!regexRG.test(rgLimpo)) {
        return false;
    }

    // Se passar em todas as verificações, o RG é válido
    return true;
}

function validateDocument(documentType, passengerID) {
    if (documentType === 'passport') true

    if (documentType === 'rg') {
        return isValidRG(passengerID);
    }

    return documentType === 'cpf' ? isValidCPF(passengerID) : true;
}

function validateDuplicatedPassengers(tripsPassengersArray) {
    const keys = new Map();

    for (const passenger of tripsPassengersArray) {
        const passengerID = passenger.documentType === 'cpf' ? passenger.passengerID.replace(/\D/g, '') : passenger.passengerID;
        const key = `${passenger.serviceNumber}-${passengerID}`;

        if (keys.has(key)) {
            return false;
        }
        keys.set(key, true);
    }

    return true; // None duplicated passenger found in the same bus
}

function validateBirthdate(birthdate) {
    const today = new Date();
    if (!birthdate) {
        return false;
    }

    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(birthdate)) {
        return false;
    }

    const parts = birthdate.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const birthDate = new Date(year, month, day);
    if (birthDate.getFullYear() !== year || birthDate.getMonth() !== month || birthDate.getDate() !== day) {
        return false;
    }

    today.setHours(0, 0, 0, 0);
    birthDate.setHours(0, 0, 0, 0);
    if (birthDate > today) {
        return false;
    }

    if(birthDate.getFullYear() < 1900) {
        return false;
    }

    return true;
}


export default function validatePassengersData(tripsPassengersArray) {
    let error = false;

    const tripsPassengersArrayWithErrors = tripsPassengersArray.map(passenger => {
        const { fullName, passengerID, documentType, birthdate } = passenger;
        passenger.errors.name = '';
        passenger.errors.passengerID = '';
        passenger.errors.birthdate = ""

        if (!fullName || !validateFullName(fullName.trim())) {
            error = true,
            passenger.errors.name = 'Digite o nome completo do passageiro sem caracteres especiais'
        };

        if (!passengerID || !validateDocument(documentType, passengerID)) {
            error = true,
            passenger.errors.passengerID = 'Insira um documento válido'
        };

        if (tripsPassengersArray.length > 1 && !validateDuplicatedPassengers(tripsPassengersArray)) {
            error = true,
            passenger.errors.passengerID = 'Existem passageiros com documentos duplicados no mesmo ônibus'
        };

        if (!birthdate || !validateBirthdate(birthdate)) {
            error = true,
            passenger.errors.birthdate = 'Insira uma data de nascimento válida'
        }

        return passenger;
    });

    return {
        error,
        tripsPassengersArrayWithErrors
    }
}
