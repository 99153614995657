const amplitude = require('@amplitude/analytics-browser');
const amplitudeHelpers = require('./amplitudeHelpers');
/**
 * Tracks an "Account Created" event in Amplitude when a new account is created.
 *
 * @param {Object} eventProperties - The properties associated with the account creation event.
 * @param {string} eventProperties.tipo_documento - The type of document used for identification (e.g., rg, passport). (Required)
 */
function accountCreated(eventProperties, utmData = {}) {
    const eventObject = {
        ...eventProperties,
        ...utmData
    };

    const accountCreatedEvent = {
        event_type: 'Conta Criada',
        event_properties: eventObject
    };

    amplitude.track(accountCreatedEvent);
}

/**
 * Tracks a "Password Reset Sent" event in Amplitude when a password reset request is sent.
 */
function changePassword() {
    const utmData = amplitudeHelpers.getUtmData();
    const changePasswordEvent = {
        event_type: 'Redefinicao De Senha Enviada',
        event_properties: {
            ...utmData
        },
    };

    amplitude.track(changePasswordEvent);
}

function userGuest() {
    const userGuestEvent = {
        event_type: 'Usuário Guest'
    };

    amplitude.track(userGuestEvent);
}
module.exports = {
    accountCreated: accountCreated,
    changePassword: changePassword,
    userGuest: userGuest
};
