<template>
    <div class="container receipt orderPanel__main" ref="ticketDetails">
        <div class="row justify-content-center mb-3">
            <div class="col-sm-8 col-md-12" id="detailsRoot">
                <confirmation-details :isCancelled="isTicketCancelled"></confirmation-details>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-8 col-md-12">
                <NavigateButtons />
            </div>
        </div>

    </div>
</template>

<script>
import ConfirmationDetails from '../components/ticket/ConfirmationDetails.vue';
import * as $ from "jquery";
import NavigateButtons from '../components/ticket/NavigateButtons.vue';
import { mapGetters } from 'vuex';

export default {
    props: ['data'],
    components: {
        ConfirmationDetails,
        NavigateButtons
    },
    data() {
        return {
            trips: {},
            order: {},
            resources: {},
            loadData: false,
        };
    },
    created() {
        this.$root.$refs.TicketConsult = this;
        this.populateDataInfo()
    },
    mounted(){
        $('.ticket-details-page.loading-skeleton').remove()
    },
    computed: {
        ...mapGetters('tickets', ['isTicketCancelled']),
    },
    methods: {
        populateDataInfo(){
            var dataParsed = JSON.parse(this.data);
            this.trips = dataParsed.trips;
            this.order = dataParsed.order;
            this.resources = dataParsed.resources;
            this.order.isCancelled =  this.order.isCancelled == false ? this.isTicketCancelled : this.order.isCancelled;
        },
    },
    watch: {
        isTicketCancelled(newValue) {
            this.order.isCancelled = newValue;
        }
    }
};
</script>
