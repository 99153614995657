<template>
    <div>
        <Modal
            modalId="SuccessCancelModal"
            size="md"
            :showCloseButton="false"
            :isStatic="true"
            :startOpen="true"
            v-if="cancelSuccess.show"
        >
            <template v-slot:title>
                {{ cancelSuccess.title }}
            </template>
            <template v-slot:body>
                {{ cancelSuccess.message }}
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between w-100">
                    <ButtonVue
                        label="Voltar para a Home"
                        className="solid logoutModal--btn"
                        data-dismiss="modal"
                        @click="redirectHome"
                        v-if="cancelSuccess.goHome"
                    />
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../ui/Modal.vue';
import routes from '../../api/routes';
import ButtonVue from '../ui/ButtonVue.vue';

import { mapGetters } from 'vuex'

export default {
    components: {
        Modal,
        ButtonVue
    },
    computed: {
        ...mapGetters('tickets', {
            cancelSuccess: 'cancelSuccess'
        }),
    },
    data() {
        return {
            showLoad: false,
            urlHome: routes.home.show,
        }
    },
    created() {
        this.$root.$refs.SuccessCancelModal = this;
    },
    methods: {
        redirectHome() {
            window.open(this.urlHome);
        }
    },
}
</script>
