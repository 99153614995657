import { mapGetters } from 'vuex'
import { validarFormatoCelular } from '../PersonalData/validationPersonalData';

export default {
    computed: {
        ...mapGetters('account', {
            isLogged: 'isLogged',
            customer: 'customer',
        }),
        ...mapGetters('checkoutOnePage', {
            resources: 'resources',
            isMobile: 'isMobile',
        }),
    },
    data() {
        return {
            customerData: {
                name: '',
                email: '',
                phone: '',
                documentNumber: '',
                documentType: 'cpf',
                customerNumber: '',
                device: '',
            },
            erros: {
                canBeSubmitted: false,
                name: '',
                email: '',
                phone: '',
                documentNumber: '',
            },
        }
    },
    methods: {
        isValidCPF(cpf) {
            cpf = cpf.replace(/\D/g, '');

            if (cpf.length !== 11) {
                return false;
            }

            if (/^(\d)\1{10}$/.test(cpf)) {
                return false;
            }

            let sum = 0;
            let mod = 0;

            for (let i = 0; i < 9; i++) {
                sum += parseInt(cpf.charAt(i)) * (10 - i);
            }

            mod = 11 - (sum % 11);
            if (mod >= 10) {
                mod = 0;
            }

            if (mod !== parseInt(cpf.charAt(9))) {
                return false;
            }

            sum = 0;
            for (let i = 0; i < 10; i++) {
                sum += parseInt(cpf.charAt(i)) * (11 - i);
            }

            mod = 11 - (sum % 11);
            if (mod >= 10) {
                mod = 0;
            }

            if (mod !== parseInt(cpf.charAt(10))) {
                return false;
            }

            return true;
        },
        validateName() {
            let fullNamePattern = /^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+(?: [a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+)+$/;

            if (!fullNamePattern.test(this.customerData.name.trim())) {
                this.erros.name = this.resources.errors.name;
                return false;
            }
            this.erros.name = '';
            return true;
        },
        validateEmail() {
            if (!this.customerData.email) {
                this.erros.email = this.resources.errors.email;
                return false;
            }
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (!regex.test(this.customerData.email)) {
                this.erros.email = 'Por favor, insira um e-mail válido.'
                return false;
            }
            this.erros.email = '';
            return true;
        },
        validatePhone() {
            if (!this.customerData.phone) {
                this.erros.phone = this.resources.errors.phone;
                return false;
            }

            if (!validarFormatoCelular(this.customerData.phone)) {
                this.erros.phone = this.resources.errors.phoneInvalid;
                return false;
            }

            this.erros.phone = '';
            return true;
        },
        validateDocumentNumber() {
            if (!this.customerData.documentNumber) {
                this.erros.documentNumber = this.resources.errors.document;
                return false;
            }
            if (this.customerData.documentType === "cpf" && !this.isValidCPF(this.customerData.documentNumber)) {
                this.erros.documentNumber = this.resources.errors.invalidCpf;
                return false;
            }
            this.erros.documentNumber = '';
            return true;
        },
        validateCustomerData() {
            const isNameValid = this.validateName();
            const isEmailValid = this.validateEmail();
            const isPhoneValid = this.validatePhone();
            const isDocumentNumberValid = this.validateDocumentNumber();
            this.erros.canBeSubmitted = isNameValid && isEmailValid && isPhoneValid && isDocumentNumberValid;
        },
        showPersonalForm() {
            return !this.isLogged
        },
        showIncompleteRegistration() {
            return !this.customer.hasPhoneNumber || !this.customer.hasCpf;
        },
    },
}
