<template>
    <div v-if="$root.$refs.TicketConsult.trips">
        <div v-if="$root.$refs.TicketConsult.trips.grouped && $root.$refs.TicketConsult.trips.grouped.length">
            <div v-for="trip in $root.$refs.TicketConsult.trips.grouped" :key="trip.id">
                <trip-details :trip="trip"></trip-details>
            </div>
        </div>
        <div v-else-if="$root.$refs.TicketConsult.trips.length">
            <div v-for="trip in $root.$refs.TicketConsult.trips" :key="trip.id">
                <trip-details :trip="trip"></trip-details>
            </div>
        </div>
    </div>
</template>

<script>
import TripDetails from './TripDetails.vue';

export default {
    components: {
        'trip-details': TripDetails,
    },
};
</script>
