<template lang="html">
    <div>
        <Accordion id="insurance" variant="primary" :open="true">
            <template v-slot:items>
                <AccordionItem id="insurance-tab" parent="insurance" :open="true">
                    <template v-slot:header>
                        <i class="icon-secure mr-3"></i>
                        <p class="mb-0 mob-heading--medium mob-color--primary w-75">
                            {{ resources.insurance.title }}
                        </p>
                    </template>
                    <template v-slot:body>
                        <InsuranceInfo />
                    </template>
                </AccordionItem>
            </template>
        </Accordion>
    </div>
</template>

<script>
import Accordion from "../../ui/Accordion.vue";
import AccordionItem from "../../ui/AccordionItem.vue";
import InsuranceInfo from "./insuranceInfo.vue";
import { mapGetters } from 'vuex';

export default {
    computed: {},
    components: {
        Accordion,
        AccordionItem,
        InsuranceInfo
    },
    computed: {
        ...mapGetters('checkoutOnePage', ['resources']),
    },
};
</script>
