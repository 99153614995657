<template>
    <div class="appCheckout" id="checkout-main" v-if="!loadData">
        <Header type="simple" :spa="true"></Header>
        <div class="container checkout-container">
            <div class="row">
                <div class="col-12">
                    <PixPaymentSelected v-if="paymentStarted && paymentMethod === 'pix'" />
                    <div v-else class="checkout-components">
                        <Identification />
                        <Passengers />
                        <Insurance />
                        <CouponSection />
                        <WholeOrderSummary />
                        <PaymentSection />
                    </div>
            </div>
        </div>
    </div>
    </div>
</template>


<script>
import Header from '../components/Header.vue';
import Identification from '../components/checkoutOnePage/Identification/Identification.vue'
import Passengers from '../components/checkoutOnePage/Passengers/Passengers.vue'
import PaymentSection from '../components/checkoutOnePage/Payment/PaymentSection.vue'
import PixPaymentSelected from '../components/checkoutOnePage/Payment/Pix/PixPaymentSelected.vue'
import SidebarCheckout from '../components/checkoutOnePage/SidebarCheckout.vue';
import Insurance from '../components/checkoutOnePage/Insurance/insurance.vue';
import CouponSection from '../components/checkoutOnePage/Coupon/CouponSection.vue';
import { mapGetters } from 'vuex';
import WholeOrderSummary from '../components/checkoutOnePage/WholeOrderSummary.vue';


export default {
    props: ['data'],
    components: {
        Header,
        Identification,
        Passengers,
        PaymentSection,
        CouponSection,
        Insurance,
        SidebarCheckout,
        PixPaymentSelected,
        WholeOrderSummary,
    },
    created() {
        this.$root.$refs.Checkout = this;
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            loadData: 'loadData',
            paymentStarted: 'paymentStarted',
            paymentMethod: 'paymentMethod',
        }),
    },
    mounted() {
        this.$store.dispatch('checkoutOnePage/getCheckoutData')
    },
    beforeCreate() {
        this.$store.commit('checkoutOnePage/setIsMobile', window.innerWidth < 992);
    },
};
</script>

<style scoped lang="scss">
.checkout-components {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    gap: 24px;
}

.loading-skeleton {
    width: 100%;

    .identification-skeleton {
        height: 490px;
    }

    .passengers-skeleton {
        height: 246px;
    }

    .insurance-skeleton {
        height: 290px;
    }

    .coupon-section-skeleton {
        height: 90px;
    }

    .whole-order-summary-skeleton {
        height: 460px;
    }

    .payment-section-skeleton {
        height: 281px;
    }
}

</style>
