window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./utils/webviewCheck'));
    processInclude(require('./utils/mobileCheck'));
    processInclude(require('./utils/deviceCheck'));
    processInclude(require('./utils/transformDateAndCalculateDays'));
    processInclude(require('./reservamos/reservamos'));
    processInclude(require('base/components/menu'));
    processInclude(require('./components/masks'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./tooltip'));
    processInclude(require('./ticket/consultTicket'));
    processInclude(require('./login'));
    processInclude(require('./account/account'));
    processInclude(require('./header'));
    processInclude(require('./checkout/checkoutLoading'));
    processInclude(require('./footer/footer'));
    processInclude(require('./amplitude/amplitude'));
    processInclude(require('./initVue'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('daterangepicker');
require('jquery-mask-plugin');
