<template lang="html">
    <div class="checkout-onePage-payment">
        <Accordion id="payment-wrap" variant="secondary">
            <template v-slot:items>
                <AccordionItem id="pix-tab" parent="payment-wrap">
                    <template v-slot:header>
                        <i class="icon-pix clear"></i>
                        <p class="mob-text--accordion mb-0">
                            {{ resources.payment.pixText }}
                        </p>
                    </template>
                    <template v-slot:body>
                        <PixPayment />
                    </template>
                </AccordionItem>
                <!-- disableCreditCard desabilita compra para cartão de credito conforme configurado no Company Setup -->
                <AccordionItem id="pix-card" parent="payment-wrap" v-if="!disableCreditCard">
                    <template v-slot:header>
                        <i class="icon-card clear"></i>
                        <p class="mob-text--accordion mb-0">
                            {{ resources.payment.creditText }}
                        </p>
                        <span class="mob-badge mr-4">{{
                            resources.payment.credit.badge
                        }}</span>
                    </template>
                    <template v-slot:body>
                        <CreditPayment />
                        <load
                            :title="loadMessage.title"
                            :subtitle="loadMessage.subtitle"
                            :subtitle2="loadMessage.subtitle2"
                            v-if="
                                loadPlaceOrder ||
                                loadSubmitPaymentPix ||
                                loadSubmitPayment ||
                                loadPooling
                            "
                        />
                        <payment-error-modal />
                    </template>
                </AccordionItem>
            </template>
        </Accordion>
    </div>
</template>

<script>
import Accordion from "../../ui/Accordion.vue";
import AccordionItem from "../../ui/AccordionItem.vue";
import CreditPayment from "./Credit/CreditPayment.vue";
import PixPayment from "./Pix/PixPayment.vue";
import Load from '../../ui/Load.vue';
import PaymentErrorModal from './PaymentErrorModal.vue';
import { mapGetters } from "vuex";

export default {
    computed: {},
    components: {
        Accordion,
        AccordionItem,
        PixPayment,
        CreditPayment,
        Load,
        PaymentErrorModal
    },
    data() {
        return {
            selectedMethod: "PIX",
            showLoad: false,
            completedEmail: false,
        };
    },
    created() {
        this.$root.$refs.Payment = this;
    },
    computed: {
        ...mapGetters("checkoutOnePage", {
            resources: "resources",
            loadSubmitPayment: "loadSubmitPayment",
            loadPlaceOrder: "loadPlaceOrder",
            loadPooling: "loadPooling",
            loadSubmitPaymentPix: "loadSubmitPaymentPix",
            disableCreditCard: 'disableCreditCard',
        }),
    },
    methods: {
        selectMethod(method) {
            this.selectedMethod = method;
        },
        handleLoadMessage(type) {
            const messages = {
                loadSubmitPayment: {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: this.resources.payment.loadingSubtitle2a,
                },
                loadSubmitPaymentPix: {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: this.resources.payment.loadingSubtitle2b,
                },
                loadPlaceOrder: {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: this.resources.payment.loadingSubtitle2c,
                },
                loadPooling: {
                    title: this.resources.payment.loadingTitle,
                    subtitle: this.resources.payment.loadingSubtitle,
                    subtitle2: this.resources.payment.loadingSubtitle2d,
                },
            };
            this.loadMessage = messages[type];
        },
    },
    watch: {
        loadSubmitPayment: function (newVal) {
            this.handleLoadMessage("loadSubmitPayment");
        },
        loadSubmitPaymentPix: function (newVal) {
            this.handleLoadMessage("loadSubmitPaymentPix");
        },
        loadPlaceOrder: function (newVal) {
            this.handleLoadMessage("loadPlaceOrder");
        },
        loadPooling: function (newVal) {
            this.handleLoadMessage("loadPooling");
        },
    },
};
</script>

<style scoped></style>
