<template>
    <div>
        <Modal
            modalId="errorModal"
            size="md"
            :showCloseButton="!error.timer"
            :isStatic="error.timer"
            :startOpen="true"
            :timerModal="error?.code == 'error.code.pooling' ? false : error.timer"
            v-if="error.show"
            :redirectTime="!9000"
            :redirectUrl="urlHome"
            @close="handleErrorModalClose"
        >
            <template v-slot:title>
                {{ error.title }}
            </template>
            <template v-slot:body>
                {{ error.message }}
            </template>
            <template v-slot:footer>               
                <ButtonVue
                    :label="resources.base.home"
                    className="solid"
                    data-dismiss="modal"
                    @click="redirectHome"
                    v-if="error.goHome || error?.code == 'error.code.pooling'"
                    class="sm"
                />                    

                <ButtonVue
                    v-else
                    :label="resources.base.tryAgain"
                    className="solid"
                    data-dismiss="modal"
                    @click="handleErrorModalClose"
                    class="sm"
                />                
            </template>
        </Modal>
        <Modal
            modalId="errorModalPix"
            size="md"
            :showCloseButton="false"
            :isStatic="true"
            :startOpen="true"
            v-if="triesError.show"
            @close="handleErrorModalClose"
        >
            <template v-slot:title>
                {{ triesError.title }}
            </template>
            <template v-slot:body>
                {{ triesError.message }}
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between w-100">
                    <ButtonVue
                        :label="resources.base.talkToCRC"
                        className="ghost logoutModal--btn"
                        @click="redirectCRC"
                        class="sm"
                    />
                    <ButtonVue
                        :label="resources.base.payWithPix"
                        className="solid logoutModal--btn"
                        data-dismiss="modal"
                        @click="redirectPix"
                        class="sm"
                    />
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import Modal from '../../ui/Modal.vue';
import routes from '../../../api/routes';
import ButtonVue from '../../ui/ButtonVue.vue';

import { mapGetters } from 'vuex'

export default {
    components: {
        Modal,
        ButtonVue
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            error: 'error',
            triesError: 'triesError',
            resources: 'resources',
        }),
    },
    data() {
        return {
            showLoad: false,
            urlHome: routes.home.show,
            urlCRC: routes.pages.crc,
            urlToTrip: routes.order.history
        }
    },
    created() {
        this.$root.$refs.PaymentErrorModal = this;
    },
    methods: {
        handleErrorModalClose() {            
            this.$store.commit('checkout/setError', { show: false });
            if (this.$root.$refs.Payment.selectedMethod === 'CREDIT') {
                this.$root.$refs.CreditPayment.errorSaveCreditCard()
            }
        },
        redirectCRC() {
            window.open(this.urlCRC, '_blank');
        },
        redirectHome() {
            window.location.href = this.urlHome;
        },
        handleRedirectToTrip() {
            window.location.href = this.urlToTrip;
        },
        async redirectPix(){
            this.$store.commit('checkoutOnePage/setPaymentStarted', true)
            this.$store.commit('checkoutOnePage/setPaymentMethod', 'pix')
            await this.$store.dispatch(
                'checkoutOnePage/pixSubmitPayment',
                { buyerData: JSON.stringify(this.$root.$refs.Identification.customerData) }
            );
            this.$root.$refs.Payment.selectMethod('PIX')
            $('#collapsePix').collapse('show')
        }
    },
}
</script>
