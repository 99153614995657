'use strict';

function relocateSearchPosition() {
    var windowWidthGreaterThan1024 = window.innerWidth >= 1024;
    var isTicketPage = $('body').hasClass('Search-Show') || $('body').hasClass('Ticket-Search');

    if (windowWidthGreaterThan1024 && isTicketPage) {
        if (!$('.js-navbar__ticketForm').find('#ticketForm').length) {
            $('#ticketForm').prependTo('.js-navbar__ticketForm');
            $('.searchTicketsLoading.js-searchTicketsLoading').prependTo('.js-navbar__ticketForm');
        }
    } else if (!$('.ticket__editForm').find('#ticketForm').length) {
        $('#ticketForm').prependTo('.ticket__editForm');
    }
}

module.exports = {
    toggleMenu: function () {
        $('.navbar-toggler').on('click', function (e) {
            e.preventDefault();
            $('.header__mobile').addClass('open');
        });

        $('.close-menu').on('click', function (e) {
            e.preventDefault();
            $('.header__mobile').removeClass('open');
        });
    },

    checkTicketFormPosition: function () {
        relocateSearchPosition();

        window.onresize = function () {
            relocateSearchPosition();
        };
    },
    onRead: function () {
        $(document).on('scroll', function () {
            if ($(document).scrollTop() > 100) {
                $('.headerLP').animate({ height: '100px' }, 1000);
            } else {
                $('.headerLP').animate({ height: '140px' }, 1000);
            }
        });
    }
};
