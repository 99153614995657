<template>
    <div class="pix-payment">
        <PixPaymentData
            :submitForm="submitForm"
        />
    </div>
</template>

<script>
import PixPaymentData from './PixPaymentData.vue'
import PixPaymentSelected from './PixPaymentSelected.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        PixPaymentData,
        PixPaymentSelected
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            paymentStarted: 'paymentStarted',
            resources: 'resources',
            tripsPassengers: 'tripsPassengers',
            totals: 'totals',
            reservamosData: 'reservamosData',
            activeInsurance: 'activeInsurance',
            device: 'device',
        }),
        ...mapGetters('account', {
            isLogged: 'isLogged',
            customer: 'customer',
            userTypeRegister: 'userTypeRegister',
            customerNumber: 'customerNumber',
        }),
    },
    methods: {
        async submitForm() {
            console.info('%c   Submit Payment Process started   ', 'background: #55368B; color: #fff');

            this.$root.$refs.Identification.validateCustomerData();

            let validatePassengers = await this.$root.$refs.Passengers.validatePassengers()

            if (validatePassengers.error) {
                const firstErrorElement = document.querySelector('.error');
                if (firstErrorElement) {
                    firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    firstErrorElement.focus();
                }
                console.error('%c   Verify Checkout Passengers   ', 'background: #c81515; color: #fff');

                return;
            }

            const personalFormIsVisible = this.$root.$refs.Identification.showPersonalForm();
            const validatePersonalForm = personalFormIsVisible && !this.$root.$refs.Identification.erros.canBeSubmitted

            const incompleteRegistrationIsVisible = this.$root.$refs.Identification.showIncompleteRegistration()
            const validateIncompleteRegistration = incompleteRegistrationIsVisible && !this.$root.$refs.Identification.erros.canBeSubmitted;

            if (validatePersonalForm || validateIncompleteRegistration) {
                const firstErrorElement = document.querySelector('.error');
                if (firstErrorElement) {
                    firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    firstErrorElement.focus();
                }

                console.error('%c   Verify Checkout Forms   ', 'background: #c81515; color: #fff');
                return;
            }

            this.$store.commit('checkoutOnePage/setPaymentStarted', true)
            this.$store.commit('checkoutOnePage/setPaymentMethod', 'pix')

            if (this.isLogged) {
                $('body').trigger('clevertap:checkout', ['PIX', this.customer, this.tripsPassengers, this.totals]);
            }

            this.reservamosData['Payment Type'] = 'pix'

            this.reservamosData['Insurance'] = this.activeInsurance;
            this.reservamosData['User Status'] = this.customer.userType;

            let reservamosMeta = { };

            if (this.totals.discounts[0]) {
                this.reservamosData['Coupon'] = this.totals.discounts[0].name;
                reservamosMeta['Coupon value'] = this.totals.discounts[0].value;
            }

            if (this.activeInsurance) {
                reservamosMeta['Insurance value'] = this.totals.insurance.value;
            }
            var reservamosDataAndMeta = this.reservamosData
            reservamosDataAndMeta.meta = reservamosMeta
            $(window).trigger('reservamos:paymentAttempt', reservamosDataAndMeta);

            var buyerData = this.$root.$refs.Identification.customerData;
            buyerData.customerNumber = this.customerNumber;
            buyerData.device = this.device;

            await this.$store.dispatch(
                'checkoutOnePage/pixSubmitPayment',
                { buyerData: JSON.stringify(buyerData) }
            );
        }
    },
}
</script>

<style scoped></style>
