'use strict';

var $input = $('.form-control');
var $document = $(document);

function placingLabel(event) {
    var $label = $(this).parent().find('.form-control-label');
    var INPUT_EVENTS = ['focus', 'input'];

    if (INPUT_EVENTS.indexOf(event.type) !== -1) {
        $label.addClass('active');
    } else if (event.type === 'blur') {
        if ($(this).val() === '') {
            $label.removeClass('active');
        }
    }
}

function setLabelPosition() {
    // Placing labels when the page starts
    $input.trigger('focus input');
    $input.trigger('blur');
}

function identifyTargets() {
    $input.attr('data-js-placinglabels', 'included-dinamically');
}

function loadEvents() {
    $input.on('focus input', placingLabel).on('blur', placingLabel);
    $document.on('change', function (event) {
        if (event.target.nodeName === 'SELECT') {
            identifyTargets();
            setLabelPosition();
        }
    });
}

module.exports = {
    start: function () {
        identifyTargets();
        loadEvents();
        setLabelPosition();
    }
};
