<template>
    <div class="payment-content-pix">
        <personal-data-form @submit="submitForm" v-if="$root.$refs.Payment.showPersonalForm()" />
        <incomplete-registration @submit="submitForm" v-else-if="$root.$refs.Payment.showIncompleteRegistration()" />
        <div class="additional-info">
            <img :src="`${staticPath}/images/pix-image.png`" alt="pix-image" class="img-pix" />
            <p>{{ resources.payment.pix.paymentDataParagraph1 }}</p>
            <p>{{ resources.payment.pix.paymentDataParagraph2 }}</p>
            <SubmitTerms @trigger-submit="submitForm" />
        </div>
    </div>
</template>

<script>
import PersonalDataForm from '../PersonalDataForm.vue';
import IncompleteRegistration from '../IncompleteRegistration.vue';
import SubmitTerms from '../SubmitTerms.vue';
import routes from '../../../../api/routes';
import { mapGetters } from 'vuex';

export default {
    components: {
        PersonalDataForm,
        SubmitTerms,
        IncompleteRegistration
    },
    props: ['submitForm'],
    computed: {
        ...mapGetters('account', {
            userTypeRegister: 'userTypeRegister',
        }),
        ...mapGetters('checkout', {
            resources: 'resources',
        }),
    },
    data() {
        return {
            staticPath: routes.urlStatic,
        };
    },
    methods: {},
};
</script>

<style scoped lang="scss">
@media (max-width: 992px) {
    .additional-info {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
}
</style>
