<template>
    <div>
        <h1 class="mob-heading--largest" v-if="!isMobile">
            {{ resources.identification.buyerData }}
        </h1>
        <h2 class="SEO-only">{{ resources.steppers.identify }}</h2>
        <div class="step-wrapper checkout-guest-user">
            <p class="mob-text--large">
                {{ text }}
            </p>
            <div class="alert alert-danger" role="alert" v-if="loginErr">
                {{ loginErr }}
            </div>
            <div class="alert alert-danger" role="alert" v-if="emailError">
                {{ emailError }}
            </div>
            <form class="buyer-data-form" @submit.prevent="submitIdentifyAccountForm">
                <InputVue
                    id="checkoutGuestEmail"
                    type="email"
                    placeholder="E-mail"
                    :attributes="[{
                        name: 'orange-id',
                        value: 'checkoutGuestInput'
                        },
                        {
                            name: 'autocomplete',
                            value: 'email'
                        }]"
                    v-model="guestEmail"
                    :readonly="readonlyButton"
                />
                <InputVue
                    v-if="userType === 'identified'"
                    id="passwordCheckoutLogin"
                    type="password"
                    placeholder=""
                    :attributes="[{ name: 'orange-id', value: 'passwordCheckoutLoginInput' }]"
                    v-model="password"
                    :isPassword="true"
                />
            </form>

            <div v-if="userType === 'identified'" class="checkout-identify-option">
                <ButtonVue
                    className="ghost forgetPassword"
                    type="password"
                    :label="resources.base.forgotPassword"
                    data-target="#forgetPasswordModal"
                    :attributes="[{ name: 'orange-id', value: 'checkoutForgetPassword' }]"
                    @click="showForgetPasswordModal"
                />

                <ForgetPasswordModal ref="forgetPasswordModal" :email="guestEmail" />

                <ButtonVue
                    :label="resources.base.login"
                    @click="submitLoginForm"
                    :isLoad="isLoading"
                    :attributes="[{ name: 'orange-id', value: 'checkoutLoginButton' }]"
                />
            </div>

            <div v-if="userType === 'guest'" class="checkout-identify-option">
                <p>{{ resources.identification.notRegisterYet }}</p>
                <ButtonVue
                    class="ghost"
                    @click="openRegistrationModal"
                    :label="resources.base.signup"
                    :attributes="[{ name: 'orange-id', value: 'checkoutRegisterButton' }]"
                />
            </div>
        </div>
        <RegisterModal></RegisterModal>
    </div>
</template>

<script>

import InputVue from '../../ui/InputVue.vue'
import ButtonVue from '../../ui/ButtonVue.vue'
import ForgetPasswordModal from './ForgetPasswordModal.vue';
import RegisterModal from '../../Account/RegisterModal.vue';
import { mapState } from 'vuex'

export default {
    components: {
        InputVue,
        ButtonVue,
        ForgetPasswordModal,
        RegisterModal,
    },
    props: {},
    data() {
        return {
            guestEmail: '',
            password: '',
            isLoading: false,
        };
    },
    computed: {
        ...mapState({
            emailError: (state) => state.account.emailError,
            isLogged: (state) => state.account.logged,
            userType: (state) => state.account.customer.userType,
            loginErr: (state) => state.account.loginErr,
            isMobile: (state) => state.checkout.isMobile,
            loadData: (state) => state.checkout.loadData,
            identifyStepCompleted: (state) => state.account.identifyStepCompleted,
            csrf: (state) => state.checkout.csrf,
            resources: (state) => state.checkout.resources,
        }),
        text() {
            if (!this.identifyStepCompleted) {
                return this.resources.identification.identifyStepInProgress;
            }

            if (this.userType === 'guest') {
                return this.resources.identification.identifyStepGuest;
            }

            if (this.userType === 'identified') {
                return this.resources.identification.identifyStepRegistered;
            }
        },
        readonlyButton() {
            return this.identifyStepCompleted || this.isLogged;
        },
    },
    methods: {
        openRegistrationModal() {
            $('#register-modal').modal('show');
        },
        async submitIdentifyAccountForm() {
            try {
                await this.$store.dispatch('account/identifyCustomerRegister', {
                    email: this.guestEmail,
                });
            } catch (error) {
                console.error('Error submitting form: ', error);
            }
        },
        async submitLoginForm() {
            try {
                this.isLoading = true;
                this.$store.commit('account/setLoginErr', false);

                await this.$store.dispatch('account/login', {
                    loginEmail: this.guestEmail,
                    loginPassword: this.password,
                    csrf_token: this.csrf.token,
                    isCheckout: true
                });

                if(!this.loginErr) {
                    this.$store.commit('account/setGuestFormSubmitted', true);
                    this.$store.dispatch('checkout/getCheckoutData').then(() => {
                        this.localLoadData = false;
                    });
                }

                this.isLoading = false;
            } catch (error) {
                console.error('Error submitting form: ', error);
                this.isLoading = false;
            }
        },
        showForgetPasswordModal() {
            $(`#forgetPasswordModal`).modal('show');
        },
    },
    watch: {
        guestEmail(newGuestEmail) {
            this.$store.commit('account/setEmail', newGuestEmail);
        },
    },
    created() {
        this.guestEmail = this.$store.state.account.email;
    },
    mounted() {
        const emailInput = document.getElementById('checkoutGuestEmail');
        if (emailInput) {
            emailInput.click();
        }
        if (emailInput && emailInput.value) {
            this.guestEmail = emailInput.value;
            this.$store.commit('account/setEmail', this.guestEmail);
        }
        setInterval(() => {
            if (emailInput && emailInput.value && this.guestEmail !== emailInput.value) {
                this.guestEmail = emailInput.value;
                this.$store.commit('account/setEmail', this.guestEmail);
            }
        }, 1000);
    },
};
</script>

<style>
.SEO-only {
    display: none;
}
</style>
