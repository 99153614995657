<template lang="html">
    <div>
        <Modal
            modalId="confirmRemoveModal"
            size="md"
            :showCloseButton="true"
            :isStatic="true"
            :startOpen="false"
        >
            <template v-slot:title> {{ resources.payment.removeCoupon || '' }} </template>
            <template v-slot:body>
                {{ resources.payment.removeCouponMessage }}
            </template>
            <template v-slot:footer>
                <button-vue
                    :label="resources.base.no"
                    className="outlined"
                    data-dismiss="modal"
                />
                <button-vue
                    :label="resources.base.yes"
                    className="solid ml-3"
                    @click="removeCoupon"
                    :isLoad="load"
                />
            </template>
        </Modal>
        <div v-if="load" class="loading-overlay"></div>
    </div>
</template>

<script>
    import ButtonVue from '../../ui/ButtonVue.vue';
    import Modal from '../../ui/Modal.vue';

    import { mapGetters, mapState } from 'vuex';

    export default {
        data() {
            return {
                load: false
            };
        },
        components: {
            Modal,
            'button-vue' : ButtonVue
        },
        computed: {
            ...mapGetters('checkoutOnePage', ['resources', 'couponToRemove']),
            ...mapState('checkoutOnePage', {
                couponToRemove: state => state.couponToRemove,
            }),
        },
        methods: {
            async removeCoupon() {
                this.load = true;
                await this.$store.dispatch(
                    'checkoutOnePage/removeCouponCode',
                    this.couponToRemove
                )
                this.load = false;
                $("#confirmRemoveModal").modal('hide')
            },
        },
    }
</script>

<style scoped>

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    pointer-events: all;
}
</style>