<template>
    <card-wrapper class="p-0">
        <div v-html="insuranceInfo"></div>
    </card-wrapper>
</template>

<script>
import CardWrapper from './CardWrapper';
import { mapGetters } from 'vuex';

export default {
    components: {
        'card-wrapper': CardWrapper,
    },
    computed: {
        ...mapGetters('checkout', {
            insuranceInfo: 'insuranceInfo',
        }),
    }
}
</script>

<style scoped lang="scss">
p {
    margin-bottom: 0;
}
</style>
