<template lang="html">
        <div>
            <modal modalId="cancelOrderModal" size="lg" @close="showModal=false">
            <template v-slot:title class="center">
                <h2 class="modal-title" id="">Cancelar todas as poltronas desse pedido?</h2>
            </template>
            <template v-slot:body>
                <p class="title mb-3">Você tem certeza que deseja cancelar todas as poltronas desse pedido?</p>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between w-100">
                    <button-vue
                        label="Cancelar Pedido"
                        className="outlined "
                        @click="cancelOrder"
                        :isLoad="load"
                    />
                    <button-vue
                        label="Voltar"
                        className="solid "
                        data-dismiss="modal"
                    />
                </div>
            </template>
        </modal>
        <error-cancel-modal></error-cancel-modal>
        <success-cancel-modal></success-cancel-modal>
        </div>
</template>

<script>
import Modal from '../ui/Modal.vue';
import routes from '../../api/routes';
import ButtonVue from '../ui/ButtonVue.vue';

import { mapGetters } from 'vuex';
import ErrorCancelModal from './ErrorCancelModal.vue';
import SuccessCancelModal from './SuccessCancelModal.vue';

    export default {
        components: {
            'modal': Modal,
            'button-vue': ButtonVue,
            'error-cancel-modal': ErrorCancelModal,
            'success-cancel-modal': SuccessCancelModal,
        },
        data() {
            return {
                staticPath: routes.urlStatic,
                load: false
            };
        },
        computed: {
            ...mapGetters('account', ['email']),
        },
        methods: {
            async cancelOrder(){
                this.load = true;
                const params = this.getUrlParams(window.location.href)
                const data = {
                    email: params.email,
                    orderNumber: params.orderNumber || params.ID || this.$root.$refs.TicketConsult.order.orderNumber
                }
                const result = await this.$store.dispatch('tickets/cancelOrder', data);
                this.load = false;
            },
            getUrlParams(url) {
                const params = {};
                const urlParts = url.split('?');
                if (urlParts.length > 1) {
                    const queryString = urlParts[1];
                    const paramPairs = queryString.split('&');

                    paramPairs.forEach(pair => {
                        const [key, value] = pair.split('=');
                        params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
                    });
                }
                return params;
            }
        },
        watch: {}
    }
</script>