<template>
    <label :for="id">
        <input
            type="checkbox"
            :id="id"
            :checked="checked"
            :disabled="disabled"
            :class="className"
            :readonly="readonly"
            @change="$emit('input', $event.target.checked)"
            v-bind="extraAttributes"
        />
        <span :disabled="disabled" v-html="label"></span>
    </label>
</template>

<script>
export default {
    name: 'CheckboxVue',
    props: {
        id: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        className: {
            type: String,
        },
        label: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        attributes: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        extraAttributes() {
            let extraAttributes = {}

            if (this.attributes) {
                this.attributes.forEach(({ name, value }) => {
                    extraAttributes[name] = value
                })
            }

            return extraAttributes
        },
    }
}
</script>

<style scoped lang="scss">
@import '../../../../scss/core/colors.scss';

label {
    display: flex;
    align-items: center;

    span {
        width: 90%;
        color: #000;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

input[type='checkbox'] {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    appearance: none;
    cursor: pointer;
    margin-right: 8px;
    border: 1px solid $mediumGray;

    &:checked {
        background-color: $mob-secondary;
        color: $mob-white;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0.05rem;
        left: 0.25rem;
        width: 0.4rem;
        height: 0.6rem;
        border: 3px solid transparent;
        border-left: none;
        border-top: none;
        transform: rotate(45deg) scale(1);
    }

    &:checked:before {
        border-color: $mob-white;
    }
}

input[disabled] {
    background-color: $lightGray;
    pointer-events: none;
    touch-action: none;
    border: 1px solid $mob-gray-3;
}

span[disabled] {
    color: $silverChalice;
}


</style>
