
<template>
    <div :id="id" class="accordion" :class="[variant, { active: open }]" >
        <slot name="items"></slot>
    </div>
</template>
<script>

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            validator: (value) => ['primary', 'secondary'].includes(value),
            default: 'primary',
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
            $(document).on('show.bs.collapse', '.collapse', function() {
                $(this).closest('.accordion').addClass('active');
                $(this).closest('.accordion-items').addClass('active');
                $(this).closest('.accordion-items').find('.accordion-arrow').addClass("open");
            });

            $(document).on('hide.bs.collapse', '.collapse', function() {
                $(this).closest('.accordion').removeClass('active');
                $(this).closest('.accordion-items').removeClass('active');
                $(this).closest('.accordion-items').find('.accordion-arrow').removeClass("open");
            });
    },
    methods: {}
}
</script>
