<template>
    <section class="checkout-identification">
        <input type="hidden" :value="checkoutBegin" class="checkoutBeginData">
        <LoggedUser v-if="isLogged" />
        <GuestUser
            v-if="!isLogged"
            :isLogged="isLogged"
            :isGuest="isGuest"
        />
    </section>
</template>

<script>
import GuestUser from './GuestUser.vue'
import LoggedUser from './LoggedUser.vue'
import { mapState } from 'vuex'

export default {
    components: {
        GuestUser,
        LoggedUser,
    },
    props: {},
    computed: {
        ...mapState({
            paymentStarted: (state) => state.checkout.paymentStarted,
            resources: (state) => state.checkout.resources,
            savedPassengers: (state) => state.checkout.savedPassengers,
            expirationYears: (state) => state.checkout.cardExpirationYears,
            checkoutTimeout: (state) => state.checkout.checkoutTimeout,
            totals: (state) => state.checkout.checkoutTotals,
            trips: (state) => state.checkout.trips,
            totalSeats: (state) => state.checkout.totalSeats,
            resources: (state) => state.checkout.resources,
            forms: (state) => state.checkout.forms,
            csrf: (state) => state.checkout.csrf,
            checkoutBegin: (state) => state.checkout.checkoutBegin,
            //account
            isLogged: (state) => state.account.isLogged,
            customer: (state) => state.account.customer,
        }),
        isGuest() {
            return this.customer.userType === 'guest'
        }
    },
}
</script>
