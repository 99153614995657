<template>
    <div class="card insuranceWrapper" v-if="!paymentStarted">
        <div class="card-header text-center backgroundInsurance insuranceTag">
            <h3 class="mob-text--large mob-color--white mb-0">
                {{ resources.payment.insurance.title }}
            </h3>
        </div>
        <div class="card-body mob-text--small bg-white">
            <b> {{ resources.payment.insurance.subtitle1 }} </b>
            {{ resources.payment.insurance.subtitle2 }}
            <span id="viewInsurance" class="insuranceLink" @click="openModal">
                {{ resources.payment.insurance.modalTitle }}
            </span>
        </div>
        <div class="card-footer d-flex bg-white insuranceCheckbox">
            <checkbox-vue
                id="insurance--checkbox"
                label=""
                :checked="!!activeInsurance"
                v-model="activeInsurance"
                @change="toggleInsurance"
            />
            <p class="mob-text--small-bold mb-0 d-flex">
                {{ resources.payment.insurance.priceParagraph }}
                &nbsp;
                <span> {{ insurance }}</span>
            </p>
        </div>
        <modal-component
            modalId="insuranceModal"
            size="md"
            :showCloseButton="true"
            :isStatic="true"
            :startOpen="false"
        >
            <template v-slot:title>
                <p class="mob-heading--medium">{{ resources.payment.insurance.modalTitle }}</p>
            </template>
            <template v-slot:body>
                <insurance-info></insurance-info>
            </template>
            <template v-slot:footer>
                <p class="mob-text--smallest" v-html="insuranceFooter"></p>
            </template>
        </modal-component>
    </div>
</template>

<script>
import CardWrapper from './CardWrapper';
import CheckboxVue from '../ui/CheckboxVue';
import ModalComponent from '../ui/Modal.vue';
import ButtonVue from '../ui/ButtonVue';
import InsuranceInfo from './insuranceInfo';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        'card-wrapper': CardWrapper,
        'checkbox-vue': CheckboxVue,
        'modal-component': ModalComponent,
        'button-vue': ButtonVue,
        'insurance-info': InsuranceInfo,
    },
    computed: {
        ...mapGetters('checkout', {
            activeInsuranceValue: 'activeInsurance',
            totals: 'totals',
            paymentStarted: 'paymentStarted',
            resources: 'resources',
            insuranceFooter: 'insuranceFooter',
        }),
        activeInsurance: {
            get() {
                return !!this.activeInsuranceValue;
            },
            set(value) {
                this.updateActiveInsurance(!!value);
            },
        },
        insurance() {
            return this.totals.insurance ? this.totals.insurance.formatted : 'R$ 0,00';
        },
    },
    methods: {
        ...mapActions('checkout', ['updateActiveInsurance']),
        toggleInsurance() {
            this.activeInsurance = !this.activeInsurance;
        },
        openModal() {
            $('#insuranceModal').modal('show');
        },
    },
};
</script>

<style scoped>
.card {
    border: 1px solid transparent;
    border-radius: 10px;
}
.insuranceLink {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
}
</style>
