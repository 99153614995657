'use strict';

var Cleave = require('cleave.js').default;

module.exports = {
    handleCreditCardNumber: function (cardFieldSelector, cardTypeSelector) {
        var cleave = new Cleave(cardFieldSelector, {
            creditCard: true,
            onCreditCardTypeChanged: function (typeParam) {
                var creditCardTypes = {
                    visa: 'Visa',
                    mastercard: 'Master Card',
                    amex: 'Amex',
                    discover: 'Discover',
                    elo: 'Elo',
                    hipercard: 'Hipercard',
                    unknown: 'Unknown'
                };

                var masterMaestroRegex = /^(50|5[6-9]|6[0-9])/;
                var isMaestro = masterMaestroRegex.test($('.card-number-wrapper').find('input').val());
                var type = typeParam;
                var cardType;

                if (isMaestro) {
                    cardType = 'Master Card';
                    type = 'mastercard';
                }

                if (!cardType) {
                    cardType = creditCardTypes[Object.keys(creditCardTypes).indexOf(type) > -1
                        ? type
                        : 'unknown'];
                }

                $(cardTypeSelector).val(cardType);
                $('.card-number-wrapper').attr('data-type', type);
                if (type === 'visa' || type === 'mastercard' || type === 'discover') {
                    $('#securityCode').attr('maxlength', 3);
                } else {
                    $('#securityCode').attr('maxlength', 4);
                }
            }
        });

        $(cardFieldSelector).data('cleave', cleave);
    },

    handleRG: function (rgFieldSelector) {
        var cleave = new Cleave(rgFieldSelector, {
            numericOnly: true,
            delimiters: ['.', '.', '-'],
            blocks: [2, 3, 3, 1],
            uppercase: true
        });

        $(rgFieldSelector).data('cleave', cleave);
        return cleave;
    },

    handleCPF: function (cpfFieldSelector) {
        var cleave = new Cleave(cpfFieldSelector, {
            numericOnly: true,
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            uppercase: true
        });

        $(cpfFieldSelector).data('cleave', cleave);
        return cleave;
    },

    handlePhone: function (phoneFieldSelector) {
        var cleave = new Cleave(phoneFieldSelector, {
            numericOnly: true,
            delimiters: ['(', ')', '-'],
            blocks: [0, 2, 5, 4],
            uppercase: true
        });

        $(phoneFieldSelector).data('cleave', cleave);
        return cleave;
    },

    handleCEP: function (cepFieldSelector) {
        var cleave = new Cleave(cepFieldSelector, {
            delimiters: ['-'],
            blocks: [5, 3],
            uppercase: true
        });

        return cleave;
    },

    handleBirthday: function (birthdaySelector) {
        var cleave = new Cleave(birthdaySelector, {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y']
        });

        $(birthdaySelector).data('cleave', cleave);
        return cleave;
    },
    handlePassport: function (passportSelector) {
        var cleave = new Cleave(passportSelector, {
            uppercase: true
        });

        $(passportSelector).data('cleave', cleave);
        return cleave;
    },
    serializeData: function (form) {
        var serializedArray = form.serializeArray();

        serializedArray.forEach(function (item) {
            if (item.name.indexOf('cardNumber') > -1) {
                item.value = $('#cardNumber').data('cleave').getRawValue(); // eslint-disable-line
            }
        });

        return $.param(serializedArray);
    }
};
