<template>
    <Modal
            modalId="insuranceModal"
            size="lg"
            :showCloseButton="true"
            :isStatic="false"
            :startOpen="false"
        >
            <template v-slot:title>
                <p class="mob-heading--medium">{{ resources.payment.insurance.modalTitle }}</p>
            </template>
            <template v-slot:body>
                <div v-html="insuranceInfo"></div>
            </template>
            <template v-slot:footer>
                <p class="mob-text--smallest" v-html="insuranceFooter"></p>
            </template>
        </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../ui/Modal.vue';

export default {
    components: {
        Modal
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            resources: 'resources',
            insuranceFooter: 'insuranceFooter',
            insuranceInfo: 'insuranceInfo',
        }),
    }
}
</script>

<style scoped lang="scss">
p {
    margin-bottom: 0;
}
</style>
