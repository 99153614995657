<template>
    <div class="card-wrapper">
        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('checkoutOnePage', {
            loadData: 'loadData',
        }),
    },
}
</script>

<style scoped lang="scss">
.card-wrapper {
    border: 1px solid #ffffff;
    border-radius: 10px;
    padding: 24px;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
}

$vue-below-desk: 992px;

@media (max-width: $vue-below-desk) {
    .card-wrapper {
        animation: slide-up 0.8s ease-in-out;
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
