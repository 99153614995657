<template lang="html" :v-if="type === 'counter'">
    <header class="header">
        <CountdownSPA v-if="spa" ></CountdownSPA>
        <Countdown v-else></Countdown>
        <img :src="`${staticPath}/images/logo-mobifacil-mobile.png`" alt="Mobifácil" title="Mobifácil" width="130" height="32" @click="goHome"/>
    </header>
</template>

<script>
    import Countdown from './checkout/Countdown.vue';
    import CountdownSPA from './checkoutOnePage/Countdown.vue';
    import routes from '../api/routes';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            Countdown,
            CountdownSPA,
        },
        props: {
            type: {
                type: String,
            },
            logo: {
                type: String,
            },
            alt: {
                type: String,
            },
            spa: {
                type: Boolean
            }
        },
        data() {
            return {
                staticPath: routes.urlStatic,
            };
        },
        methods: {
            goHome() {
                if (this.isMobile){
                    window.location.href = routes.home.show;
                }
            }
        },
        computed: {
            ...mapGetters('checkoutOnePage', {
                isMobile: 'isMobile'
            }),
        }
    }
</script>
