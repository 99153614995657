<template>
    <div class="submit-terms">
        <p class="mob-text--smallest">
            {{ resources.payment.submitTerms }}
                <a class="mob-text--smallest checkout-link" :href="termosUso" target="_blank">
                    {{ resources.payment.termsOfUse }}
                </a>
                {{ resources.base.and }}
                <a class="mob-text--smallest checkout-link" :href="politicaPagamento" target="_blank">
                    {{ resources.payment.paymentPolicy }}
                </a>.
        </p>
        <ButtonVue
            :label="resources.base.buy"
            className="solid mt-3"
            type="submit"
            @click="$emit('trigger-submit')"
        />
    </div>
</template>

<script>
import ButtonVue from '../../ui/ButtonVue.vue'
import routes from '../../../api/routes'
import { mapGetters } from 'vuex'

export default {
    components: {
        ButtonVue,
    },
    computed: {
        ...mapGetters('checkout', {
            resources: 'resources',
        }),
    },
    data() {
        return {
            termosUso: routes.pages.termosUso,
            politicaPagamento: routes.pages.politicaPagamento,
            politicaCookies: routes.pages.politicaCookies,
        }
    },
}
</script>
