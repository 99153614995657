<template lang="html">
    <div class="col-lg-4 col-12 checkout-sidebar" :class="{ 'loading-skeleton': loadData }">
        <h2 class="mob-heading--small mb-3">{{ resources.sidebar.orderSummary }}</h2>
            <travel-data></travel-data>
            <insurance class="mt-3" v-if="!paymentStarted"></insurance>
            <order-summary class="mt-3"></order-summary>
    </div>
</template>

<script>
import CardWrapper from './CardWrapper';
import TravelData from './TravelData';
import OrderSummary from './OrderSummary';
import Insurance from './insurance';
import { mapGetters } from 'vuex';

export default {
    components: {
        'card-wrapper': CardWrapper,
        'travel-data': TravelData,
        'order-summary': OrderSummary,
        'insurance': Insurance,
    },
    computed: {
        ...mapGetters('checkout', {
            loadData: 'loadData',
            paymentStarted: 'paymentStarted',
            resources: 'resources',
        })
    }
}
</script>
