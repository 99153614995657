const amplitude = require('@amplitude/analytics-browser');
const amplitudeHelpers = require('./amplitudeHelpers');
var loggerGC = require('../components/loggerGC').sendLogToGoogleChat;

/**
 * Rastreia um evento "Checkout Iniciado" no Amplitude quando um usuário inicia o processo de checkout.
 *
 * @param {Object} eventProperties - As propriedades associadas ao evento de início do checkout.
 * @param {string[]} eventProperties.cidade_destino - As cidades de destino da viagem. (Obrigatório)
 * @param {string[]} eventProperties.cidade_origem - As cidades de origem da viagem. (Obrigatório)
 * @param {boolean} eventProperties.confirmacao_estudante - Indica se o usuário tem confirmação de estudante. (Obrigatório)
 * @param {boolean} [eventProperties.confirmacao_pcd] - Indica se o usuário tem confirmação de deficiência. (Opcional)
 * @param {string[]} eventProperties.data_partida - As datas de partida da viagem no formato "DD-MM-AAAA". (Obrigatório)
 * @param {string} eventProperties.tipo_documento - O tipo de documento usado para identificação (por exemplo, RG, CPF). (Obrigatório)
 * @param {string[]} eventProperties.uf_destino - Os estados de destino da viagem. (Obrigatório)
 * @param {string[]} eventProperties.uf_origem - Os estados de origem da viagem. (Obrigatório)
 * @param {number} [eventProperties.valor_total_compra] - O valor total da compra do processo de checkout. (Opcional)
 */
function checkoutIniciado(eventProperties) {
    const destino = [];
    const origem = [];
    const estudante = [];
    const pcd = [];
    const partida = [];
    const viagem = [];
    const ufDestino = [];
    const ufOrigem = [];
    const utmData = amplitudeHelpers.getUtmData();
    eventProperties.trips.forEach(tripArray => {
        tripArray.forEach(trip => {
            trip.lineItems.forEach(item => {
                const [cidadeDestino, ufDestinoSigla] = item.destiny.split(' - ');
                const [cidadeOrigem, ufOrigemSigla] = item.origin.split(' - ');

                destino.push(cidadeDestino);
                origem.push(cidadeOrigem);
                estudante.push(item.isStudent);
                pcd.push(item.isPCD);
                partida.push(trip.tripDate);
                documento = item.passengerDocType || eventProperties.docType || "";
                viagem.push(trip.tripSection);
                ufDestino.push(ufDestinoSigla);
                ufOrigem.push(ufOrigemSigla);
            });
        });
    });

    const valorTotalCompra = parseFloat(eventProperties.totalPrice.replace('R$', '').replace(',', '.').trim());

    const checkoutIniciadoEvent = {
        event_type: 'Checkout Iniciado',
        event_properties: {
            cidade_destino: destino,
            cidade_origem: origem,
            confirmacao_estudante: estudante,
            confirmacao_pcd: pcd,
            data_partida: partida,
            tipo_documento: documento,
            tipo_viagem: viagem,
            uf_destino: ufDestino,
            uf_origem: ufOrigem,
            valor_total_compra: valorTotalCompra,
            ...utmData
        }
    };

    try {
        amplitude.track(checkoutIniciadoEvent).promise.then(function(result) {
            if (result.code !== 200) {
                const isWebView = window.isWebView();
                const isMobile = window.mobileCheck();
                loggerGC({
                    message: `[SESSION ID: ${result.event.session_id}] | [USER ID: ${result.event.user_id || 'SEM-LOGIN'}]`,
                    title: result.event.event_type,
                    subtitle: `${result.code}`,
                    deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
                    errorMessage: `${result.message}`,
                    params: JSON.stringify(result)
                })
            }
        });
    } catch (error) {
        const isWebView = window.isWebView();
        const isMobile = window.mobileCheck();
        loggerGC({
            message: `Try/Catch`,
            title: 'Checkout Iniciado',
            subtitle: `Erro Capturado`,
            deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
            errorMessage: error.message,
            params: JSON.stringify(error),
            })
    }
}

/**
 * Tracks a "Pagamento Selecionado" event in Amplitude when a user selects a payment method.
 *
 * @param {Object} eventProperties - The properties associated with the payment selection event.
 * @param {boolean} eventProperties.confirmacao_usuario_passageiro - Indicates whether the user confirms the passenger information. (Required)
 * @param {string} eventProperties.metodo_de_pagamento - The selected payment method. (Required)
 * @param {boolean} eventProperties.seguro_viagem_adicionado - Indicates whether travel insurance was added. (Required)
 * @param {number} [eventProperties.valor_cupom_desconto] - The value of the discount coupon applied. (Optional)
 * @param {number} eventProperties.valor_seguro_viagem - The value of the travel insurance. (Required)
 * @param {number} eventProperties.valor_total_compra - The total purchase value. (Required)
 * @param {number} [eventProperties.valor_total_desconto] - The total discount applied. (Optional)
 */
function pagamentoSelecionado(eventProperties) {
    let eventObject = eventProperties;
    var eventPropertiesKeys = ['confirmacao_usuario_passageiro', 'metodo_de_pagamento', 'seguro_viagem_adicionado', 'valor_cupom_desconto', 'valor_seguro_viagem', 'valor_total_compra', 'valor_total_desconto'];
    var eventPropertiesIsOk = amplitudeHelpers.checkProperties(eventObject, eventPropertiesKeys);
    if (eventPropertiesIsOk) {
        const cupomAplicadoEvent = {
            event_type: 'Metodo De Pagamento Selecionado',
            event_properties: eventObject
        };
        try{
            amplitude.track(cupomAplicadoEvent).promise.then(function(result) {
                if (result.code !== 200) {
                    const isWebView = window.isWebView();
                    const isMobile = window.mobileCheck();

                    loggerGC({
                        message: `[SESSION ID: ${result.event.session_id}] | [USER ID: ${result.event.user_id || 'SEM-LOGIN'}]`,
                        title: result.event.event_type,
                        subtitle: `${result.code}`,
                        deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
                        errorMessage: `${result.message}`,
                        params: JSON.stringify(result)
                    })
                }
            });
        } catch (error) {
            const isWebView = window.isWebView();
            const isMobile = window.mobileCheck();
            loggerGC({
                message: `Try/Catch`,
                title: 'Checkout Iniciado',
                subtitle: `Erro Capturado`,
                deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
                errorMessage: error.message,
                params: JSON.stringify(error),
            })
        }
    }
}

/**
 * Tracks a "Cupom Aplicado" event in Amplitude when a coupon is applied during checkout.
 *
 * @param {Object} eventProperties - The properties associated with the coupon application event.
 * @param {string} eventProperties.cupom_codigo - The code of the applied coupon. (Required)
 * @param {number} eventProperties.valor_cupom_desconto - The value of the discount coupon applied. (Required)
 * @param {number} eventProperties.valor_total_compra - The total purchase value. (Required)
 * @param {number} eventProperties.valor_total_desconto - The total discount applied. (Required)
 */
function cupomAplicado(eventProperties) {
    let eventObject = eventProperties;
    const cupomAplicadoEvent = {
        event_type: 'Cupom Aplicado',
        event_properties: eventObject
    };
    try {
        amplitude.track(cupomAplicadoEvent).promise.then(function(result) {
            if (result.code !== 200) {
                const isWebView = window.isWebView();
                const isMobile = window.mobileCheck();

                loggerGC({
                    message: `[SESSION ID: ${result.event.session_id}] | [USER ID: ${result.event.user_id || 'SEM-LOGIN'}]`,
                    title: result.event.event_type,
                    subtitle: `${result.code}`,
                    deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
                    errorMessage: `${result.message}`,
                    params: JSON.stringify(result)
                })
            }
        });
    } catch (error) {
        const isWebView = window.isWebView();
        const isMobile = window.mobileCheck();
        loggerGC({
            message: `Try/Catch`,
            title: 'Checkout Iniciado',
            subtitle: `Erro Capturado`,
            deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
            errorMessage: error.message,
            params: JSON.stringify(error),
        })
    }

}

function qrCodeExpirado() {
    const cupomAplicadoEvent = {
        event_type: 'QR Code Pix Expirado'
    };
    try {
        amplitude.track(cupomAplicadoEvent).promise.then(function(result) {
            if (result.code !== 200) {
                const isWebView = window.isWebView();
                const isMobile = window.mobileCheck();

                loggerGC({
                    message: `[SESSION ID: ${result.event.session_id}] | [USER ID: ${result.event.user_id || 'SEM-LOGIN'}]`,
                    title: result.event.event_type,
                    subtitle: `${result.code}`,
                    deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
                    errorMessage: `${result.message}`,
                    params: JSON.stringify(result)
                })
            }
        });
    } catch (error) {
        const isWebView = window.isWebView();
        const isMobile = window.mobileCheck();
        loggerGC({
            message: `Try/Catch`,
            title: 'Checkout Iniciado',
            subtitle: `Erro Capturado`,
            deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
            errorMessage: error.message,
            params: JSON.stringify(error),
        })
    }

}

function seguroViagem(eventProperties) {
    const { checkoutData } = eventProperties;

    const { totals, validatedPassengers } = checkoutData;
    const passengers = validatedPassengers ? validatedPassengers.passengers || [] : [];

    const confirmUserPassenger = passengers.some(passenger => passenger.userPassenger === true);
    const utmData = amplitudeHelpers.getUtmData();
    const origem = [];
    const partida = [];
    const ufOrigem = [];
    let isStudent = false;
    let isPCD = false;

    checkoutData.trips.forEach(tripArray => {
        tripArray.forEach(trip => {
            trip.lineItems.forEach(item => {
                const [cidadeOrigem, ufOrigemSigla] = item.origin.split(' - ');
                origem.push(cidadeOrigem);
                partida.push(trip.tripDate);
                ufOrigem.push(ufOrigemSigla);
                if (item.isStudent) {
                    isStudent = true;
                }
                if (item.isPCD) {
                    isPCD = true;
                }
            });
        });
    });

    const seguroViagemEvent = {
        event_type: 'Seguro Viagem',
        event_properties: {
            cidade_origem: origem,
            confirmacao_estudante: isStudent,
            confirmacao_pcd: isPCD,
            confirmacao_usuario_passageiro: confirmUserPassenger,
            data_partida: partida,
            uf_origem: ufOrigem,
            valor_seguro_viagem: totals.insurance ? totals.insurance.value : 0,
            valor_total_compra: parseFloat(totals.totalPrice ? totals.totalPrice.value.toFixed(2) : 0),
            ...utmData
        }
    };
    try {
        amplitude.track(seguroViagemEvent).promise.then(function(result) {
            if (result.code !== 200) {
                const isWebView = window.isWebView();
                const isMobile = window.mobileCheck();

                loggerGC({
                    message: `[SESSION ID: ${result.event.session_id}] | [USER ID: ${result.event.user_id || 'SEM-LOGIN'}]`,
                    title: result.event.event_type,
                    subtitle: `${result.code}`,
                    deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
                    errorMessage: `${result.message}`,
                    params: JSON.stringify(result)
                })
            }
        });
    } catch (error) {
        const isWebView = window.isWebView();
        const isMobile = window.mobileCheck();
        loggerGC({
            message: `Try/Catch`,
            title: 'Checkout Iniciado',
            subtitle: `Erro Capturado`,
            deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
            errorMessage: error.message,
            params: JSON.stringify(error),
        })
    }
}

function submitPurchase(eventProperties) {
    const { metodo_de_pagamento, checkoutData } = eventProperties;

    const { totals, validatedPassengers, activeInsurance } = checkoutData;
    const discounts = totals.discounts || [];
    const totalDiscount = discounts.reduce((sum, discount) => sum + discount.value, 0);
    const confirmUserPassenger = validatedPassengers.passengers.some(passenger => passenger.userPassenger === true);
    const isUserPCD = validatedPassengers.passengers.some(passenger => passenger.isPCD === true);
    const isUserStudent = validatedPassengers.passengers.some(passenger => passenger.isStudent === true);
    const utmData = amplitudeHelpers.getUtmData();
    const submitedPurchaseEvent = {
        event_type: 'Compra Submetida',
        event_properties: {
            confirmacao_usuario_passageiro: confirmUserPassenger,
            confirmacao_estudante: isUserStudent,
            confirmacao_pcd: isUserPCD,
            cupom_codigo: discounts.length > 0 ? discounts[discounts.length - 1].name : '',
            valor_cupom_desconto: discounts.length > 0 ? discounts[discounts.length - 1].value : 0,
            metodo_de_pagamento: metodo_de_pagamento,
            seguro_viagem_adicionado: activeInsurance || false,
            valor_seguro_viagem: totals.insurance ? totals.insurance.value : 0,
            valor_total_compra: parseFloat(totals.totalPrice ? totals.totalPrice.value.toFixed(2) : 0),
            valor_total_desconto: totalDiscount,
            quantidade_de_passageiros: validatedPassengers.passengers.length,
            ...utmData
        }
    };

    try {
        amplitude.track(submitedPurchaseEvent).promise.then(function(result) {
            if (result.code !== 200) {
                const isWebView = window.isWebView();
                const isMobile = window.mobileCheck();

                loggerGC({
                    message: `[SESSION ID: ${result.event.session_id}] | [USER ID: ${result.event.user_id || 'SEM-LOGIN'}]`,
                    title: result.event.event_type,
                    subtitle: `${result.code}`,
                    deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
                    errorMessage: `${result.message}`,
                    params: JSON.stringify(result)
                })
            }
        });
    } catch (error) {
        const isWebView = window.isWebView();
        const isMobile = window.mobileCheck();
        loggerGC({
            message: `Try/Catch`,
            title: 'Checkout Iniciado',
            subtitle: `Erro Capturado`,
            deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
            errorMessage: error.message,
            params: JSON.stringify(error),
        })
    }
}

function passengerIdentification(eventProperties) {
    let eventObject = eventProperties;
    const passengerEvent = {
        event_type: 'Passageiro Identificado',
        event_properties: eventObject
    };
    try {
        amplitude.track(passengerEvent).promise.then(function(result) {
            if (result.code !== 200) {
                const isWebView = window.isWebView();
                const isMobile = window.mobileCheck();

                loggerGC({
                    message: `[SESSION ID: ${result.event.session_id}] | [USER ID: ${result.event.user_id || 'SEM-LOGIN'}]`,
                    title: result.event.event_type,
                    subtitle: `${result.code}`,
                    deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
                    errorMessage: `${result.message}`,
                    params: JSON.stringify(result)
                })
            }
        });
    } catch (error) {
        const isWebView = window.isWebView();
        const isMobile = window.mobileCheck();
        loggerGC({
            message: `Try/Catch`,
            title: 'Checkout Iniciado',
            subtitle: `Erro Capturado`,
            deviceType: amplitudeHelpers.getOrigin(isWebView, isMobile),
            errorMessage: error.message,
            params: JSON.stringify(error),
        })
    }
}
module.exports = {
    checkoutIniciado: checkoutIniciado,
    pagamentoSelecionado: pagamentoSelecionado,
    cupomAplicado: cupomAplicado,
    qrCodeExpirado: qrCodeExpirado,
    submitPurchase: submitPurchase,
    seguroViagem: seguroViagem,
    passengerIdentification: passengerIdentification
};
