var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isMobile)?_c('h1',{staticClass:"mob-heading--largest"},[_vm._v("\n        "+_vm._s(_vm.resources.identification.buyerData)+"\n    ")]):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"SEO-only"},[_vm._v(_vm._s(_vm.resources.steppers.identify))]),_vm._v(" "),_c('div',{staticClass:"step-wrapper checkout-guest-user"},[_c('p',{staticClass:"mob-text--large"},[_vm._v("\n            "+_vm._s(_vm.text)+"\n        ")]),_vm._v(" "),(_vm.loginErr)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v("\n            "+_vm._s(_vm.loginErr)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.emailError)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v("\n            "+_vm._s(_vm.emailError)+"\n        ")]):_vm._e(),_vm._v(" "),_c('form',{staticClass:"buyer-data-form",on:{"submit":function($event){$event.preventDefault();return _vm.submitIdentifyAccountForm.apply(null, arguments)}}},[_c('InputVue',{attrs:{"id":"checkoutGuestEmail","type":"email","placeholder":"E-mail","attributes":[{
                    name: 'orange-id',
                    value: 'checkoutGuestInput'
                    },
                    {
                        name: 'autocomplete',
                        value: 'email'
                    }],"readonly":_vm.readonlyButton},model:{value:(_vm.guestEmail),callback:function ($$v) {_vm.guestEmail=$$v},expression:"guestEmail"}}),_vm._v(" "),(_vm.userType === 'identified')?_c('InputVue',{attrs:{"id":"passwordCheckoutLogin","type":"password","placeholder":"","attributes":[{ name: 'orange-id', value: 'passwordCheckoutLoginInput' }],"isPassword":true},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e()],1),_vm._v(" "),(_vm.userType === 'identified')?_c('div',{staticClass:"checkout-identify-option"},[_c('ButtonVue',{attrs:{"className":"ghost forgetPassword","type":"password","label":_vm.resources.base.forgotPassword,"data-target":"#forgetPasswordModal","attributes":[{ name: 'orange-id', value: 'checkoutForgetPassword' }]},on:{"click":_vm.showForgetPasswordModal}}),_vm._v(" "),_c('ForgetPasswordModal',{ref:"forgetPasswordModal",attrs:{"email":_vm.guestEmail}}),_vm._v(" "),_c('ButtonVue',{attrs:{"label":_vm.resources.base.login,"isLoad":_vm.isLoading,"attributes":[{ name: 'orange-id', value: 'checkoutLoginButton' }]},on:{"click":_vm.submitLoginForm}})],1):_vm._e(),_vm._v(" "),(_vm.userType === 'guest')?_c('div',{staticClass:"checkout-identify-option"},[_c('p',[_vm._v(_vm._s(_vm.resources.identification.notRegisterYet))]),_vm._v(" "),_c('ButtonVue',{staticClass:"ghost",attrs:{"label":_vm.resources.base.signup,"attributes":[{ name: 'orange-id', value: 'checkoutRegisterButton' }]},on:{"click":_vm.openRegistrationModal}})],1):_vm._e()]),_vm._v(" "),_c('RegisterModal')],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }