var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal-data-form"},[_c('p',{staticClass:"mob-subtitle--medium mob-color--secondary-light-1 bd"},[_vm._v("\n        "+_vm._s(_vm.resources.forms.personalData)+"\n    ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.resources.forms.incompleteRegistration)+"\n    ")]),_vm._v(" "),(!_vm.customer.hasPhoneNumber)?_c('InputVue',{staticClass:"form-group col-12 p-0",attrs:{"id":"personal-form-cellphone","name":"personal-form-cellphone","useMask":"phone","placeholder":_vm.resources.forms.phoneWithDDD,"attributes":[
            { name: 'orange-id', value: 'personal-form-cellphone' },
            { name: 'minlength', value: 11 },
            { name: 'autocomplete', value: 'tel' },
        ],"errorMessage":_vm.$root.$refs.Payment.erros.phone},model:{value:(_vm.$root.$refs.Payment.customerData.phone),callback:function ($$v) {_vm.$set(_vm.$root.$refs.Payment.customerData, "phone", $$v)},expression:"$root.$refs.Payment.customerData.phone"}}):_vm._e(),_vm._v(" "),(!_vm.customer.hasCpf)?_c('div',{staticClass:"form-row"},[_c('SelectVue',{staticClass:"form-group col-md-6 disabled",attrs:{"id":"personal-form-documentType","name":"personal-form-documentType","attributes":[
                { name: 'orange-id', value: 'personal-form-documentType'},
            ],"options":_vm.documentTypeOptions,"errorMessage":_vm.$root.$refs.Payment.erros.documentType},model:{value:(_vm.$root.$refs.Payment.customerData.documentType),callback:function ($$v) {_vm.$set(_vm.$root.$refs.Payment.customerData, "documentType", $$v)},expression:"$root.$refs.Payment.customerData.documentType"}}),_vm._v(" "),_c('InputVue',{staticClass:"form-group col-md-6",attrs:{"id":"personal-form-documentNumber","name":"personal-form-documentNumber","placeholder":_vm.resources.forms.documentNumber,"attributes":[{ name: 'orange-id', value: 'personal-form-documentNumber' }],"useMask":_vm.$root.$refs.Payment.customerData.documentType.toLowerCase(),"errorMessage":_vm.$root.$refs.Payment.erros.documentNumber},model:{value:(_vm.$root.$refs.Payment.customerData.documentNumber),callback:function ($$v) {_vm.$set(_vm.$root.$refs.Payment.customerData, "documentNumber", $$v)},expression:"$root.$refs.Payment.customerData.documentNumber"}})],1):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }