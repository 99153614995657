/* eslint-disable no-useless-escape */
'use strict';

var ticketLoading = function () {
    $('body').on('click', '.check-ticket__cta a', function (e) {
        $('body').trigger('busLoading:init');
    });
};

var submitConsultTicketForm = function () {
    $('body').on('submit', '.consultTicket', function(e) {
        e.preventDefault();

        var emailValue = $(this).find('input[name="dwfrm_consultTicket_email"]').val().trim().toLocaleLowerCase();
        var orderNumberValue = $(this).find('input[name="dwfrm_consultTicket_orderNumber"]').val().trim().toLocaleUpperCase();

        var $form = $('.consultTicket')
        $form.find('.alert.alert-danger').remove()
        $(".wrongEmailError").removeClass("d-block");
        $(".wrongEmailError").addClass("d-none");
        $('.searchTicketsLoading__subTitle').addClass('d-none');
        $('body').trigger('checkoutLoading:init');
        $.ajax({
            url: $(this).attr('action'),
            type: 'POST',
            dataType: 'json',
            data: $(this).serialize(),
            success: function (data) {
                if (data.success) {
                    var continueUrl = data.continueUrl
                    const urlObj = new URL(continueUrl);
                    var params = new URLSearchParams(urlObj.search);
                    params.set('email', emailValue);
                    params.set('orderNumber', orderNumberValue);
                    urlObj.search = params.toString()

                    window.location.href = continueUrl
                } else {
                    $form.prepend('<div class="alert alert-danger" role="alert">'+ data.error.message + '</div>');
            $('body').trigger('checkoutLoading:stop');
                }
            },
            error: function() {
            $('body').trigger('checkoutLoading:stop');

                $form.prepend('<div class="alert alert-danger" role="alert">Ocorreu um erro ao enviar o formulário.</div>');
            }
        });
    });
};

module.exports = {
    ticketLoading: ticketLoading,
    submitConsultTicketForm: submitConsultTicketForm,
};
