<template v-if="localLoadData">
    <div class="appCheckout" id="checkout-main" :data-checkout-stage="activeStep===2 ? 'shipping' : activeStep===3 ? 'payment' : 'placeOrder'">
        <header-mob type="simple"></header-mob>
        <div class="container">
            <div class="row">
                <main-checkout></main-checkout>
                <sidebar-checkout v-if="!isMobile"></sidebar-checkout>
            </div>
        </div>
        <div v-if="isMobile" class="mobileEmptySpace" style="height: 200px;"></div>
        <sidebar-mobile v-if="showMobileSidebar"></sidebar-mobile>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '../components/Header';
import SidebarCheckout from '../components/checkout/SidebarCheckout';
import MainCheckout from '../components/checkout/MainCheckout';
import Modal from '../components/ui/Modal';

import SideBarMobile from '../components/checkout/SideBarMobile.vue';

export default {
    props: ['data'],
    components: {
        'header-mob': Header,
        'sidebar-checkout': SidebarCheckout,
        'main-checkout': MainCheckout,
        'modal': Modal,
        'sidebar-mobile': SideBarMobile
    },
    data() {
        return {
            activeStep: 1,
            localLoadData: true,
        };
    },
    created() {
        this.$root.$refs.Checkout = this;
    },
    computed: {
        ...mapGetters('checkout', {
            loadData: 'loadData',
            isMobile: 'isMobile'
        }),
        showMobileSidebar() {
            if ((this.isMobile && !this.loadData )) {
                return true;
            }
        },
    },
    methods: {
        nextStep() {
            this.activeStep += 1;
        },
        backStep() {
            this.activeStep -= 1;
        },
        goToStep(step) {
            this.activeStep = step;
        },
    },
    beforeCreate() {
        this.$store.commit('checkout/setIsMobile', window.innerWidth < 992);
    },
    mounted() {
        this.$store.dispatch('checkout/getCheckoutData')
            .then(() => {
                this.localLoadData = false;
            });
    },
    watch: {
        changeStep(step) {
            if (step) {
                this.activeStep = step;
            }
        },
        activeStep(step) {
            $(".continue-btn").removeClass("is__loading");
        }
    },
};
</script>
