<template>
    <div class="payment-content-pix">
        <div class="additional-info">
            <p>{{ resources.payment.pix.paymentDataParagraph1 }}</p>
            <p>{{ resources.payment.pix.paymentDataParagraph2 }}</p>
            <SubmitTerms @trigger-submit="submitForm" method="Pix" />
        </div>
    </div>
</template>

<script>
import SubmitTerms from '../SubmitTerms.vue';
import routes from '../../../../api/routes';
import { mapGetters } from 'vuex';

export default {
    components: {
        SubmitTerms
    },
    props: ['submitForm'],
    computed: {
        ...mapGetters('account', {
            userTypeRegister: 'userTypeRegister',
        }),
        ...mapGetters('checkoutOnePage', {
            resources: 'resources',
        }),
    },
    data() {
        return {
            staticPath: routes.urlStatic,
        };
    },
    methods: {},
};
</script>

<style scoped lang="scss">
@media (max-width: 992px) {
    .additional-info {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
}
</style>
