<template>
    <div class="payment-methods">
        <div
            class="method"
            :class="{
                selected: selectedMethod === 'PIX',
                disabled: paymentStarted
            }"
            @click="$emit('select-method', 'PIX')"
        >
            <span class="payment-pix">{{ resources.payment.pixText }}</span>
            <span class="arrow-right"></span>
        </div>
        <div
            class="method"
            :class="{
                selected: selectedMethod === 'CREDIT',
                disabled: paymentStarted
            }"
            @click="$emit('select-method', 'CREDIT')"
            v-if="!disableCreditCard"
        >
            <span class="payment-credit">{{ resources.payment.creditText }}</span>
            <span class="arrow-right"></span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['selectedMethod'],
    computed: {
        ...mapGetters('checkout', {
            paymentStarted: 'paymentStarted',
            resources: 'resources',
            disableCreditCard: 'disableCreditCard',
        }),
    },
}
</script>

<style scoped>
.arrow-right {
    color: #ccc;
}

.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
}
</style>
