<template>
    <section class="checkout-passengers">
        <div class="step-wrapper">
            <div class="passengers-section">
                <h3 class="mob-heading--small">{{ resources.passengers.departureTrip }}</h3>
                <PassengersList
                    v-for="(passenger, index) in tripsPassengersData.departurePassengersData"
                    :key="'d_' + index"
                    :passengerProp="passenger"
                    :index="index"
                    :isLogged="customer.userType === 'logged'"
                    tripType="departure"
                />
            </div>

            <div class="passengers-section" v-if="hasReturnTrip">
                <h3 class="mob-heading--small">{{ resources.passengers.returnTrip }}</h3>
                <PassengersList
                    v-for="(passenger, index) in tripsPassengersData.returnPassengersData"
                    :key="'r_' + index"
                    :passengerProp="passenger"
                    :index="index"
                    :isLogged="customer.userType === 'logged'"
                    tripType="return"
                />
            </div>
        </div>
        <Modal
            modalId="errorModalPassengers"
            size="md"
            :showCloseButton="true"
            :isStatic="true"
            :startOpen="true"
            v-if="passengersError.show"
        >
            <template v-slot:body>
                {{ passengersError.message }}
            </template>
        </Modal>
    </section>
</template>

<script>
import PassengersList from './PassengersList.vue';
import Modal from '../../ui/Modal.vue';
import { mapState, mapGetters } from 'vuex';
import validatePassengersData from './passengersValidation.js';

export default {
    components: {
        PassengersList,
        Modal,
    },
    props: {
        activeStep: {
            type: Number,
        },
        isLogged: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            trips: (state) => state.checkout.trips,
            tripsPassengers: (state) => state.checkout.tripsPassengers,
            resources: (state) => state.checkout.resources,
            passengersError: (state) => state.checkout.showModalPassengersErrors,
            reservamosData: (state) => state.checkout.reservamosData,
            // account
            customer: (state) => state.account.customer,
        }),
        ...mapGetters({
            hasReturnTrip: 'checkout/hasReturnTrip',
        }),
    },
    data() {
        return {
            passengerDataError: false,
            tripsPassengersData: {}
        };
    },
    watch: {
        tripsPassengers: {
            handler(tripsPassengers) {
                const departurePassengersValidated = validatePassengersData(tripsPassengers.departurePassengersData);
                this.passengerDataError = departurePassengersValidated.error;

                if (this.tripsPassengers.returnPassengersData.length) {
                    const returnPassengersValidated = validatePassengersData(tripsPassengers.returnPassengersData);
                    this.passengerDataError = this.passengerDataError || returnPassengersValidated.error;
                }

                if (this.passengerDataError) {
                    this.$store.commit('checkout/setPassengersFilled', false);
                    return;
                }

                this.passengerDataError = false;
                this.$store.commit('checkout/setPassengersFilled', true);
            },
            deep: true,
        },
    },
    methods: {
        getTripsPassengers() {
            const departurePassengersData = [];
            const returnPassengersData = [];
            const allPassengersData = [];

            this.trips.forEach((trips) => {
                trips.forEach((trip) => {
                    const lineItems = trip.lineItems;
                    const route = trip.departure.location + ' > ' + trip.arrival.location;
                    const serviceNumber = trip.serviceNumber;
                    const savePassenger = false;
                    const departureTime = trip.departure.hour;
                    let isStudent = false;
                    let birthdate = ''
                    lineItems.forEach((lineItem, index) => {
                        const id = trip.ids[index];
                        const seat = lineItem.seatNumber
                        let userPassenger = false;
                        let showUserPassengerButton = false;
                        let isReadonly = false;
                        let fullName = '';
                        let documentType = '';
                        let passengerID = '';
                        let originalDocumentType = '';

                        if (index === 0 && this.customer.userType === 'logged' && this.customer.profile.docType) {
                            let docType = this.customer.profile.docType;
                            passengerID = this.customer.profile[docType];
                            docType = docType === 'Passaporte' ? 'passport' : 'cpf';

                            fullName = this.customer.profile.fullName;
                            documentType = docType.toLowerCase();
                            originalDocumentType = docType.toLowerCase();
                            userPassenger = true;
                            showUserPassengerButton= true;
                            birthdate = this.customer.profile.birthdate
                        } else {
                            birthdate = ''
                        }

                        if (lineItem.isStudent) {
                            isStudent = true;
                        }

                        if (isStudent && lineItem.passengerName) {
                            // when the name passengerName is filled, the passengerData must be readonly (students)
                            isReadonly = true;
                            fullName = lineItem.passengerName;
                            documentType = lineItem.passengerDocType.toLowerCase();
                            passengerID = lineItem.passengerID;
                            userPassenger = false;
                            originalDocumentType = lineItem.passengerDocType.toLowerCase()
                        }

                        const passengerData = {
                            id,
                            isReadonly,
                            fullName,
                            documentType,
                            originalDocumentType,
                            passengerID,
                            route,
                            seat,
                            serviceNumber,
                            savePassenger,
                            departureTime,
                            userPassenger,
                            showUserPassengerButton,
                            isPCD: lineItem.isPCD,
                            isStudent: lineItem.isStudent,
                            errors: {},
                            birthdate,

                        };

                        const passengerDataReservamos ={
                            "Passenger Name": fullName,
                            "Passenger Document Type": documentType,
                            "Passenger Document Id": passengerID,
                            "Passenger Birthdate": birthdate,
                            "Passenger Seat": seat,
                        }

                        if (trip.tripSection === 'IDA') {
                            departurePassengersData.push(passengerData);
                            allPassengersData.push(passengerDataReservamos);
                        } else {
                            returnPassengersData.push(passengerData);
                            allPassengersData.push(passengerDataReservamos);
                        }
                    });
                });
            });

            const result = { departurePassengersData, returnPassengersData };
            this.$store.commit('checkout/setTripsPassengers', result);
            this.$store.commit('checkout/setReservamosPassengers', allPassengersData);

            return result;
        },
    },
    mounted() {
        this.getTripsPassengers();
        this.tripsPassengersData = this.tripsPassengers;
        this.$store.dispatch('checkout/saveAbandonedCart')
    },
    created() {
        this.$root.$refs.Passengers = this;
    },
};
</script>
