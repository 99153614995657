<template>
    <div class="travel__card">
        <div class="company_images_wrap">
            <div class="company_images">
                <div class="item-image company-logo orderDetailLogo">
                    <img :src="section.companyLogo.src" :alt="section.companyLogo.alt" :title="section.companyLogo.title" class="product-image logo" />
                </div>
            </div>
        </div>
        <!-- download -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="ticket-download"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="lineItem"
        >
        <section slot="pdf-content">
                <div class="container">
                    <div class="row p-3">
                        <img :src="section.companyLogo.src" :alt="section.companyLogo.alt" :title="section.companyLogo.title" class="col-3 my-2" />
                        <div class="card-body col-12">
                            <div class="ticketBody product-summary-block">
                                <ticket-card v-if="lineItemSelected" :section="section" :item="lineItemSelected"></ticket-card>
                                <p class="font-italic" style="margin: 50px 0;">Embarque: para esta viagem é necessário chegar com 30min de antecedência.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
        <slick :options="carouselOptions">
            <div v-for="lineItem in section.lineItems" style="display: flex; flex-direction: column;">
                <!-- bilhetes do trecho -->
                <div class="card-body">


                    <div class="product-summary-block">
                        <ticket-card :section="section" :item="lineItem"></ticket-card>
                    </div>
                </div>

                <!-- ctas do line item -->
                <div class="product-line-item-linksGroup" v-if="!isCancelled">
                    <p class="font-italic mb-4">Embarque: para esta viagem é necessário chegar com 30min de antecedência.</p>
                    <div class="linksGroup--content text-left">
                            <button-vue
                                class="justify-content-start downloadTicket link"
                                :class="`download-trigger-${lineItem.UUID}`"
                                :data-is-comporte-group="lineItem.isComporteGroup"
                                :isLoad="loadDownload"
                                label="Download"
                                @click="generatePDF(lineItem.UUID, lineItem.isComporteGroup, lineItem.bookingId)"
                            />
                        </div>
                        <div class="linksGroup--content text-right" v-if="!isCancelled">
                            <a
                                :href="lineItem.shareUrl"
                                class="shareTicket"
                                target="_blank"
                                rel="noreferrer"
                                :id="`shareTicket-${lineItem.UUID}`"
                            >
                                Compartilhar
                            </a>
                        </div>
                </div>
            </div>
        </slick>
    </div>
</template>

<script>
import TicketCard from './TicketCard.vue';
import Slick from 'vue-slick';
import VueHtml2pdf from "vue-html2pdf";
import { mapState } from 'vuex';
import ButtonVue from '../ui/ButtonVue.vue';

import routes from '../../api/routes';

export default {
    props: ['section'],
    components: {
        TicketCard,
        Slick,
        VueHtml2pdf,
        ButtonVue,
    },
    data() {
        return {
            carouselOptions: {
                arrow: true,
                dots: false,
                infinite: false,
                slidesToShow: 1,
            },
            lineItemSelected: null,
            loadDownload: false,
        };
    },
    mounted() {
    },
    computed: {
        ...mapState({
            lineItem: (state) => state.order.isCancelled,
        }),
        isCancelled() {
            //return false
            return this.$root.$refs.TicketConsult.order.isCancelled
        }
    },
    methods: {
        // generatePDF() {
        //     alert('generatePDF')
        //     this.$refs.html2Pdf.generatePdf();
        // },
        // setLineItemSelected(UUID) {
        //     const selectedLineItem = this.section.lineItems.find(item => item.UUID === UUID);
        //     this.lineItemSelected = selectedLineItem;
        // },
        isMobile() {
            return window.innerWidth <= 600;
        },
        async setLineItemSelected(UUID) {
            const selectedLineItem = this.section.lineItems.find(item => item.UUID === UUID);
            this.lineItemSelected = selectedLineItem;
        },
        async generatePDF(UUID, isComporte, bookingId) {
            this.loadDownload = true
            if (isComporte) {
                this.setLineItemSelected(UUID).then(() => {
                    this.$refs.lineItem.generatePdf();
                });
            } else {
                const getLink = await this.$store.dispatch('tickets/downloadDistribusionLink', bookingId)
                window.open(getLink.url, '_blank')
            }
            this.loadDownload = false
        },
    },
};
</script>
