<template>
    <div class="card accordion-items">
        <div class="card-header w-100 px-2 pb-1" :id="`heading-${id}`">
            <h5 class="mb-0">
                <div
                    v-if="collapsible"
                    class="d-flex w-100 justify-content-start align-items-center"
                    :class="{ collapsed: !open }"
                    data-toggle="collapse"
                    :data-target="`#${id}`"
                    :aria-expanded="open"
                    :aria-controls="id"
                >
                <span class="accordion-arrow" :class="{ open: open }"></span>
                <slot name="header"></slot>
            </div>
            
                <div v-else class="static-header">
                    <slot name="header"></slot>
                </div>
            </h5>
        </div>
        <div
            v-if="collapsible"
            :id="id"
            class="collapse"
            :class="{ show: open }"
            :aria-labelledby="`heading-${id}`"
            :data-parent="`#${parent}`"
        >
            <div class="card-body pt-0">
                <hr class="my-2">
                <slot name="body"></slot>
            </div>
        </div>

        <div v-else class="card-body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        parent: {
            type: String,
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
        collapsible: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        if (this.open) {
            this.addActiveClass();
        }

        this.$watch("open", (newVal) => {
            if (newVal) {
                this.addActiveClass();
            } else {
                this.removeActiveClass();
            }
        });
    },
    methods: {
        addActiveClass() {
            $(`.${this.id}`).addClass("active");
            $(`.${this.id}`).find('.accordion-arrow').addClass("open");
        },
        removeActiveClass() {
            $(`.${this.id}`).removeClass("active");
            $(`.${this.id}`).find('.accordion-arrow') .removeClass("open");
        },
    },
};
</script>
