import { mapState } from 'vuex';

export default {
    computed: {
        showBackButton() {
            if (this.activeStep === 1 && (!this.identifyStepCompleted || this.isLogged)) return false;
            if (this.activeStep === 3) return false;
            return true;
        },
        showForwardButton() {
            if (this.activeStep === 3) return false;
            return true;
        },
        disableNextStepButton() {
            if (this.activeStep === 1) {
                if (this.isLogged) return false;
            }

            return false;
        },
        ...mapState({
            reservamosData: (state) => state.checkout.reservamosData,
            reservamosPassengers: (state) => state.checkout.reservamosPassengers,
        }),
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        previousStep() {
            if (this.activeStep === 1 && this.identifyStepCompleted) {
                this.$store.commit('account/setLoginErr', false);

                this.$store.commit('account/setIdentifyStepCompleted', false);
                this.$store.commit('account/setCustomer', {});
                return;
            }
            this.$root.$refs.Checkout.activeStep -= 1;
        },
        async nextStep() {
            // requires: activeStep, isLogged, identifyStepCompleted, email, customer, tripsPassengers, passengerForm
            this.isLoading = true;

            if (this.activeStep === 1 && !this.isLogged && !this.identifyStepCompleted) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(this.email)) {
                    this.isLoading = false;
                    await this.$store.commit('account/setEmailError', 'E-mail inválido. Verifique e tente novamente.');
                    return;
                }
                await this.$store.commit('account/setEmailError', null);

                try {
                    await this.$store.dispatch('account/identifyCustomerRegister', {
                        email: this.email,
                    });
                    this.$store.commit('account/setGuestFormSubmitted', true);
                } catch (error) {
                    console.error('Error submitting form: ', error);
                }

                this.isLoading = false;
                return;
            }

            if (this.activeStep === 1 && this.userTypeRegister === 'NOT_REGISTERED') {
                await this.$store.dispatch('account/createBasicAccount', { email: this.email });
                this.isLoading = false;
            }

            if (this.activeStep === 1 && this.showGhostButton) {
                await this.$store.dispatch('account/triggerUserGuest');
                this.isLoading = false;
            }

            if (this.activeStep === 2) {
                if (!this.passengersFilled) {
                    this.$store.commit('checkout/setShowPassengersErrors', true);

                    this.isLoading = false;
                    return;
                }

                const departurePassengersData = this.tripsPassengers.departurePassengersData;
                const returnPassengersData = this.tripsPassengers.returnPassengersData;
                const allPassengersData = [...departurePassengersData, ...returnPassengersData];
                const verifyPassengers = [];
                const formName = this.passengerForm.passengerData.htmlName;
                const formNameDoc = this.passengerForm.passengerData.document.htmlName;
                allPassengersData.forEach(passenger => {
                    verifyPassengers.push({
                        uuid: passenger.id,
                        passengerName: passenger.fullName,
                        documentType: passenger.documentType,
                        passengerID: passenger.passengerID,
                        savePassenger: passenger.savePassenger,
                        formName: formName,
                        formNameDoc: formNameDoc,
                        departureTime: passenger.departureTime,
                        serviceNumber: passenger.serviceNumber,
                        seatNumber: passenger.seat,
                        userPassenger: passenger.userPassenger,
                        isPCD: passenger.isPCD,
                        isStudent: passenger.isStudent,
                        birthdate: passenger.birthdate,
                    });
                });

                const verifyPassengersResult = await this.$store.dispatch('checkout/verifyPassengers', { passengers: verifyPassengers });

                this.isLoading = false;

                if (verifyPassengersResult.error) {
                    return
                }

                const reservamosDataPassengers = this.reservamosData;
                reservamosDataPassengers['Passengers'] = this.reservamosPassengers;

                $(window).trigger('reservamos:passengersCreated', reservamosDataPassengers);
            }

            if (this.activeStep < 3) {
                this.$root.$refs.Checkout.activeStep += 1;
                this.isLoading = false;
            }
        }
    }
}
