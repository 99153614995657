import Vue from "vue";
import vueComponents from "./Vue";
Vue.config.devtools = true;
import store from "./Vue/store";

new Vue({
    el: "#vueApp",
    store,
    components: vueComponents,
    beforeCreate: function() {
        this.$store.dispatch("account/begin");
    }
});
