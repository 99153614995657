<template>
    <div>
        <slick v-if="daysParsed.length" ref="carousel" class="date-carousel" :options="carouselOptions" @afterChange="handleAfterChange" @init="onInitCarousel">
            <a
                v-for="(date, index) in daysParsed"
                :class="['date-carousel__item', { 'current': date.isSearchedDate }]"
                :key="date.date"
                :href="date.searchTripUrl"
                :alt="'Viagem para dia ' + date.dateFormatted"
                :id="'carouselCard_' + index"
                @click.prevent="selectDate(date)"
            >
                <p class="carousel__item__day">{{ date.dateFormatted }}</p>
                <p class="carousel__item__weekday">{{ date.weekDay }}</p>
            </a>
        </slick>
        <div>

        </div>
        <div v-if="loading" class="loading-overlay">
            <load
                title="Um minutinho!"
                :loadimage="loadimage"
                subtitle="Por favor, aguarde"
                subtitle2="enquanto buscamos sua passagem"
            />
        </div>
    </div>
</template>

<script>
import Load from './ui/Load.vue';
import { mapActions } from 'vuex';
import Slick from 'vue-slick';

export default {
    components: {
        Load,
        Slick
    },
    props: ['loadimage', 'searchpairs', 'days'],
    data() {
        return {
            lastAdjustedSlide: null,
            daysParsed: [],
            currentGroup: 0,
            loading: false,
            groupSize: this.isMobile() ? 3 : 5,
            currentSlide: 0,
            startIndex: 0,
            carouselOptions: {
                arrow: true,
                dots: false,
                infinite: false,
                slidesToShow: 5,
                slidesToScroll: 5,
                draggable: false,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    }
                ]
            }
        };
    },
    mounted() {
        this.daysParsed = JSON.parse(this.days).map(date => ({
            ...date,
            enabled: true
        }));
        this.initializeCarousel();
        this.setCurrentSlide();
    },
    methods: {
        initializeCarousel() {
            const today = new Date().toISOString().split('T')[0];
            const initialSlide = this.daysParsed.findIndex(date => date.isSearchedDate);
            const isToday = initialSlide !== -1 && this.daysParsed[initialSlide].date === today;

            if (isToday) {
                this.daysParsed = this.daysParsed.filter(date => date.date >= today);
                this.carouselOptions.initialSlide = 0;
            } else if (this.isMobile()) {
                const slidesBefore = Math.min(initialSlide, 1);
                this.carouselOptions.initialSlide = initialSlide - slidesBefore;
            } else {
                const slidesBefore = Math.min(initialSlide, 2);
                this.carouselOptions.initialSlide = initialSlide - slidesBefore;
            }
        },
        setCurrentSlide() {
            this.currentSlide = this.daysParsed.findIndex(date => date.isSearchedDate);
            const calculateStartIndex = this.currentSlide - (this.isMobile() ? 1 : 2);
            this.startIndex = calculateStartIndex < 0 ? 0 : calculateStartIndex;
        },
        handleAfterChange(currentSlide) {
            if (currentSlide > this.currentSlide) {
                this.startIndex += this.groupSize;
            } else {
                this.startIndex -= this.groupSize;
                if (this.startIndex < 0) {
                    this.startIndex = 0;
                }
            }

            this.currentSlide = currentSlide;
        },
        adjustSlidesToScroll() {
            if (this.currentSlide === this.lastAdjustedSlide) return;

            this.$nextTick(() => {
                if(this.$refs.carousel && typeof this.$refs.carousel.slick === 'function') {
                    if (this.currentSlide < this.carouselOptions.slidesToShow && this.currentSlide > 0) {
                        this.$refs.carousel.slick('slickSetOption', 'slidesToScroll', 1, true);
                    } else {
                        this.$refs.carousel.slick('slickSetOption', 'slidesToScroll', this.isMobile() ? 3 : 5, true);
                    }
                }
            });

            this.lastAdjustedSlide = this.currentSlide;
        },
        onInitCarousel() {
            this.$nextTick(() => {
                const skeleton = document.querySelector('.date-carousel-skeleton');
                if (skeleton) {
                    skeleton.remove();
                }
            });
        },
        isMobile() {
            return window.innerWidth <= 600;
        },
        selectDate(date) {
            if (!this.loading) {
                this.loading = true;
                window.location.href = date.searchTripUrl;
            }
        },
    },
    watch: {
        daysParsed() {
            this.adjustSlidesToScroll();
        }
    }
};
</script>

<style scoped>
.disabled_card {
    background: #e9e9e9;
    filter: grayscale(1);
    opacity: 0.6;
    pointer-events: none;
}
</style>
