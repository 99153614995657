import routes from '../../api/routes'
import loginApi from '../../api/login'
import amplitude from '../../../amplitude/amplitude';
import amplitudeTriggersAccount from '../../../amplitude/amplitudeTriggersAccount';
import checkoutApi from '../../api/checkoutOnePage';

const state = () => ({
    isLogged: false,
    identifyStepCompleted: false,
    loginErr: null,
    email: '',
    customerNumber: '',
    customer: {},
    guestFormSubmitted: false,
    userTypeRegister: '',
    passwordReset: {},
    emailError: null,
})

const getters = {
    isLogged: (state) => {
        return state.isLogged
    },
    loginErr: (state) => {
        return state.loginErr
    },
    email: (state) => {
        return state.email
    },
    userTypeRegister:(state) => {
        return state.userTypeRegister;
    },
    userType:(state) => {
        return state.customer.userType;
    },
    customer:(state) => {
        return state.customer;
    },
    customerNumber:(state) => {
        return state.customerNumber;
    },
    savedCards: (state) => {
        return state.customer.customerPaymentInstruments
    },
    emailError: (state) => {
        return state.emailError
    }
};

const actions = {
    async triggerUserGuest({ commit }) {
        try {
            $('body').trigger('amplitude:userGuest');
        } catch (error) {
            console.error('[account][triggerUserGuest] error', error);
        }
    },
    async begin({ commit, dispatch, rootState }, data) {
        let req = loginApi.getResources(
            routes.account.resources,
            data,
            rootState
        );

        try {
            const response = await fetch(req.url, req.options);
            const data = await response.json();
            commit('checkout/setResources', data, { root: true });

        } catch (error) {
            console.error('Fetch error account/begin: ', error);
        }

    },
    async login({ commit, rootState }, data) {
        let url = routes.account.loginmodal;
        if(data.isCheckout) {
            url = routes.account.logincheckout;
        }

        let req = loginApi.submitLogintReq(
            url,
            data,
            rootState
        );

        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();

            if (responseData.error && responseData.error.length > 0) {
                commit('setLoginErr', responseData.error[0]);
                return { success: false, error: responseData.error[0] };
            } else {
                sessionStorage.setItem('loginChecked', 'true');
                amplitude.logInEvent(true);
                $(window).trigger('reservamos:identify', responseData.reservamosData);
                $('body').trigger('cleverTap:identifyUser', responseData.cleverTapLogin)


                commit('setCustomer', responseData.customer);
                commit('setIsLogged', true);
                commit('setIdentifyStepCompleted', true);
                return { success: true };
            }
        } catch (error) {
            console.error('[account][login] error', error);
            return { success: false, error: 'Erro ao tentar fazer login.' };
        }
    },

    async createBasicAccount({ commit, dispatch, rootState }, data) {
        let req = loginApi.createBasicAccount(
            routes.checkout.createCustomer,
            data,
            rootState
        );
        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();

            if (!responseData.error) {
                if (responseData.reservamosData) {
                    $(window).trigger('reservamos:identify', responseData.reservamosData);
                }

                commit('setCustomer', responseData.customer);
                commit('setIdentifyStepCompleted', true);
                commit('setCustomerNumber', responseData.customer.customerNumber)
                commit('setIsLogged', false)

                $('body').trigger('amplitude:userGuest');
            }
        } catch (error) {
            console.error('[account][createAccount] error', error);
        }
    },

    async logoutUser({ commit, dispatch, rootState }, data) {
        let req = loginApi.logout(
            routes.login.logoutStay,
            data,
            rootState
        );
        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();
            if (responseData.logout) {
                commit('setCustomer', {});
                commit('setIsLogged', false);
                commit('setIdentifyStepCompleted', false);
            }
        } catch (error) {
            console.error('[account][logoutUser] error', error);
        }
    },

    async resetPassword({ commit, dispatch, rootState }, data) {
        let req = loginApi.passwordReset(
            routes.account.passwordReset,
            data,
            rootState
        );
        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();
            if (responseData.success) {
                commit('setPasswordReset', {
                    reset: responseData.success,
                    resetMsgBody: responseData.receivedMsgBody,
                    resetMsgHeading: responseData.receivedMsgHeading,
                });

                $('body').trigger('amplitude:changePassword');
            }
        } catch (error) {
            console.error('[account][resetPassword] error', error);
        }
    },
    async identifyCustomerRegister({ commit, dispatch, rootState }, data) {
        const url = data.create ? routes.account.identifyCustomerAndCreate : routes.account.identifyCustomer

        let req = loginApi.identifyCustomerRegisterReq(
            url,
            data,
            rootState
        );
        commit('setEmail', data.email);

        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();

            if (!responseData.error) {
                commit('setIdentifyStepCompleted', true);
                commit('setUserTypeRegister', responseData.userType);
                if (responseData.reservamosData) {
                    $(window).trigger('reservamos:identify', responseData.reservamosData);
                }

                if (responseData.customer) {
                    commit('setCustomerNumber', responseData.customer.customerNumber)
                }

                const customer = {
                    userType: responseData.userType === 'REGISTERED' ? 'identified' : 'guest'
                };
                commit('setCustomer', customer);

                dispatch('saveAbandonedCart')

            }
            return responseData;
        } catch (error) {
            console.error('[account][identifyCustomer] error', error);
            throw error;
        }
    },
    async submitRegistration({ commit, rootState }, data) {
        let req = loginApi.submitRegistrationReq(
            routes.account.submitRegistration,
            data,
            rootState
        );
        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();
            if (!responseData.error && !responseData.fields) {
                $(window).trigger('reservamos:identify', responseData.reservamosData);
                if (responseData.customer) {
                    commit('setCustomer', responseData.customer);
                    commit('setCustomerNumber', responseData.customer.customerNumber);
                    commit('setIdentifyStepCompleted', true);

                    $('body').trigger('amplitude:accountCreated', {
                        tipo_documento: responseData.form.customer.document.selectedOption
                    });
                }
            }

            return responseData;
        } catch (error) {
            console.error('[account][submitRegistration] error', error);
            throw error;
        }
    },
    async removeCard({ commit, rootState }, UUID) {
        let req = loginApi.removeCardReq(
            routes.account.removeCard,
            UUID,
            rootState
        );
        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();
            commit('setRemoveCard', UUID);
            return responseData;
        } catch (error) {
            console.error('[account][removeCard] error', error);
            throw error;
        }
    },
    async saveAbandonedCart({ rootState }) {
        const data = {
            email: rootState.account.email
        }

        let req = checkoutApi.saveAbandonedCartReq(
            routes.checkout.saveAbandonedCart,
            data,
            rootState
        );
        try {
            await fetch(req.url, req.options);
        } catch (error) {
            console.error('Fetch error submitPaymentReq: ', error);
            throw error;
        }
    },
};

const mutations = {
    setRemoveCard(state, UUID) {
        state.customer.customerPaymentInstruments = state.customer.customerPaymentInstruments.filter(card => card.UUID !== UUID);
    },
    setIsLogged(state, data) {
        state.isLogged = data
    },
    setLoginErr(state, data) {
        state.loginErr = data
    },
    setEmail(state, data) {
        state.email = data
    },
    setCustomer(state, data) {
        state.customer = data
    },
    setGuestFormSubmitted(state, data) {
        state.guestFormSubmitted = data
    },
    setIdentifyStepCompleted(state, data) {
        state.identifyStepCompleted = data
    },
    setUserTypeRegister(state, data) {
        state.userTypeRegister = data
    },
    setIsLogged(state, data) {
        state.isLogged = data
    },
    setPasswordReset(state, data) {
        state.passwordReset = data
    },
    setCustomerNumber(state, data) {
        state.customerNumber = data
    },
    setEmailError(state, data) {
        state.emailError = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
