<template>
    <div class="px-3 checkout-logged-user">
        <h4 class="bd mb-3">
            {{ loggedMessage }}
        </h4>
        <div class="d-flex justify-content-center purple--messageButton">
            <p class="mob-text--large">
                Entrar com outra conta?
            </p>
            <ButtonVue
            :label="resources.base.logout"
            className="ghost h-100 mob-text--large mob-color--primary"
            @click="confirmLogout"
            :attributes="[
                { name: 'orange-id', value: 'checkoutLogoutButton' },
            ]"
            />
        </div>
        <Modal
            modalId="confirmLogout"
            size="md"
            :showCloseButton="true"
            :isStatic="true"
            :startOpen="false"
        >
            <template v-slot:title> {{ resources.identification.wantToLeave }} </template>
            <template v-slot:body>
                {{ resources.identification.yourPassagesWillBeLost }}
                <b>”{{ resources.base.logout }}”</b>,
                {{ resources.identification.yourPassagesWillBeLost2 }}
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between w-100">
                    <ButtonVue
                        :label="resources.identification.logoutAnyway"
                        className="outlined logoutModal--btn"
                        @click="logout"
                        class="sm"
                    />
                    <ButtonVue
                        :label="resources.forms.cancel"
                        className="solid logoutModal--btn"
                        data-dismiss="modal"
                        class="sm"
                    />
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
import InputVue from '../../ui/InputVue.vue'
import { mapState } from 'vuex'
import routes from '../../../api/routes';
import ButtonVue from '../../ui/ButtonVue.vue';
import Modal from '../../ui/Modal'

export default {
    components: {
        InputVue,
        ButtonVue,
        Modal: Modal,
    },
    props: {},
    computed: {
        ...mapState({
            customer: (state) => state.account.customer,
            isLogged: (state) => state.account.isLogged,
            resources: (state) => state.checkoutOnePage.resources,
        }),
        loggedMessage() {
            const fullName = this.customer?.profile?.fullName;
            const welcomeBack = this.resources?.identification?.welcomeBack;
            const welcomeBackGuest = this.resources?.identification?.welcomeBackGuest;

            if (!fullName || fullName === '... ...') {
                return welcomeBackGuest || "Bem-vindo(a), visitante!";
            }

            return welcomeBack?.replace('*', fullName) || `Bem-vindo(a), ${fullName}!`;
        }
    },
    data() {
        return {
            activeStep: this.$root.$refs.Checkout.activeStep,
        }
    },
    methods: {
        confirmLogout() {
            $(`#confirmLogout`).modal('show');
        },
        logout() {
            this.$store.dispatch('account/logoutUser').then(() => {
                window.location.href = routes.home.show;
            });
        }
    }
}
</script>
