<template lang="html">
    <div class="mt-5">
        <input-vue
            id="register-email"
            type="email"
            :label="resources.forms.informEmail"
            :placeholder="resources.forms.email"
            v-model="userEmail"
            :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'register-email' }, { name: 'disabled', value: clientIdentify}]"
            :errorMessage="errorMessages"
        />
        <div class="button-wrapper" v-if="!clientIdentify">
            <button-vue
                @click="validateEmail"
                :label="resources.forms.continue"
                :isLoad="load"
                :attributes="[{ name: 'disabled', value: !email}, { name: 'orange-id', value: 'register-email-button' }]"
            />
        </div>

        <div class="client-identify mt-3" v-if="clientIdentify">
            <p class="title">
                {{ resources.forms.identify.title }}
            </p>
            <p class="text mt-3">
                {{ resources.forms.identify.paragraph }}
            </p>
            <div class="button-wrapper">
                <button-vue
                    @click="sendMail"
                    :label="sendButtonLabel"
                    :isLoad="load"
                    :disabled="isButtonDisabled || load || emailSent"
                    :attributes="[{ name: 'orange-id', value: 'send-email-button' }]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import InputVue from '../ui/InputVue.vue';
import ButtonVue from '../ui/ButtonVue.vue';
import routes from '../../api/routes';
import { mapGetters } from 'vuex';

export default {
    components: {
        'input-vue': InputVue,
        'button-vue': ButtonVue,
    },
    props: {
        email: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            staticPath: routes.urlStatic,
            errorMessages: '',
            load: false,
            userEmail: this.email || '',
            clientIdentify: false,
            isButtonDisabled: false,
            emailSent: false,
        };
    },
    computed: {
        ...mapGetters('account', ['userTypeRegister']),
        ...mapGetters('checkout', ['resources']),
        sendButtonLabel: {
            get() {
                return this.resources.forms.send;
            },
            set(value){
                return value;
            }
        }
    },
    watch: {
        email: function (newVal, oldVal) {
            this.userEmail = newVal;
        }
    },
    methods: {
        async validateEmail() {
            this.errorMessages = '';
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!this.userEmail) {
                this.errorMessages = this.resources.forms.emailRequired;
            } else if (!regex.test(this.userEmail)) {
                this.errorMessages = this.resources.forms.emailInvalid;
            } else {
                this.load = true;
                try {
                    await this.$store.dispatch('account/identifyCustomerRegister', { email: this.userEmail });
                    if (this.userTypeRegister == "NOT_REGISTERED") {
                        this.$root.$refs.Register.nextStep();
                    } else if (this.userTypeRegister == "FIRST_ACCESS" || this.userTypeRegister == "REGISTERED") {
                        this.clientIdentify = true;
                    }
                } catch (error) {
                    this.errorMessages = error.message || this.resources.errors.emailNotSent;
                } finally {
                    this.load = false;
                }
            }
        },
        async sendMail() {
            this.load = true;
            this.isButtonDisabled = true;
            try {
                await this.$store.dispatch('account/resetPassword', {
                    loginEmail: this.userEmail,
                });
                this.sendButtonLabel = this.resources.forms.sentWithSuccess;
                this.emailSent = true;
            } catch (error) {
                this.errorMessages = error.message || this.resources.errors.emailNotSent;
                this.isButtonDisabled = false;
            } finally {
                this.load = false;
            }
        },
    },
    created: function () {
        this.$root.$refs.Identify = this;
    },
};
</script>

<style scoped lang="scss">
@import '../../../../scss/core/colors.scss';

.register-modal {
    .title {
        color: #333333;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.client-identify {
    .title {
        color: #55368B;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    .text {
        color: #333;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

}
</style>
